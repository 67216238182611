export const setLangFields = (ob, lang) => {
	const b = Object.assign({}, ob)
	b.name = setField(b.personal.nameL, b.personal.name, lang)
	b.occupationLang = setField(b.occupation.titleL, b.occupation.title, lang)
	let addr = setField(
		b.address.present.locationL,
		b.address.present.location,
		lang
	)
	b.presentAddress = `${addr}`
	addr = setField(
		b.address.permanent.locationL,
		b.address.permanent.location,
		lang
	)
	b.permanentAddress = `${addr}`
	b.purpose = setField(b.purposeL, null, lang)
	b.currentLang = lang
	return b
}
export const setLangDonatationItem = (ob, lang) => {
	const di = Object.assign({}, ob)
	if (di.name && Array.isArray(di.name)) {
		di.nameL = di.name
		di.name = setField(di.nameL, null, lang)
	} else if (di.nameL && Array.isArray(di.nameL)) {
		di.name = setField(di.nameL, null, lang)
	}
	if (di.description && Array.isArray(di.description)) {
		di.descriptionL = di.description
		di.description = setField(di.descriptionL, null, lang)
	} else if (di.descriptionL && Array.isArray(di.descriptionL)) {
		di.description = setField(di.descriptionL, null, lang)
	}
	di.currentLang = lang
	return di
}
export const getAge = time => {
	var ageInMilliseconds = new Date() - time
	return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365) // convert to years
}

function setField(data, initVal, lang) {
	if (data) {
		let val = data.find(n => n.languageCode === lang)
		if (!val && lang !== 'en') {
			val = data.find(n => n.languageCode === 'en')
		}
		return val && val.text ? val.text : initVal ? initVal : 'no text provided'
	}
}
