import RequestService from './Request'

var donate = (
	amount,
	name,
	phoneOrEmail,
	paymentMethod,
	donorAddress,
	collectDate,
	collectTime,
	mobileBankingType,
	mobileBankingNumber,
	transactionID,
	photo,
	beneficiary,
	product,
	type
) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/donation-offline',
			method: 'POST',
			body: {
				amount: amount,
				name: name,
				phoneOrEmail: phoneOrEmail,
				paymentMethod: paymentMethod,
				donorAddress,
				collectDate,
				collectTime,
				mobileBankingType,
				mobileBankingNumber,
				transactionID,
				photo: photo !== '' ? photo : undefined,
				beneficiary,
				product,
				recipient: product ? 'product' : 'beneficiary',
				type: type ? type : 'donor'
			},
			addToken: false
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

const photoUpload = file => {
	return new Promise((resolve, reject) => {
		var data = new FormData()
		data.append('photo', file)
		var req = {
			url: '/public/donation-offline/upload',
			method: 'POST',
			body: data,
			addToken: false
		}
		RequestService.sendMultipart(req)
			.then(res => {
				console.log(res)
				return resolve(res)
			})
			.catch(err => {
				console.error(err)
				return reject(err)
			})
	})
}

export default { photoUpload, donate }
