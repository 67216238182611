import { injectIntl } from 'react-intl'
import Rating from '../Rating'
import DonatedList from './DonatedList'
import DonorDonationItemList from './DonorDonationItemList'

const DonorDashboard = ({ intl }) => {
	return (
		<div className='pb-5'>
			<div className='container-fluid mt-4'>
				<div className='row'>
					<div className='col-md-3 offset-md-1 position-relative'>
						<div className='card position-absolute h-100'>
							<div className='d-flex'>
								<div className='p-3'>
									<img
										style={{ width: '70px', height: '70px' }}
										src='/imgs-donation/donor.jpg'
										className='img-thumbnail img-fluid rounded-circle'
										alt='...'
									/>
								</div>
								<div className='px-2 flex-grow-1 align-self-center'>
									mokarra bin
								</div>
								<div className='pe-3 align-self-center'>
									<Rating rating={5} />
								</div>
							</div>

							<hr className='text-muted mx-3' />
							<DonatedList />
							<hr className='mx-3' />
							<div className='d-flex justify-content-between pb-3 text-muted'>
								<div className='ms-5'>Donated in October</div>
								<div className='fw-bold me-3'>$100</div>
							</div>
						</div>
					</div>
					<div className='col-md-7'>
						<div className='card'>
							<div className='card-body'>
								<div className='d-flex justify-content-between'>
									<div>
										Total Donation:{' '}
										<span className='text-muted fw-bold'>$1000.63</span>
									</div>
									<div className='text-muted'>
										<select className='form-select'>
											<option selected>Active Donations: (0)</option>
											<option value='1'>One</option>
											<option value='2'>Two</option>
											<option value='3'>Three</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className='pt-4'>
							<h6 className='text-muted'>
								{intl.formatMessage({ id: 'donor.dashboard' })}
							</h6>
							<hr className='bg-gray border-top border-danger'></hr>
						</div>
						<div className='card'>
							<div className='card-body'>
								<div className='d-flex justify-content-between'>
									<div className='pt-5'>
										<span className='text-muted fw-bold'>
											{intl.formatMessage({ id: 'donate.howto' })}
										</span>
										<p className='text-muted'>
											{intl.formatMessage({ id: 'donate.intro' })}
										</p>
									</div>
									<div className='ps-5'>
										<img
											src={process.env.PUBLIC_URL + '/imgs-donation/5.jpg'}
											alt='donation-image'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='pt-3'></div>

						<div className='card'>
							<div className='card-body'>
								<h5 className='card-title text-center text-muted pt-3'>
									{intl.formatMessage({ id: 'donate.participated' })}
								</h5>
								<DonorDonationItemList />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(DonorDashboard)
