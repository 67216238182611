import React from 'react'
import '../zakat/Zakat.css'
import { FaCheck } from 'react-icons/fa'
import Counter from '../counter/Counter'
import RoundBtn from '../roundBtn/RoundBtn'
import Buttons from '../button/Buttons'
import Imgprofile from '../imgprofile/Imgprofile'
import { ZakatData } from '../../../data/ZakatData'
import { injectIntl } from 'react-intl'
const Zakat=({ intl })=> {

	const myStyle = {
		backgroundImage: "url(images/wave.svg), url(images/wave.svg)",
		backgroundRepeat: 'no-repeat, no-repeat',
		backgroundPosition: 'right top, right bottom',
		backgroundSize: '150px auto, 150px auto',
	}

	return (
		<div className="zakat" style={myStyle}>
			<div className="container">
				<div className="row">
					<div className="col-lg-10 col-sm-12">
						{/* <h1 dangerouslySetInnerHTML={{ __html: ZakatData[0].title }}></h1> */}
						<h1>{intl.formatMessage({id:'zakat.heading'})}</h1>
						<div className="img-wrap">
							<img className="big-img" src="images/zakat.jpg"></img>

							<div className="d-flex">
								<Imgprofile margin={{ marginTop: "-180px", marginLeft: "100px" }}></Imgprofile>

								<Counter district={ZakatData[0].district} thana={ZakatData[0].thana} child={ZakatData[0].children} margin={{ marginLeft: '50px', position: 'relative', top: '30px' }}></Counter>
							</div>

							<div className="info-wrap">
								{/* <p dangerouslySetInnerHTML={{ __html: ZakatData[0].shortDesc }}></p> */}
								<p className="short-desc">{intl.formatMessage({ id: 'zakat.description' })}</p>
								<RoundBtn bg={{ backgroundColor: '#2aa457', color: '#ffffff' }}></RoundBtn>
							</div>
						</div>

					</div>
					<div className="col-sm-12">
						<div className="short-desc">
							{/* <p><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s,</span> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
							<p className="short-desc">{intl.formatMessage({ id: 'zakat.description' })}</p>
						</div>
						<div className="donation-btn-group">
							<Buttons></Buttons>
							<RoundBtn></RoundBtn>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(Zakat)
