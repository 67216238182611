import { useState } from 'react'
import { injectIntl } from 'react-intl'
import Rating from '../Rating'
import BenefitedList from './BenefitedList'

import '../../../components/bene-detail.css'
import '../../../components/profile-analysis.css'
import '../../../components/profileGallery.css'

import { useSelector } from 'react-redux'
import Personal from '../../../components/profile/Personal'
import Gallery from './Gallery'
import Boxes from './stepprogress/Boxes'

const BeneficiaryDashboard = ({ intl }) => {
	const [gallery, setGallery] = useState(true)
	const [analysis, setAnalysis] = useState(false)
	const [about, setAbout] = useState(false)
	const profile = useSelector(state => state.beneficiaries.profile)

	const handleGallery = () => {
		setGallery(true)
		setAnalysis(false)
		setAbout(false)
	}
	const handleAnalysis = () => {
		setGallery(false)
		setAnalysis(true)
		setAbout(false)
	}
	const handleAbout = () => {
		setGallery(false)
		setAnalysis(false)
		setAbout(true)
	}
	return (
		<div className='pb-5'>
			<div className='container-fluid mt-3'>
				<div className='row'>
					<div className='col-md-3 offset-md-1 position-relative'>
						<div className='card position-absolute h-100'>
							<div className='d-flex'>
								<div className='p-3 '>
									<img
										style={{ width: '70px', height: '70px' }}
										src='/imgs-donation/beneficiary.jpg'
										className='img-thumbnail img-fluid rounded-circle'
										alt='...'
									/>
								</div>
								<div className='px-2 flex-grow-1 align-self-center'>
									Abu Taher
								</div>
								<div className='pe-3 align-self-center'>
									<Rating rating={5} />
								</div>
							</div>

							<hr className='text-muted mx-3' />
							<BenefitedList />
						</div>
					</div>
					<div className='col-md-7'>
						<div className='card'>
							<div className='card-body'>
								<div className='d-flex flex-column'>
									<div>
										{intl.formatMessage({ id: 'bene.level' })}
										<a href='#' style={{ color: 'green' }}>
											{intl.formatMessage({ id: 'learn.more' })}
										</a>
									</div>
									<div className='text-muted fs-6'>
										Evaluation Period ends on 23 Sep, 2023
									</div>
								</div>
								<div className='m-4'>
									<Boxes />
								</div>
							</div>
						</div>
						<div className='card mt-3'>
							<div className='card-body'>
								<div className='d-flex justify-content-between'>
									<div className='pt-5'>
										<span className='text-muted fw-bold'>
											{intl.formatMessage({ id: 'donation.howto' })}
										</span>
										<p className='text-muted'>
											{intl.formatMessage({ id: 'donation.howto.description' })}
										</p>
									</div>
									<div className='ps-5'>
										<img
											src={process.env.PUBLIC_URL + '/imgs-donation/7.jpg'}
											alt='donation-image'
										/>
									</div>
								</div>
							</div>
						</div>

						<div className='pt-3'></div>

						<div className='card'>
							<div className='card-body'>
								<h5 className='text-muted'>
									{intl.formatMessage({ id: 'beneficiary.dashboard' })}
								</h5>
								<div className='h-100 position-relative'>
									<div className='pb-3 px-1 pt-1'>
										<button
											onClick={() => handleGallery()}
											className={gallery ? 'btn btn-new' : 'btn btn-gallery'}
										>
											{intl.formatMessage({ id: 'gallery' })}
										</button>
										<button
											onClick={() => handleAnalysis()}
											className={analysis ? 'btn btn-new' : 'btn btn-analysis'}
										>
											{intl.formatMessage({ id: 'profile' })}
										</button>
										<button
											onClick={() => handleAbout()}
											className={about ? 'btn btn-new' : 'btn btn-analysis'}
										>
											{intl.formatMessage({ id: 'about' })}
										</button>
									</div>

									<div>
										{gallery && <Gallery />}
										{analysis && <Personal />}
										{/* {about && <About profile={profile} />} */}
										{/* For suppressing the error, the above code would be executed once beneficiary id is set */}
										{about && (
											<p>
												Lorem ipsum dolor, sit amet consectetur adipisicing
												elit. Incidunt, velit suscipit! Impedit hic dolores
												cumque explicabo nihil sint, veniam atque tenetur.
												Nostrum, doloremque magnam facilis aut fugit culpa.
												Iusto consequatur eaque porro ad repellendus laboriosam!
												Tempore eum sit atque, natus, et deserunt sequi,
												reiciendis aperiam accusamus reprehenderit ducimus!
												Sequi totam illo voluptate accusantium nostrum inventore
												adipisci nulla doloremque! Consequuntur eligendi facilis
												laudantium et, ipsa aliquid eius maxime reiciendis
												accusamus explicabo saepe doloremque rem voluptatibus a
												at, dolorum perspiciatis cum dolore voluptatum. Facilis
												fuga error quae et ea quos, cumque quod unde numquam
												rerum est facere, obcaecati, illo sed quaerat
												aspernatur.
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(BeneficiaryDashboard)
