import { useRef } from 'react'
import { useSelector } from 'react-redux'
import './news.css'

import { injectIntl } from 'react-intl'
import LoadingBar from 'react-top-loading-bar'
import Bannercrumb from '../../../util/banner/Bannercrumb'

const NewsDetail = ({ intl }) => {
	const loadingRef = useRef(null)
	const language = useSelector(state => state.language.locale)

	const myStyle = {
		backgroundImage:
			'url(images/left-circle.svg), url(images/right-circle.svg)',
		backgroundRepeat: 'no-repeat, no-repeat',
		backgroundPosition: 'right top, left top -600px',
		backgroundSize: '350px auto, auto 700px'
	}

	return (
		<>
			<LoadingBar height={3} color='#0C8F4D' ref={loadingRef} />
			<Bannercrumb bannerId='home.news.title' />
			<div className='news' style={myStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-10 offset-md-1 g-0'>
							<div className='row'>
								<div className='card'>
									<div className='card-body'>
										<h1>News Heading</h1>
										<img
											src='news-images/1.jpg'
											className='card-img-top'
											alt='...'
										/>
										<p className='card-text lead py-3'>
											Lorem ipsum dolor sit, amet consectetur adipisicing elit.
											Magni neque repellendus blanditiis optio eaque similique
											nostrum, voluptate itaque sint laboriosam, doloremque
											accusantium consequuntur, est excepturi nihil ea dolorum
											expedita cum corrupti saepe nisi? Ab fugiat repellendus
											quidem nobis perferendis architecto, aut odit modi
											repellat deleniti explicabo itaque iste enim aspernatur
											qui dolores vel id molestias! Quo illo delectus hic
											perspiciatis libero blanditiis tenetur doloribus
											exercitationem, maiores culpa et iusto modi quod odio id
											similique quis ipsum cupiditate, debitis quidem! Voluptas,
											ut autem. Dicta deleniti sed sint hic, asperiores ipsum
											nesciunt magni voluptatum nulla autem libero, quidem
											possimus laborum similique optio.
										</p>
										<img
											src='news-images/2.jpg'
											className='card-img-top'
											alt='...'
										/>
										<p className='card-text lead py-3'>
											Lorem ipsum dolor sit amet consectetur adipisicing elit.
											Nobis quibusdam autem quod repudiandae ea, perferendis
											natus veniam culpa. Earum a officia odit quisquam culpa
											eum nostrum animi cumque ducimus magni quos,
											necessitatibus vero esse voluptates ullam suscipit dolores
											mollitia exercitationem modi omnis non debitis, hic
											impedit reiciendis! Quasi sequi corporis ex harum nisi,
											neque nobis dolorem excepturi nulla molestias praesentium
											eligendi velit quo aspernatur repellendus sit? Illo quam
											ab aspernatur modi tenetur minima molestias, magnam
											corrupti sequi nisi debitis quae! Placeat ab cum possimus,
											culpa architecto iusto tempore dolor soluta voluptates
											repudiandae adipisci, nisi neque! Animi nisi numquam modi
											omnis unde officiis nemo repellat nihil magnam similique
											eveniet, error facere placeat sunt dolorum quidem aperiam
											quasi labore vel rerum. Excepturi eos ea aperiam corrupti
											blanditiis minima accusamus reprehenderit doloribus autem
											voluptas voluptatum architecto, possimus officiis ipsa
											quis dolorem repellat explicabo quod modi nesciunt laborum
											numquam accusantium. Est aut eligendi possimus voluptate!
											Sapiente alias aliquid, suscipit quam praesentium ipsa
											velit maiores nostrum eos recusandae sit ducimus adipisci
											est numquam deserunt nemo consequuntur dolores cupiditate
											in, quia ex illo repellendus libero eligendi. Eius
											necessitatibus quam illum modi voluptatibus. Suscipit
											itaque architecto maiores, quis consectetur asperiores.
											Aliquid nemo corrupti rem, optio ab dolores!
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(NewsDetail)
