const { createSlice } = require('@reduxjs/toolkit')

const UiSlice = createSlice({
	name: 'ui',
	initialState: { animate: false },
	reducers: {
		setAnimate: (state, action) => {
			state.animate = action.payload
		}
	}
})
export default UiSlice
