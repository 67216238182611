import React from 'react'
import Box from './Box'

function Boxes() {
	const levels = [
		{ id: 'ONE', text: 'LEVEL', color: 'gray' },
		{ id: 'TWO', text: 'LEVEL', color: '#2e8780' },
		{ id: 'THREE', text: 'LEVEL', color: ' #5a2e87' },
		{ id: 'RATED', text: 'TOP', color: ' #FFC300 ' },
	]
	return (
		<div className='d-flex justify-content-between text-white position-relative'>
			<div className='position-absolute'
				style={{ content: '', width: '100%', height: '4px', background: ' #e1e8e7', top: '2rem', zIndex: '0' }}
			>
			</div>
			{levels.map(level =>
				<div key={level.id} className='d-flex flex-column justify-content-center align-items-center'
					style={{ width: '4rem', fontSize: '0.75rem', height: '4rem', background: `${level.color}`, borderRadius: '2rem', zIndex: '1' }}>

					<div>{level.text}</div>

					<div>{level.id}</div>
				</div>)}
		</div>
	)
}

export default Boxes
