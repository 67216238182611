import { injectIntl } from 'react-intl'
const BelowSliderSection = ({ intl }) => {
	return (
		<div className='container'>
			<div className='row py-5 text-center'>
				<div className='col-md-8 offset-md-2 footer-text'>
					<p>{intl.formatMessage({ id: 'footer.lorem' })}</p>
					<button type='button' className='btn btn-success'>
						{intl.formatMessage({ id: 'learn.more' })}
					</button>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(BelowSliderSection)
