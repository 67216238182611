const { createSlice } = require('@reduxjs/toolkit')

const defaultLanguage = {
	languageId: 'english',
	locale: 'en',
	name: 'English',
	icon: 'us'
}



const language = JSON.parse(localStorage.getItem('language'))

if (!language) {
	localStorage.setItem('language', JSON.stringify(defaultLanguage))
}

const LanguageSlice = createSlice({
	name: 'language',
	initialState: {
		// locale: defaultLanguage
		locale: defaultLanguage
	},

	reducers: {
		setLanguage: (state, action) => {
			state.locale = action.payload
		}
	}
})

export default LanguageSlice
