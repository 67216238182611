import React from 'react'
import { injectIntl } from 'react-intl'

const DonorDonationItem = ({ intl, donation }) => {
	return (
		<div className='col-md-4 px-4 py-4'>

			<img src={process.env.PUBLIC_URL + donation.src} style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
			<p>{donation.description}</p>
			<button type="button" className='btn btn-outline-success'>
				{intl.formatMessage({ id: 'explore' })}
			</button>
		</div>
	)
}

export default injectIntl(DonorDonationItem)
