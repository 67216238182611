/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux'
import './App.css'
import Header from './components/Header'

import '@animxyz/core'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from './components/HeaderComponents/About'
import Contact from './components/HeaderComponents/Contact'
import Gallery from './components/HeaderComponents/Gallery'
import LatestNews from './components/HeaderComponents/news/LatestNews'
import NewsDetail from './components/HeaderComponents/news/NewsDetail'
import Projects from './components/HeaderComponents/Projects'
import Donate from './components/HeaderComponents/Donate'
import BeneficiaryRepayment from './components/HeaderComponents/BeneficiaryRepayment'
import HomePage from './components/HomePage'
import PaidBeneficiary from './components/PaidBeneficiary'
import Copyright from './components/MoreComponents/copyrights/Copyright'
import Footer from './components/MoreComponents/footer/Footer'
import NavMenu from './components/NavMenu'
import Abort from './components/Payment/Abort'
import Failure from './components/Payment/Failure'
import Success from './components/Payment/Success'
import AppLocale from './lngProvider'
import BeneficiaryLogin from './routes/Beneficiary/Login/BeneficiaryLogin'
import AddBeneficiaryForm from './routes/Beneficiary/Registration/AddBeneficiaryForm'
import BeneficiaryDashboard from './routes/dashboard/beneficiary/BeneficiaryDashboard'
import DonorDashboard from './routes/dashboard/donor/DonorDashboard'
import DonationsAll from './routes/donation/DonationsAll'
const App = () => {
	const locale = useSelector(state => state.language.locale)
	const currentAppLocale = AppLocale[locale.locale]
	return (
		<IntlProvider
			locale={currentAppLocale.locale}
			messages={currentAppLocale.messages}
		>
			<BrowserRouter>
				<Header />
				<NavMenu />
				{/* <NavLower/> */}

				<Routes>
					<Route path='/' element={<HomePage />} />
					<Route path='/about' element={<About />} />
					<Route path='/contact' element={<Contact />} />
					<Route path='/gallery' element={<Gallery />} />
					<Route path='/news' element={<LatestNews />} />
					<Route path='/news-detail' element={<NewsDetail />} />
					<Route path='/projects' element={<Projects />} />
					<Route
						path='/beneficiaryrepayment'
						element={<BeneficiaryRepayment />}
					/>
					<Route path='/donation/:routeExtension' element={<Donate />} />
					<Route path='/donation' element={<DonationsAll />} />
					<Route path='/archive' element={<PaidBeneficiary />} />
					<Route path='/register' element={<AddBeneficiaryForm />} />
					<Route path='/user/signin' element={<BeneficiaryLogin />} />
					<Route path='/payment/success/:id' element={<Success />} />
					<Route path='/payment/failure/:id' element={<Failure />} />
					<Route path='/payment/abort/:id' element={<Abort />} />
					<Route path='/user/donor' element={<DonorDashboard />} />
					<Route path='/user/beneficiary' element={<BeneficiaryDashboard />} />
				</Routes>

				<Footer />
				<Copyright />
			</BrowserRouter>
		</IntlProvider>
	)
}

export default App
