import { Button } from 'antd'
import { useEffect, useRef } from 'react'
import './topbar.css'

import { useDispatch, useSelector } from 'react-redux'
import LanguageSlice from '../store/LanguageSlice'

function Topbar() {
	const pageRef = useRef(null)

	const languageData = [
		{
			languageId: 'english',
			locale: 'en',
			name: 'English',
			icon: 'us'
		},
		{
			languageId: 'bengali',
			locale: 'bn',
			name: 'Bengali',
			icon: 'bd'
		}
	]

	const locale = useSelector(state => state.language.locale)
	const dispatch = useDispatch()

	const { setLanguage } = LanguageSlice.actions

	const enSelected = () => {
		dispatch(setLanguage(languageData[0]))
		localStorage.setItem('language', JSON.stringify(languageData[0]))
	}

	const bnSelected = () => {
		dispatch(setLanguage(languageData[1]))
		localStorage.setItem('language', JSON.stringify(languageData[1]))
	}

	useEffect(() => {
		setTimeout(() => {
			pageRef.current.scrollIntoView()
		}, 20)
	}, [])

	return (
		<div className='d-flex flex-row-reverse mt-1 me-1' ref={pageRef}>
			<div className='ant-btn-group lang-btn topbar-btn'>
				<Button
					size='small'
					type={locale.locale === 'en' ? 'primary' : 'default'}
					onClick={enSelected}
					className={locale.locale === 'en' ? 'active' : ''}
				>
					EN
				</Button>
				<Button
					size='small'
					type={locale.locale === 'en' ? 'default' : 'primary'}
					onClick={bnSelected}
					className={locale.locale === 'bn' ? 'active' : ''}
				>
					বাংলা
				</Button>
			</div>
		</div>
	)
}

export default Topbar
