import RequestService from './Request'

var create = (
	personal,
	educations,
	experiences,
	occupation,
	address,
	familyMembers,
	trainings,
	references,
	photo,
	purposeL
) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/beneficiary-request',
			method: 'POST',
			body: {
				personal,
				educations,
				experiences,
				occupation,
				address,
				familyMembers,
				trainings,
				references,
				photo,
				other: {
					purposeL
				}
			},
			addToken: false
		}
		console.log(req.body)
		RequestService.send(req).then(
			res => {
				console.log(res)
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

var getAll = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/beneficiary-request',
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}
const checkUserExists = personal => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/beneficiary-request/checkUserExists',
			method: 'POST',
			body: {
				personal
			},
			addToken: false
		}
		RequestService.send(req).then(
			res => {
				console.log(res)
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}
const checkBeneficiaryForRepayment = mobileNo => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/beneficiary-request/checkBeneficiaryForRepayment',
			method: 'POST',
			body: { mobileNo },
			addToken: false
		}
		RequestService.send(req).then(
			res => {
				console.log(res)
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

var photoUpload = file => {
	return new Promise((resolve, reject) => {
		var data = new FormData()
		data.append('photo', file)
		var req = {
			url: '/public/beneficiary-request/upload',
			method: 'POST',
			body: data,
			addToken: false
		}
		RequestService.sendMultipart(req)
			.then(res => {
				console.log(res)
				return resolve(res)
			})
			.catch(err => {
				console.error(err)
				return reject(err)
			})
	})
}

const BeneficiaryCreateService = {
	create,
	getAll,
	photoUpload,
	checkUserExists,
	checkBeneficiaryForRepayment
}
export default BeneficiaryCreateService
