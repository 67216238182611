
import bnLang from './entries/bn-BD'
import enLang from './entries/en-US'

const AppLocale = {
	en: enLang,
	bn: bnLang
}

export default AppLocale
