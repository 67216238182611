export const PartnerData = [
	{
		id: 1,
		title: 'Logo 1',
		img: 'images/1.png',
	},
	{
		id: 2,
		title: 'Logo 2',
		img: 'images/2.png',
	},
	{
		id: 3,
		title: 'Logo 3',
		img: 'images/4.png',
	},
	{
		id: 4,
		title: 'Logo 4',
		img: 'images/1.png',
	},
	{
		id: 5,
		title: 'Logo 5',
		img: 'images/5.png',
	},
	{
		id: 6,
		title: 'Logo 6',
		img: 'images/6.png',
	},
	{
		id: 7,
		title: 'Logo 7',
		img: 'images/7.png',
	},
	{
		id: 8,
		title: 'Logo 8',
		img: 'images/2.png',
	},
	{
		id: 9,
		title: 'Logo 9',
		img: 'images/4.png',
	},
	{
		id: 10,
		title: 'Logo 10',
		img: 'images/1.png',
	},

]
