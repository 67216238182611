/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import './success.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'
import UserProductService from '../../services/Public/Product'

const Success = ({ intl }) => {
	const history = useNavigate()
	const { id } = useParams()
	const [userProduct, setUserProduct] = useState({})

	useEffect(() => {
		// if (id.length !== 24) {
		// 	history.push('/error404')
		// }
		UserProductService.get(id).then(
			res => {
				if (res.state === 'success') {
					setUserProduct(res.userProduct)
				} else {
					history.push('/error404')
					// message.warning(intl.formatMessage({id: res.message}), 10)
				}
			},
			err => {
				history.push('/error404')
				message.error(intl.formatMessage({ id: err.message }), 10)
			}
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='container py-5 mt-2'>
			<div className='row'>
				<div
					className='col text-center success-heading'
					style={{ marginLeft: '-15px' }}
				>
					<h1>Your Donation was Successfully Completed</h1>
				</div>
				<div className='col-md-8 offset-md-2' style={{ textAlign: 'center' }}>
					<p>
						Thank you for your kind donation! Your gift was processed
						successfully and you will receive a SMS or Email shortly!
					</p>
				</div>
				<div className='row'>
					<div className='col-md-8 offset-md-2'>
						<div className='card'>
							<div className='card-body'>
								<div className='row'>
									<div className='col-md-6'>
										<h6>Donation Information</h6>
									</div>
									<div className='col-md-6'></div>
									<div className='col-md-6'>
										<p>Donation Type</p>
									</div>
									<div className='col-md-6'>
										<p>
											{userProduct.name ? (
												<>
													<IntlMessages id={userProduct.name} />
													{/* <IntlMessages id={userProduct.productType} /> */}
												</>
											) : (
												'Loading'
											)}
										</p>
									</div>
									<div className='col-md-6'>
										<p>Donation Amount</p>
									</div>
									<div className='col-md-6'>
										<p>{userProduct ? userProduct.amount : 'Syncing'}</p>
									</div>
									<div className='col-md-6'>
										<p>Transanction ID</p>
									</div>
									<div className='col-md-6'>
										<p>
											{userProduct.amount
												? userProduct.payment.payment_id.toUpperCase()
												: 'Syncing'}
										</p>
									</div>
									<div className='col-md-6'>
										<p>Date</p>
									</div>
									<div className='col-md-6'>
										<p>
											{userProduct.amount
												? new Date(
													userProduct.created.dateTime
												).toLocaleDateString()
												: 'Syncing'}
										</p>
									</div>
									<div className='col-md-6'>
										<p>Time</p>
									</div>
									<div className='col-md-6'>
										<p>
											{userProduct.amount
												? new Date(
													userProduct.created.dateTime
												).toLocaleTimeString()
												: 'Syncing'}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className='col text-center pt-3'>
							<button className='btn btn-success'>
								<Link to='/' style={{ color: '#fff' }}>
									<IntlMessages id='payment.success.goHome' />
								</Link>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(Success)
