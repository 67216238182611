import { Container, Nav, Navbar } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import BeneficiarySlice from '../store/BeneficiarySlice'

import { injectIntl } from 'react-intl'

const NavMenu = ({ intl }) => {
	const dispatch = useDispatch()
	const { setProfile } = BeneficiarySlice.actions
	const handleClick = () => {
		dispatch(setProfile({ portfolio: null }))
	}
	return (
		<Navbar expand='lg' style={{ background: '#7dd078' }}>
			<Container fluid onClick={handleClick}>
				{/* <Navbar.Toggle aria-controls='basic-navbar-nav' /> */}
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='mx-auto nav-lower'>
						<LinkContainer to='/'>
							<Nav.Link>{intl.formatMessage({ id: 'home' })}</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/about' className='px-4'>
							<Nav.Link>{intl.formatMessage({ id: 'about' })}</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/projects'>
							<Nav.Link>{intl.formatMessage({ id: 'our.projects' })}</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/gallery' className='px-4'>
							<Nav.Link>{intl.formatMessage({ id: 'gallery' })}</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/news'>
							<Nav.Link>{intl.formatMessage({ id: 'news.heading' })}</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/contact' className='px-4'>
							<Nav.Link>{intl.formatMessage({ id: 'contact' })}</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/archive' className='px-4'>
							<Nav.Link>{intl.formatMessage({ id: 'archive' })}</Nav.Link>
						</LinkContainer>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}
export default injectIntl(NavMenu)
