import { injectIntl } from 'react-intl'
import BelowSliderSection from '../../../components/BelowSliderSection'
import Bannercrumb from '../../../util/banner/Bannercrumb'

const BeneficiaryLogin = ({ intl }) => {
	return (
		<>
			<Bannercrumb bannerId='login-banner' />
			<div className='container-fluid'>
				<div
					className='row'
					style={{
						border: '2px solid #7dd078'
						// borderRight: '2px solid lightgrey'
					}}
				>
					<div className='col-md-6 ps-0 pe-0'>
						<div
							className='card card-bene bg-dark text-white backface border-0'
							style={{ border: '1px solid #78d673', borderRadius: '0' }}
						>
							<img
								src='https://cdn.pixabay.com/photo/2014/11/13/06/12/boy-529067_1280.jpg'
								alt='Stony Beach'
							/>
							<div className='card-img-overlay'>
								<h6
									style={{
										position: 'absolute',
										textAlign: 'center',
										bottom: '1.25rem',
										fontSize: '1rem',
										color: 'white'
									}}
								>
									Ayman
								</h6>
								<p
									style={{
										position: 'absolute',
										textAlign: 'center',
										bottom: '0.1rem',
										color: '#7dd078',
										fontSize: '0.75rem'
									}}
								>
									Children
								</p>
							</div>
						</div>
					</div>
					<div className='col-md-6 d-flex justify-content-center align-items-center'>
						<div className='card'>
							<div className='card-body'>
								<p className='card-subtitle mb-4 text-muted text-center'>
									{intl.formatMessage({ id: 'login' })}
								</p>

								<form>
									<label className='form-label text-muted'>
										{intl.formatMessage({ id: 'mobile' })}
									</label>
									<div className='input-group flex-nowrap mb-4'>
										{/* <span className='input-group-text'>
											{intl.formatMessage({ id: '+880' })}
										</span> */}
										<input type='text' className='form-control' />
										<button type='submit' className='ms-3 btn btn-success'>
											{intl.formatMessage({ id: 'otp' })}
										</button>
									</div>

									<label className='form-label text-muted'>
										{intl.formatMessage({ id: 'otp.sent' })}
									</label>
									<div className='mb-4 input-group flex-nowrap'>
										<input
											type='text'
											className='form-control'
											placeholder={intl.formatMessage({ id: 'otp.enter' })}
										/>
										<span className='mx-3 mt-3 text-muted'>
											{intl.formatMessage({ id: 'otp.not.sent' })}
										</span>
										<button type='submit' className='ms-3 btn btn-success'>
											{intl.formatMessage({ id: 'otp.again' })}
										</button>
									</div>

									<div className='d-grid gap-2'>
										<button type='submit' className='btn btn-success'>
											{intl.formatMessage({ id: 'login.confirm' })}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<BelowSliderSection />
		</>
	)
}

export default injectIntl(BeneficiaryLogin)
