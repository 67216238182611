const { createSlice } = require('@reduxjs/toolkit')



const FormSlice = createSlice({
	name: 'formMulti',
	initialState: {
		currentStep: 0,
		currentZilla: '',
		typeofThana: 1
	},
	reducers: {
		setCurrentStep: (state, action) => {
			state.currentStep = action.payload
		},
		setCurrentZilla: (state, action) => {
			state.currentZilla = action.payload
		},
		setTypeofThana: (state, action) => {
			state.typeofThana = action.payload
		}
	}
})

export default FormSlice
