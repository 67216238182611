const beneficiaries = [
	{
		id: 1,
		name: 'Amazing children',
		occupation: 'Children',
		src: '/imgs/1.jpg'
	},
	{
		id: 2,
		name: 'Atiya',
		occupation: 'Madrasa student',
		src: '/imgs/2.jpg'
	},
	{
		id: 3,
		name: 'Abdul Majid',
		occupation: 'Van Driver',
		src: '/imgs/3.jpg'
	},
	{
		id: 4,
		name: 'Abdul Hannan',
		occupation: 'Rickshaw-puller',
		src: '/imgs/4.jpg'
	},
	{
		id: 5,
		name: 'Abdul Matin',
		occupation: 'Rickshaw-puller',
		src: '/imgs/5.jpg'
	},
	{
		id: 6,
		name: 'Aklima',
		occupation: 'Slum-dweller',
		src: '/imgs/6.jpg'
	},
	{
		id: 7,
		name: 'Ariya',
		occupation: 'Orphan',
		src: '/imgs/7.jpg'
	},

	{
		id: 8,
		name: 'Abdul Majid',
		occupation: 'Rickshaw-puller',
		src: '/imgs/9.jpg'
	},

	{
		id: 9,
		name: 'Abdul Majid',
		occupation: 'Rickshaw-puller',
		src: '/imgs/1.jpg'
	},
	{
		id: 10,
		name: 'Abdul Majid',
		occupation: 'Rickshaw-puller',
		src: '/imgs/2.jpg'
	},
	{
		id: 11,
		name: 'Abdul Majid',
		occupation: 'Rickshaw-puller',
		src: '/imgs/3.jpg'
	},
	{
		id: 12,
		name: 'Abdul Majid',
		occupation: 'Rickshaw-puller',
		src: '/imgs/4.jpg'
	},
	{
		id: 13,
		name: 'Ariya',
		occupation: 'Orphan',
		src: '/imgs/7.jpg'
	},

	{
		id: 14,
		name: 'Abdul Majid',
		occupation: 'Rickshaw-puller',
		src: '/imgs/9.jpg'
	}
]
export default beneficiaries
