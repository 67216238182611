import React from 'react'
import donationItems from '../../../donationItems'
import DonorDonationItem from './DonorDonationItem'

import { injectIntl } from 'react-intl'

const DonorDonationItemList = ({ intl }) => {
	return (
		<div className='row'>
			{donationItems.map(d => <DonorDonationItem key={d.id} donation={d} />)}
		</div>
	)
}

export default injectIntl(DonorDonationItemList)