import { Col, Container, Row } from 'react-bootstrap'
//import './bene-detail.css'
//import './profileGallery.css'

import { injectIntl } from 'react-intl'

const Profile = ({ intl, profile }) => {
	return (
		<div className='analysis h-50 position-relative w-100'>
			<Container className='gx-3'>
				<Row className='g-0'>
					<Col>
						<h2>{profile.name}</h2>
						{/* <h6>{profile.occupationLang}</h6> */}
					</Col>
				</Row>
				<Row className='g-0'>
					<Col>
						<h6>
							{intl.formatMessage({ id: 'nav.family.info.age' })}: {profile.age}
						</h6>
					</Col>
				</Row>
				<Row className='g-0'>
					<Col></Col>
				</Row>
				<Row className='g-0'>
					<Col>
						<h6>
							{intl.formatMessage({
								id: 'nav.volunteer.subtitle.presentAddress'
							})}
							: {profile.presentAddress}
						</h6>
					</Col>
				</Row>
				{/* <Row className='g-0'>
					<Col>
						<h6>
							{intl.formatMessage({
								id: 'nav.volunteer.subtitle.permanentAddress'
							})}
							: {profile.permanentAddress}
						</h6>
					</Col>
				</Row> */}
				<Row className='g-0'>
					<Col>
						<h6>
							{intl.formatMessage({
								id: 'donation.goal'
							})}
							:{' '}
							<span
								style={{
									textDecorationLine: profile.paid ? 'line-through' : 'none'
								}}
							>
								{profile.donationItem?.price} ৳
							</span>
							{profile.paid && (
								<span style={{ color: 'yellow', marginLeft: '10px' }}>
									COMPLITE
								</span>
							)}
						</h6>
						{!profile.paid && (
							<h6>
								{intl.formatMessage({
									id: 'donation.collected'
								})}
								: <span>{profile.donated} ৳</span>
							</h6>
						)}
					</Col>
				</Row>
				<Row className='g-0'>
					<Col>
						<h6>
							{intl.formatMessage({
								id: 'donation.item'
							})}
							: {profile.donationItem?.name}
						</h6>
					</Col>
				</Row>
				<Row className='g-0'>
					<Col>
						<h6>
							{intl.formatMessage({
								id: 'description'
							})}
							: {profile.donationItem?.description}
						</h6>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default injectIntl(Profile)
