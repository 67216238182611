import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import BeneficiarySlice from '../../store/BeneficiarySlice'
import Bannercrumb from '../../util/banner/Bannercrumb'
import DonateEntry from '../DonateEntry'


function Donate() {
	const param = useParams()
	const dispatch = useDispatch()
	const { setProfile, setDonate } = BeneficiarySlice.actions
	dispatch(setProfile({ portfolio: null }))
	return (
		<>
			<Bannercrumb bannerId={param.routeExtension} />
			<div className='container-fluid pt-3'>
				<div className='row'>
					<div className='col-md-10 offset-md-1 g-0'>
						<DonateEntry routeExtension={param.routeExtension} />
					</div>
				</div>
			</div>
		</>
	)
}

export default Donate
