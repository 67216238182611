import RequestService from './Request'

var create = (name, description, routeExtension, photo, price) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/donation-product',
			method: 'POST',
			body: {
				name: name,
				description: description,
				routeExtension: routeExtension,
				photo: photo,
				price: price
			},
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

var update = (id, name, description, photo, price) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/donation-product' + id,
			method: 'PUT',
			body: {
				id: id,
				name: name,
				description: description,
				photo: photo,
				price: price
			},
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

var activate = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/donation-product/' + id + '/activate',
			method: 'PUT',
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

var deactivate = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/donation-product/' + id + '/deactivate',
			method: 'PUT',
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

var getAll = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/product',
			method: 'GET',
			addToken: false
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

var get = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/donation-product/' + id,
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

var search = (
	searchQuery = undefined,
	sortBy = undefined,
	itemsPerPage = undefined,
	pageIndex = undefined
) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/donation-product',
			method: 'PUT',
			body: {
				searchQuery: searchQuery,
				sortBy: sortBy,
				pagination:
					itemsPerPage || pageIndex
						? { itemsPerPage: itemsPerPage, pageIndex: pageIndex }
						: undefined
			},
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

export default {
	activate,
	deactivate,
	getAll,
	get,
	search,
	create,
	update
}
