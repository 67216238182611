import BeneficiaryList from './BeneficiaryList'
import Activities from './MoreComponents/activities/Activities'
import Donors from './MoreComponents/doners/Donors'
import Qarz from './MoreComponents/qarz/Qarz'
import Sadakah from './MoreComponents/sadakah/Sadakah'
import Zakat from './MoreComponents/zakat/Zakat'

function HomePage() {
	return (
		<>
			<BeneficiaryList />
			<Sadakah />
			<Qarz />
			<Zakat />
			<Activities />
			<Donors />
		</>
	)
}

export default HomePage
