/* eslint-disable react/prop-types */
import { message, Select, Collapse, DatePicker, TimePicker, Upload } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
const { Panel } = Collapse
import { injectIntl } from 'react-intl'
import LoadingBar from 'react-top-loading-bar'
import './donateEntry.css'
// import IntlMessages from './../util/IntlMessages'
import { useNavigate } from 'react-router-dom'
import IntlMessages from './../util/IntlMessages'
import ProductService from './../services/Product'
import DonateOffline from './../services/DonateOffline'

// import DimensionService from './../services/Dimensions'

// const phoneRegex = new RegExp(/(^(\+88)?(01){1}[3456789]{1}(\d){8})$/)
// // eslint-disable-next-line no-useless-escape
// const emailRegex = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/)
// const phoneOrEmailRegex = new RegExp(
// 	phoneRegex.source + '|' + emailRegex.source
// )
const maxFileSizeInMB = 1
const paymentMethods = [
	{ value: 'deposit', label: 'Deposit to Bank Account' },
	{ value: 'mobileBanking', label: 'Mobile Banking' },
	{
		value: 'collectTheDonation',
		label: 'No More Poverty representative will collect the donation from you'
	}
]
const paymentMethodsO = paymentMethods.reduce((acc, item) => {
	acc[item.value] = item.label
	return acc
}, {})

const mobileBankingTypes = [
	{ value: 'Bkash', label: 'Bkash' },
	{ value: 'Nagad', label: 'Nagad' },
	{ value: 'Rocket', label: 'Rocket' }
]

function DonateEntry({ intl, routeExtension }) {
	console.log('routeExtension', routeExtension)
	const loadingRef = useRef(null)
	const [loading, setLoading] = useState(false)
	const [product, setProduct] = useState({})
	const [amount, setAmount] = useState(0)
	const [fileList, setFileList] = useState([])
	const [mobileBankingType, setMobileBankingType] = useState('')
	const [mobileBankingNumber, setMobileBankingNumber] = useState('')
	const [transactionID, setTransactionID] = useState('')
	const [donorAddress, setDonorAddress] = useState('')
	const [collectDate, setCollectDate] = useState('')
	const [collectTime, setCollectTime] = useState('')
	const [photo, setPhoto] = useState('')

	const [phoneOrEmail, setPhoneOrEmail] = useState('')
	const [donorName, setDonorName] = useState('')
	const [paymentMethod, setPaymentMethod] = useState('')
	// const [disableAmountField, setDisableAmountField] = useState(true)
	const [anonymous, setAnonymous] = useState(false)
	const [editAmount, setEditAmount] = useState(true)
	const [donateDone, setDonateDone] = useState(false)

	const profile = useSelector(state => state.beneficiaries.profile)
	console.log(profile)
	const navigate = useNavigate()
	//console.log(photo)

	useEffect(() => {
		loadingRef.current.continuousStart()
		ProductService.getAll().then(
			res => {
				loadingRef.current.complete()
				//console.log(res.products)
				if (res.state === 'success') {
					setLoading(false)
					// localStorage.setItem('products', JSON.stringify(res.products))
					const product = res.products.find(
						item => item.routeExtension === routeExtension
					)
					if (product) {
						setProduct(product)
					} else {
						navigate('/')
					}
				} else {
					message.warning(intl.formatMessage({ id: res.message }))
				}
			},
			err => {
				loadingRef.current.complete()
				console.error(err)
				message.error(intl.formatMessage({ id: 'backend.network.error' }))
			}
		)
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSubmit = () => {
		if (!loading) {
			if (/(^(01){1}[3456789]{1}(\d){8})$/.test(phoneOrEmail)) {
				setPhoneOrEmail('+88' + phoneOrEmail)
			}
			setLoading(true)
			loadingRef.current.continuousStart()
			DonateOffline.donate(
				amount,
				donorName,
				phoneOrEmail,
				paymentMethod,
				donorAddress,
				collectDate,
				collectTime,
				mobileBankingType,
				mobileBankingNumber,
				transactionID,
				photo,
				profile?._id,
				product?._id
			).then(
				res => {
					setLoading(false)
					loadingRef.current.complete()
					if (res.state === 'success') {
						setDonateDone(true)
					} else {
						message.warning(intl.formatMessage({ id: res.message }))
					}
				},
				err => {
					setLoading(false)
					loadingRef.current.complete()
					console.error(err)
					message.error(intl.formatMessage({ id: 'backend.network.error' }))
				}
			)
		}
	}

	const handleChange = e => {
		setAmount(parseInt(e.target.value))
	}

	const handleDonorName = e => {
		setDonorName(e.target.value)
	}

	const handlePhoneOrEmail = e => {
		setPhoneOrEmail(e.target.value)
	}

	const handleAnonymous = e => {
		e.target.checked ? setAnonymous(true) : setAnonymous(false)
	}

	const handleAmountInput = n => {
		setAmount(n)
		setEditAmount(true)
	}
	const onChangeDate = (date, dateString) => {
		//console.log(date, dateString)
		setCollectDate(dateString)
	}
	const onChangeTime = (date, dateString) => {
		//console.log(date, dateString)
		setCollectTime(dateString)
	}
	//console.log(collectTime, collectDate)
	const setDisabled = () => {
		let val = Boolean(
			!paymentMethod ||
				!phoneOrEmail ||
				!amount ||
				!donorName ||
				(profile && profile.donationTarget - profile.donated < amount)
		)
		if (val) {
			return val
		}
		if (paymentMethod === 'deposit') {
			return !photo
		} else if (paymentMethod === 'mobileBanking') {
			return Boolean(
				!mobileBankingType || !mobileBankingNumber || !transactionID
			)
		} else if (paymentMethod === 'collectTheDonation') {
			return Boolean(!donorAddress || !collectDate || !collectTime)
		}
	}

	const beforeUpload = file => {
		// console.log(file)
		const isValidType = file.type === 'image/png' || file.type === 'image/jpeg'
		const isAllowedSize = file.size <= 1024 * 1024 * maxFileSizeInMB
		if (!isValidType) {
			message.error(`${file.name} is not a png/jpeg file`)
		} else if (!isAllowedSize) {
			message.error(`${file.name} exceeds ${maxFileSizeInMB} MB file size`)
		}
		return (isValidType && isAllowedSize) || Upload.LIST_IGNORE
	}

	const uploadPhoto = options => {
		const { onSuccess, onError, file } = options

		DonateOffline.photoUpload(file).then(
			res => {
				console.log('photoUpload ', res)

				if (res.state === 'success') {
					onSuccess(res)
					if (res.photo && res.photo.url) {
						setPhoto(res.photo.url)
					}
				} else {
					// console.log(res)
					onError(res)
				}
			},
			err => {
				console.error(err)
				onError(err)
			}
		)
	}

	const onChangeImg = ({ fileList: newFileList }) => {
		const file = newFileList[0]

		if (file) {
			const isValidType =
				file.type === 'image/png' || file.type === 'image/jpeg'
			const isAllowedSize = file.size <= 1024 * 1024 * maxFileSizeInMB
			if (isValidType && isAllowedSize) {
				setFileList(newFileList)
			}
			console.log(newFileList)
		} else {
			console.log('photo,file', file)
			setPhoto('')
			setFileList(newFileList)
		}
	}

	const onPreview = async file => {
		let src = file.url

		if (!src) {
			src = await new Promise(resolve => {
				const reader = new FileReader()
				reader.readAsDataURL(file.originFileObj)

				reader.onload = () => resolve(reader.result)
			})
		}

		const image = new Image()
		image.src = src
		const imgWindow = window.open(src)
		imgWindow?.document.write(image.outerHTML)
	}

	return (
		<>
			<LoadingBar height={3} color='#7dd078' ref={loadingRef} />
			{donateDone && (
				<div
					className='h-100 w-100  xyz-in'
					style={{
						flexDirection: 'column',
						paddingTop: '20px',
						paddingBottom: '20px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
					xyz='fade up small duration-10'
				>
					<h3> Thank you for your kind donation!</h3>
					<div style={{ width: '90%' }}>
						Donation Amount: <span style={{ fontWeight: 600 }}>{amount}</span>
					</div>
					<div style={{ width: '90%' }}>
						Donor Name: <span style={{ fontWeight: 600 }}>{donorName}</span>
					</div>
					<div style={{ width: '90%' }}>
						Phone / Email:{' '}
						<span style={{ fontWeight: 600 }}>{phoneOrEmail}</span>
					</div>
					<div style={{ width: '90%' }}>
						Payment method:{' '}
						<span style={{ fontWeight: 600 }}>
							{paymentMethodsO[paymentMethod]}
						</span>
					</div>
					{paymentMethod === 'deposit' && (
						<>
							<div style={{ width: '90%' }}>
								Payment confirmation:{' '}
								<span style={{ fontWeight: 600 }}>{photo}</span>
							</div>
						</>
					)}
					{paymentMethod === 'mobileBanking' && (
						<>
							<div style={{ width: '90%' }}>
								Mobile Banking Type :{' '}
								<span style={{ fontWeight: 600 }}>{mobileBankingType}</span>
							</div>
							<div style={{ width: '90%' }}>
								Mobile Banking Number :{' '}
								<span style={{ fontWeight: 600 }}>{mobileBankingNumber}</span>
							</div>
							<div style={{ width: '90%' }}>
								Transaction ID :{' '}
								<span style={{ fontWeight: 600 }}>{transactionID}</span>
							</div>
						</>
					)}
					{paymentMethod === 'collectTheDonation' && (
						<>
							<div style={{ width: '90%' }}>
								Address :{' '}
								<span style={{ fontWeight: 600 }}>{donorAddress}</span>
							</div>
							<div style={{ width: '90%' }}>
								Date : <span style={{ fontWeight: 600 }}>{collectDate}</span>
							</div>
							<div style={{ width: '90%' }}>
								Time : <span style={{ fontWeight: 600 }}>{collectTime}</span>
							</div>
						</>
					)}
				</div>
			)}
			{!donateDone && (
				<div
					className='h-100 w-100 control-flow xyz-in donate-entry'
					xyz='fade up small duration-10'
				>
					<div className='h-100'>
						<div className='card h-100'>
							<div className='card-body' style={{ background: 'white' }}>
								<div>
									<div
										className='donate-amount'
										role='group'
										aria-label='Basic radio toggle button group'
									>
										<div className='row my-3'>
											<div className='col'>
												<input
													type='radio'
													className='btn-check'
													name='btnradio'
													id='btnradio1'
													autoComplete='off'
												/>
												<label
													className='btn btn-outline-success w-100 form-radio-btn btn-lg'
													htmlFor='btnradio1'
													onClick={() => handleAmountInput(100)}
												>
													100৳
												</label>
											</div>
											<div className='col'>
												<input
													type='radio'
													className='btn-check'
													name='btnradio'
													id='btnradio2'
													autoComplete='off'
												/>
												<label
													className='btn btn-outline-success w-100 form-radio-btn btn-lg'
													htmlFor='btnradio2'
													onClick={() => handleAmountInput(1000)}
												>
													1000৳
												</label>
											</div>
											<div className='col'>
												<input
													type='radio'
													className='btn-check'
													name='btnradio'
													id='btnradio3'
													autoComplete='off'
												/>
												<label
													className='btn btn-outline-success w-100 form-radio-btn btn-lg'
													htmlFor='btnradio3'
													onClick={() => handleAmountInput(5000)}
												>
													5000৳
												</label>
											</div>
										</div>
										<div className='row my-3'>
											<div className='col'>
												<input
													type='radio'
													className='btn-check'
													name='btnradio'
													id='btnradio4'
													autoComplete='off'
												/>
												<label
													className='btn btn-outline-success w-100 form-radio-btn btn-lg'
													htmlFor='btnradio4'
													onClick={() => handleAmountInput(10000)}
												>
													10000৳
												</label>
											</div>
											<div className='col'>
												<input
													type='radio'
													className='btn-check'
													name='btnradio'
													id='btnradio5'
													autoComplete='off'
												/>
												<label
													className='btn btn-outline-success w-100 form-radio-btn btn-lg'
													htmlFor='btnradio5'
													onClick={() => handleAmountInput(50000)}
												>
													50000৳
												</label>
											</div>
											<div className='col'>
												<input
													type='radio'
													className='btn-check'
													name='btnradio'
													id='btnradio6'
													autoComplete='off'
												/>
												<label
													onClick={() => setEditAmount(false)}
													className='btn btn-outline-success w-100 form-radio-btn btn-lg'
													htmlFor='btnradio6'
												>
													Other
												</label>
											</div>
										</div>
									</div>

									<div className='row my-3'>
										<label className='col-md-4 text-end col-form-label'>
											Donation Amount:
										</label>
										<div className='col-md-8 '>
											<input
												className='form-control'
												type='number'
												value={amount}
												disabled={editAmount}
												onChange={handleChange}
											/>
										</div>
									</div>
									{profile && profile.donationTarget && (
										<div className='row my-1'>
											<label className='col-md-4 text-end col-form-label'></label>
											<div
												className='col-md-8 '
												style={{
													color:
														profile.donationTarget - profile.donated >= amount
															? 'rgb(125, 208, 120)'
															: 'red'
												}}
											>
												Maximum amount you can donate to this beneficiary:{' '}
												{profile.donationTarget - profile.donated} ৳
											</div>
										</div>
									)}
									{/* <div className='form-check row mx-2'>
								<div className='col-md-3 offset-md-4'>
									<input
										className='form-check-input'
										type='checkbox'
										value=''
										id='flexCheckDefault'
										onChange={handleAnonymous}
									/>
									<label
										className='form-check-label'
										htmlFor='flexCheckDefault'
										style={{ color: '#7dd078' }}
									>
										Stay Anonymous
									</label>
								</div>
							</div> */}

									<div className='row my-3'>
										<label className='col-md-4 text-end col-form-label'>
											Donor Name:
										</label>
										<div className='col-md-8 '>
											<input
												className='form-control'
												onChange={handleDonorName}
											/>
										</div>
									</div>

									<div className='row my-3'>
										<label className='col-md-4 text-end col-form-label'>
											Phone / Email:
										</label>
										<div className='col-md-8 '>
											<input
												className='form-control'
												onChange={handlePhoneOrEmail}
											/>
										</div>
									</div>
									<div className='row my-3'>
										<label className='col-md-4 text-end col-form-label'>
											Payment method:
										</label>
										<div className='col-md-8 '>
											<Select
												placeholder='Please select the payment method.'
												// dropdownStyle={{ backgroundColor: 'green' }}
												style={{
													width: '100%'
												}}
												onChange={value => setPaymentMethod(value)}
												options={paymentMethods}
											/>
										</div>
									</div>

									<Collapse
										activeKey={paymentMethod === 'deposit' ? 1 : 0}
										className={
											paymentMethod !== 'deposit' ? 'close-collapse' : ''
										}
									>
										<Panel showArrow={false} key='1'>
											Please deposit or transfer the{' '}
											{amount === 0
												? 'SELECT AMOUTN'
												: `donation amount BDT ${amount}`}{' '}
											to the following bank account:
											<br />
											Account Name : No More Poverty <br />
											Account Number : 87346586753678 <br />
											Bank : Islami Bank Bangladesh ltd <br />
											Branch : Gulshan, Dhaka. <br />
											Routing Number : 125260341 <br />
											Swift Code : IBBLBDDH
											<br />
											Once you have completed the deposit, please take a picture
											of the deposit receipt and upload below, in case of online
											bank transfer, upload the screenshot of the transfer.
											<div className='row my-3'>
												<label className='col-md-4 text-end col-form-label'>
													Payment confirmation :
												</label>
												<div className='col-md-8 '>
													<Upload
														customRequest={uploadPhoto}
														listType='picture-card'
														fileList={fileList}
														multiple={false}
														onChange={onChangeImg}
														onPreview={onPreview}
														beforeUpload={beforeUpload}
													>
														{fileList.length < 1 && (
															<IntlMessages id='nav.volunteer.formImage' />
														)}
													</Upload>
												</div>
											</div>
										</Panel>
									</Collapse>
									<Collapse
										activeKey={paymentMethod === 'mobileBanking' ? 1 : 0}
										className={
											paymentMethod !== 'mobileBanking' ? 'close-collapse' : ''
										}
									>
										<Panel showArrow={false} key='1'>
											Please Send Money for the{' '}
											{amount === 0
												? 'SELECT AMOUTN'
												: `donation amount BDT ${amount}`}{' '}
											to the following Bkash/Nagad/Rocket number:
											<div>
												number.Bkash/Nagad/Rocket : 01756400541 <br />
												Once you have completed the Send Money type the sender
												Bkash/Nagad/Rocket Number and Transaction ID below.
											</div>
											<div className='row my-3'>
												<label className='col-md-4 text-end col-form-label'>
													Mobile Banking Type :
												</label>
												<div className='col-md-8 '>
													<Select
														placeholder='Please select mobile banking type.'
														style={{
															width: '100%'
														}}
														onChange={value => setMobileBankingType(value)}
														options={mobileBankingTypes}
													/>
												</div>
											</div>
											<div className='row my-3'>
												<label className='col-md-4 text-end col-form-label'>
													Mobile Banking Number :
												</label>
												<div className='col-md-8 '>
													<input
														className='form-control'
														placeholder='Please enter mobile number'
														onChange={e => {
															setMobileBankingNumber(e.target.value)
														}}
													/>
												</div>
											</div>
											<div className='row my-3'>
												<label className='col-md-4 text-end col-form-label'>
													Transaction ID :
												</label>
												<div className='col-md-8 '>
													<input
														className='form-control'
														placeholder='Please enter transaction ID:'
														onChange={e => {
															setTransactionID(e.target.value)
														}}
													/>
												</div>
											</div>
										</Panel>
									</Collapse>

									<Collapse
										activeKey={paymentMethod === 'collectTheDonation' ? 1 : 0}
										className={
											paymentMethod !== 'collectTheDonation'
												? 'close-collapse'
												: ''
										}
									>
										<Panel showArrow={false} key='1'>
											Please let us know your full address, a date and time, our
											representative will collect the donation from you.
											<br />
											<div className='row my-3'>
												<label className='col-md-4 text-end col-form-label'>
													Address :
												</label>
												<div className='col-md-8 '>
													<input
														className='form-control'
														onChange={e => {
															setDonorAddress(e.target.value)
														}}
													/>
												</div>
											</div>
											<div className='row my-3'>
												<label className='col-md-4 text-end col-form-label'>
													Date :
												</label>
												<div className='col-md-8 '>
													<DatePicker
														onChange={onChangeDate}
														style={{
															width: '100%'
														}}
													/>
												</div>
											</div>
											<div className='row my-3'>
												<label className='col-md-4 text-end col-form-label'>
													Time :
												</label>
												<div className='col-md-8 '>
													<TimePicker
														onChange={onChangeTime}
														style={{
															width: '100%'
														}}
													/>
												</div>
											</div>
										</Panel>
									</Collapse>

									<div className='row p-3'>
										<button
											className='btn btn-success'
											type='submit'
											onClick={handleSubmit}
											disabled={setDisabled()}
										>
											<span
												className='fs-6 fw-bold'
												style={{ letterSpacing: '3px' }}
											>
												DONATE
											</span>
										</button>
									</div>
									<div className='text-center'>
										<p>
											By pressing Donate, you agree to our{' '}
											<span style={{ color: '#7dd078' }}>
												Terms and Conditions
											</span>{' '}
											and{' '}
											<span style={{ color: '#7dd078' }}>Privacy Policy.</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default injectIntl(DonateEntry)
