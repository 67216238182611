import { BsChevronRight } from 'react-icons/bs'
import {
	FaEnvelope,
	FaFacebookF,
	FaInstagram,
	FaLinkedinIn,
	FaMapMarkerAlt,
	FaPhoneAlt,
	FaTelegramPlane,
	FaTwitter,
	FaYoutube
} from 'react-icons/fa'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import '../footer/Footer.css'

const Footer = ({ intl }) => {
	const myStyle = {
		backgroundImage:
			'linear-gradient(rgba(219, 243, 227,0.85), rgba(219, 243, 227,0.85)), url(images/footer.jpg)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	}

	return (
		<footer style={myStyle}>
			<div className='container-lg container-fluid'>
				<div className='row gy-4 gy-md-4 gy-lg-0'>
					<div className='col-lg-4 col-md-5 col-sm-12'>
						<Link to='/'>
							<img
								className='footer-logo'
								src='/images/nmp-logo.svg'
								height='60px'
								width='auto'
								alt='No More Poverty'
							/>
						</Link>

						<p className='short-info'>
							{intl.formatMessage({ id: 'footer.lorem' })}
						</p>

						<ul className='footer-contact'>
							<li>
								<span>
									<FaMapMarkerAlt />
								</span>{' '}
								{intl.formatMessage({ id: 'full.address' })}
							</li>
							<li>
								<span>
									<FaPhoneAlt />
								</span>{' '}
								+880XXXXXXXXX
							</li>
							<li>
								<span>
									<FaEnvelope />
								</span>{' '}
								info@nomorepoverty.com
							</li>
						</ul>
					</div>

					<div className='col-lg-4 col-md-7 col-sm-12'>
						<div className='row'>
							<div className='col-6'>
								<h6>{intl.formatMessage({ id: 'programs' })}</h6>
								<ul className='list-unstyled'>
									<li>
										<Link to='/general'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'general' })}
										</Link>
									</li>
									<li>
										<Link to='/zakat'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'zakat' })}
										</Link>
									</li>
									<li>
										<Link to='/qarzehasana'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'qarzehasana' })}
										</Link>
									</li>
									<li>
										<Link to='/beneficiaryrepayment'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'beneficiaryrepayment' })}
										</Link>
									</li>
								</ul>
							</div>
							<div className='col-6'>
								<h6>{intl.formatMessage({ id: 'quick.links' })}</h6>
								<ul className='list-unstyled'>
									<li>
										<Link to='/'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'home' })}
										</Link>
									</li>
									<li>
										<Link to='/about'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'about' })}
										</Link>
									</li>
									<li>
										<Link to='/projects'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'our.projects' })}
										</Link>
									</li>
									<li>
										<Link to='/gallery'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'gallery' })}
										</Link>
									</li>
									<li>
										<Link to='/news'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'home.news.title' })}
										</Link>
									</li>
									<li>
										<Link to='/contact'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'contact' })}
										</Link>
									</li>
									<li>
										<Link to='/archive'>
											<span>
												<BsChevronRight />
											</span>
											{intl.formatMessage({ id: 'archive' })}
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className='col-lg-4 col-sm-12'>
						<h6>{intl.formatMessage({ id: 'subscribe.follow' })}</h6>
						<form>
							<div className='input-group'>
								<input
									type='text'
									className='form-control'
									placeholder={intl.formatMessage({ id: 'email.here' })}
								/>
								<button className='btn' type='button' id='button-addon2'>
									<FaTelegramPlane /> {intl.formatMessage({ id: 'subscribe' })}
								</button>
							</div>
							<div className='form-text'>
								{intl.formatMessage({ id: 'email.share' })}
							</div>
						</form>
						<ul className='list-unstyled social-link'>
							<li>
								<Link to='/'>
									<FaFacebookF />
								</Link>
							</li>
							<li>
								<Link to='/'>
									<FaTwitter />
								</Link>
							</li>
							<li>
								<Link to='/'>
									<FaInstagram />
								</Link>
							</li>
							<li>
								<Link to='/'>
									<FaLinkedinIn />
								</Link>
							</li>
							<li>
								<Link to='/'>
									<FaYoutube />
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	)
}
export default injectIntl(Footer)
