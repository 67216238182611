import RequestService from './../Request'

var donate = (id, amount, phoneOrEmail, name) => {
	console.log('amount', amount)
	console.log(typeof amount)
	console.log(phoneOrEmail)
	console.log(name)
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/beneficiary-request/donate/' + id,
			method: 'POST',
			body: {
				amount,
				phoneOrEmail,
				name
			},
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

// var getAll = () => {
// 	return new Promise((resolve, reject) => {
// 		var req = {
// 			url: '/admin/donation-product',
// 			method: 'GET',
// 			addToken: true
// 		}
// 		RequestService.send(req).then(res => {
// 			return resolve(res)
// 		}, err => {
// 			return reject(err)
// 		})
// 	})
// }

export const getAllHeros = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/beneficiary-request/getHeroSection',
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}
export const getAllHerosArch = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/beneficiary-request/getHeroSectionArch',
			method: 'GET',
			addToken: true
		}
		RequestService.send(req).then(
			res => {
				return resolve(res)
			},
			err => {
				return reject(err)
			}
		)
	})
}

export default {
	// getAll,
	getAllHeros,
	donate
}
