import React from 'react'
import '../imgprofile/Imgprofile.css'
import { FaCheck } from 'react-icons/fa'
import { injectIntl } from 'react-intl'

const Imgprofile = ({ intl, margin }) => {

	return (
		<div className="fundrise" style={margin}>
			<img src="images/volunteer.jpg" />
			<div className="chk"><FaCheck /></div>
			<p>{intl.formatMessage({ id: 'quick.fundrise' })}</p>
		</div>
	)
}

export default injectIntl(Imgprofile)
