import { useDispatch } from 'react-redux'
import logo from '../Icon.svg'
import BeneficiarySlice from '../store/BeneficiarySlice'
import './bene-detail.css'

import { injectIntl } from 'react-intl'

const Donate = ({ intl }) => {
	const { setDonate } = BeneficiarySlice.actions
	const dispatch = useDispatch()
	return (
		<div className='text-center'>
			<div className='d-flex justify-content-center'>
				<div>
					<button
						onClick={() => dispatch(setDonate(true))}
						className='btn btn-success btn-sz'
					>
						<strong>
							{intl.formatMessage({ id: 'donate' })}{' '}
							<span>
								<img className='flexbox-container' src={logo} alt='donate' />
							</span>
						</strong>
					</button>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(Donate)
