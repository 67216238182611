import { Button, Cascader, Col, Form, Input, Row, Select } from 'antd'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

import { BiLandscape } from 'react-icons/bi'
import { RiBuilding2Line, RiMoneyDollarBoxLine } from 'react-icons/ri'
import { useEffect, useState } from 'react'
// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }

const OccupationInfo = ({ onChange, previousValue, intl }) => {
	const [isJobDescriptionField, setIsJobDescriptionField] = useState(true)
	const [occupationInfo] = Form.useForm()
	const maxAcre = /^[0-2]{1}(\.\d{1,2})?$/
	const monthlyIncomeRegex = /^([0-9]{1,5}|100000)$/
	// const [isJobDescriptionField, setIsJobDescriptionField] = useState(undefined)

	const handleFormValuesChanged = (changedValues, allValues) => {
		// TODO
	}
	const handleOccupationChange = value => {
		//console.log(value)
		if (value && value[0] === 'JOB-LESS') {
			setIsJobDescriptionField(false)
		} else {
			setIsJobDescriptionField(true)
		}
	}

	const onSubmitOccupationInfo = values => {
		console.log(values)
		onChange(4, values)
	}

	const onPrevious = () => {
		onChange(2)
	}

	const occupationList = [
		'IMAAM-KHATIB',
		'MUAZZIN',
		'DOCTOR',
		'TEACHER',
		'WRITER',
		'FARMER',
		'ENGINEER',
		'LAWYER',
		'BUSINESS-PERSON',
		'COMPUTER-PROGRAMMER',
		'CRAFTER-HANDICRAFTER',
		'JOURNALIST',
		'SOCIAL-WORKER',
		'GRAPHICS-DESIGNER',
		'BANKER',
		'STUDENT',
		'HOUSE-WIFE',
		'GOVT-EMPLOYEE',
		'NON-GOVT-EMPLOYEE',
		'JOB-LESS',
		'OTHERS'
	]

	const occupationStyle = {
		backgroundImage: 'url(images-reg/4.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	}
	console.log(isJobDescriptionField)
	useEffect(() => {
		if (previousValue) {
			occupationInfo.setFieldsValue(previousValue)
			console.log(previousValue)
		}
	}, [previousValue])

	return (
		<>
			<div style={occupationStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='d-none d-lg-block col-lg-6 col-xl-7 display-2 text-end text-white pt-5'>
							Take Actions
						</div>
						<div className='col-lg-6 col-xl-5 bg-white p-5 rounded-3 my-2'>
							<Row>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<h1
										style={{
											// margin: '0 0 30px 0',
											color: '#535353',
											fontWeight: 500,
											fontSize: '2rem',
											lineHeight: '2rem',
											wordWrap: 'breakWord'
										}}
									>
										<IntlMessages id='nav.volunteer.form.occupation' />
									</h1>
								</Col>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<Form
										// {...formItemLayout}
										form={occupationInfo}
										name='occupationInfo'
										onValuesChange={handleFormValuesChanged}
										onFinish={onSubmitOccupationInfo}
										style={{ margineTop: 30 }}
									>
										{/* work info */}
										<div>
											<Form.Item
												hasFeedback
												name='occupation'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.form.occupation'
												// })}
												rules={[
													{
														required: true,
														message: intl.formatMessage({
															id: 'nav.volunteer.occupation.errorMessage'
														})
													}
												]}
											>
												<Cascader
													options={occupationList.map(item => {
														return {
															value: item,
															label: intl.formatMessage({
																id: 'nav.volunteer.form.occupation.' + item
															})
														}
													})}
													onChange={handleOccupationChange}
													// onChange={handleOccupationChange}
													size={'middle'}
													placeholder={
														intl.formatMessage({
															id: 'nav.volunteer.occupation.placeholder'
														}) + '*'
													}
												/>
											</Form.Item>
											<Form.Item
												hasFeedback
												name='institute'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.organization.name'
												// })}
												rules={[
													{
														required: isJobDescriptionField ? true : false,
														min: 5,
														max: 500,
														message: intl.formatMessage({
															id: 'nav.volunteer.education.exam.institute.errorMessage'
														}),
														whitespace: true
													}
												]}
											>
												<Input
													style={{ width: '100%' }}
													prefix={<RiBuilding2Line />}
													placeholder={
														intl.formatMessage({
															id: 'nav.volunteer.organization.name'
														}) + '*'
													}
												/>
											</Form.Item>
											<Form.Item
												hasFeedback
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.experience.Summary'
												// })}
												name='jobDescription'
												rules={[
													{
														required: isJobDescriptionField ? true : false,
														min: 10,
														max: 1000,
														message: intl.formatMessage({
															id: 'nav.volunteer.jobDescription.errorMessage'
														}),
														whitespace: true
													}
												]}
											>
												<Input.TextArea
													rows={2}
													size='large'
													placeholder={
														intl.formatMessage({
															id: 'nav.volunteer.experience.Summary'
														}) + '*'
													}
												/>
											</Form.Item>
											<Form.Item
												hasFeedback
												name='monthlyIncome'
												// label={intl.formatMessage({
												// 	id: 'nav.family.info.monthlyIncome'
												// })}
												rules={[
													{
														required: isJobDescriptionField ? true : false,
														pattern: monthlyIncomeRegex,
														message: intl.formatMessage({
															id: 'nav.family.info.monthlyIncome.errorMessage'
														})
													}
												]}
											>
												<Input
													style={{ width: '100%' }}
													prefix={<RiMoneyDollarBoxLine />}
													placeholder={
														intl.formatMessage({
															id: 'nav.family.info.monthlyIncome'
														}) + '*'
													}
												/>
											</Form.Item>
											<Form.Item
												hasFeedback
												name='netLand'
												// label={intl.formatMessage({ id: 'nav.family.info.netLand' })}
												rules={[
													{
														required: isJobDescriptionField ? true : false,
														pattern: maxAcre,
														message: intl.formatMessage({
															id: 'nav.family.info.netLand.errorMessage'
														})
													}
												]}
											>
												<Input
													type='number'
													prefix={<BiLandscape />}
													style={{ width: '100%' }}
													placeholder={
														intl.formatMessage({
															id: 'nav.family.info.netLand'
														}) + '*'
													}
												/>
											</Form.Item>
										</div>
										<div className='d-flex justify-content-between'>
											<Button
												// type='primary'
												onClick={() => onPrevious()}
												// className='gx-mb-0'
												style={{
													backgroundColor: '#fff',
													color: '#000'
													//width: '100%'
													// float: 'left'
												}}
											>
												<IntlMessages id='nav.form.previous' />
											</Button>
											<Button
												type='primary'
												// size='large'
												htmlType='submit'
												// className='gx-mb-0'
												style={{
													backgroundColor: '#EE0000',
													color: '#ffffff'
													//width: '100%'
													// float: 'left'
												}}
											>
												<IntlMessages id='nav.form.next' />
											</Button>
										</div>
									</Form>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(OccupationInfo)
