import RequestService from './../Request'

var getAll = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/public/product',
			method: 'GET',
			addToken: false
		}
		RequestService.send(req).then(res => {
			return resolve(res)
		}, err => {
			return reject(err)
		})
	})
}

// var get = id => {
// 	return new Promise((resolve, reject) => {
// 		var req = {
// 			url: '/admin/donation-product/' + id,
// 			method: 'GET',
// 			addToken: true
// 		}
// 		RequestService.send(req).then(res => {
// 			return resolve(res)
// 		}, err => {
// 			return reject(err)
// 		})
// 	})
// }



export default {
	getAll
}
