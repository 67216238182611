import { Link } from 'react-router-dom'
import './banner.css'


import { injectIntl } from 'react-intl'

const Bannercrumb = ({ intl, bannerId }) => {
	return <div style={{background:'#ADDAB1'}}>
		<div className='d-flex justify-content-between align-items-center col-md-10 offset-md-1' 
			style={{height:'7rem'}}>
			<div>
				<nav className='delim' aria-label='breadcrumb'>
					<ol className='breadcrumb'>
						<li className='breadcrumb-item'><Link to='/'>{intl.formatMessage({ id: 'home' })}</Link></li>
						<li className='breadcrumb-item active' aria-current='page' style={{color:'green'}}>{intl.formatMessage({ id: `${bannerId}` })}</li>
					</ol>
				</nav>
			</div>
			<div className='display-6'>{intl.formatMessage({ id: `${bannerId}` })}</div>
		</div>
	</div>
}

export default injectIntl(Bannercrumb)
