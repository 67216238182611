import React from 'react'
import Benefited from './Benefited'

function BenefitedList() {
	const benefited = [
		{ id: 1, donor: 'Abu Taher', amount: 100, when: '18 days ago' },
		{ id: 2, donor: 'Golam Mostafa', amount: 10, when: '28 days ago' },
		{ id: 3, donor: 'Abdul Majid', amount: 15, when: '28 days ago' },
		{ id: 4, donor: 'Abdu Taher', amount: 150, when: '28 days ago' },
		{ id: 5, donor: 'Abdu Taher', amount: 176, when: '24 days ago' },
		{ id: 6, donor: 'Abdu Taher', amount: 155, when: '248 days ago' },
		{ id: 7, donor: 'Abdu Taher', amount: 166, when: '2 days ago' },
	]
	return (
		<div className='ms-3'>
			{benefited.map(d => <Benefited key={d.id} donor={d.donor} amount={d.amount} when={d.when} />)}
		</div>
	)
}

export default BenefitedList
