import React from 'react'
import Donated from './Donated'

function DonatedList() {
	const donated = [
		{ id: 1, description: 'We bought Abdul Majid a Rickdhaw. ', date: 'Sep 28' },
		{ id: 2, description: 'We bought Labib a Laptop. ', date: 'Dec 18' },
		{ id: 3, description: 'Abdu Taher got admitted to a freelancing course. ', date: 'Sep 29' },
		{ id: 4, description: 'Abdu Taher got admitted to a freelancing course. ', date: 'Sep 29' },
		{ id: 5, description: 'Abdu Taher got admitted to a freelancing course. ', date: 'Sep 29' },
		{ id: 6, description: 'Abdu Taher got admitted to a freelancing course. ', date: 'Sep 29' },
		{ id: 7, description: 'Abdu Taher got admitted to a freelancing course. ', date: 'Sep 29' },
	]
	return (
		<div className='ms-3'>
			{donated.map(d => <Donated key={d.id} description={d.description} date={d.date} />)}
		</div>
	)
}

export default DonatedList
