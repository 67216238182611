import { Button, Card, message, Modal, Steps } from 'antd'
import React, { useRef, useState } from 'react'
import { injectIntl } from 'react-intl'
import LoadingBar from 'react-top-loading-bar'
import BeneficiaryCreateService from '../../../services/BeneficiaryCreate'
import Bannercrumb from '../../../util/banner/Bannercrumb'
import IntlMessages from '../../../util/IntlMessages'
import AddressInfo from '../../MultistepFormComponents/AddressInfo'
import EducationInfo from '../../MultistepFormComponents/EducationInfo'
import FamilyInfo from '../../MultistepFormComponents/FamilyInfo'
import OccupationInfo from '../../MultistepFormComponents/OccupationInfo'
import PersonalInfo from '../../MultistepFormComponents/PersonalInfo'
import Purpose from '../../MultistepFormComponents/Purpose'
import Reference from '../../MultistepFormComponents/Reference'
import Training from '../../MultistepFormComponents/Training'
import WorkExperience from '../../MultistepFormComponents/WorkExperience'
import './AddBeneficiaryForm.css'

var zillaUpazilla = [
	{
		zilla: 'BARGUNA',
		thana: [
			'BARGUNA-SADAR',
			'BAMNA',
			'PATHORGHATA',
			'TALTOLI',
			'BETAGI',
			'AMTALI'
		]
	},
	{
		zilla: 'BARISAL',
		thana: [
			'BARISAL-SADAR',
			{
				name: 'BARISAL-CITY-CORPORATION',
				area: [
					'KOTWALI',
					'AIRPORT',
					'KAWNIA',
					'RUPATOLY',
					'BARISAL-UNIVERSITY',
					'CHORMONAI',
					'KASHIPUR'
				]
			},
			'BAKERGANJ',
			'BABUGANJ',
			'WAZIRPUR',
			'BANARIPARA',
			'GOURNADI',
			'AGAILJHARA',
			'MEHENDIGONJ',
			'MULADI',
			'HIZLA'
		]
	},
	{
		zilla: 'BHOLA',
		thana: [
			'BHOLA-SADAR',
			'CHARFESSON',
			'DOULATKHAN',
			'MONPURA',
			'TAZUMUDDIN',
			'LALMOHAN',
			'BORHANUDDIN'
		]
	},
	{
		zilla: 'JHALOKATI',
		thana: ['JHALAKATHI-SADAR', 'KATHALIA', 'NALCHITY', 'RAJAPUR']
	},
	{
		zilla: 'PATUAKHALI',
		thana: [
			'PATUAKHALI-SADAR',
			'BAUPHAL',
			'DUMKI',
			'DASHMINA',
			'KALAPARA',
			'MIRZAGANJ',
			'GALACHIPA',
			'RANGABALI'
		]
	},
	{
		zilla: 'PIROJPUR',
		thana: [
			'PIROJPUR-SADAR',
			'NAZIRPUR',
			'KAWKHALI',
			'BHANDARIA',
			'MATHBARIA',
			'INDURKANI',
			'NESARABAD'
		]
	},
	{
		zilla: 'BANDARBAN',
		thana: [
			'BANDARBAN-SADAR',
			'ALIKADAM',
			'NAIKHONGCHHARI',
			'ROWANGCHHARI',
			'LAMA',
			'RUMA',
			'THANCHI'
		]
	},
	{
		zilla: 'BRAHMANBARIA',
		thana: [
			'BRAHMANBARIA-SADAR',
			'KASBA',
			'NASIRNAGAR',
			'SARAIL',
			'ASHUGANJ',
			'AKHAURA',
			'NABINAGAR',
			'BANCHARAMPUR',
			'BIJOYNAGAR'
		]
	},
	{
		zilla: 'CHANDPUR',
		thana: [
			'CHANDPUR-SADAR',
			'HAIMCHAR',
			'KACHUA',
			'SHAHRASTI',
			'MATLAB',
			'HAJIGANJ',
			'FARIDGONJ'
		]
	},
	{
		zilla: 'CHITTAGONG',
		thana: [
			'RANGUNIA',
			'SITAKUNDA',
			{
				name: 'CHITTAGONG-CITY-CORPORATION',
				area: [
					'CHANDGAON',
					'BONDOR',
					'DOUBLE-MOORING',
					'KOTWALI',
					'PAHARTALI',
					'PANCHLAISH',
					'BAYEJID-BOSTAMI',
					'PATENGA',
					'HALISHAHAR',
					'KHULSHI',
					'BAKALIA',
					'KARNAPHULI',
					'CHAWK-BAZAR',
					'AKBAR-SHAH',
					'SADARGHAT',
					'EPZ'
				]
			},
			'MIRSHARAI',
			'PATIYA',
			'SANDWIP',
			'BANSHKHALI',
			'BOALKHALI',
			'ANWARA',
			'CHANDANAISH',
			'SATKANIA',
			'LOHAGARA',
			'HATHAZARI',
			'FATIKCHHARI',
			'RAOZAN',
			'KARNAFULI'
		]
	},
	{
		zilla: 'COMILLA',
		thana: [
			'COMILLA-SADAR',
			'COMILLA-CITY-CORPORATION',
			'DEBIDWAR',
			'BARURA',
			'BRAHMANPARA',
			'CHANDINA',
			'CHAUDDAGRAM',
			'DAUDKANDI',
			'HOMNA',
			'LAKSAM',
			'MURADNAGAR',
			'NANGALKOT',
			'MEGHNA',
			'MONOHARGONJ',
			'SADAR-SOUTH',
			'TITAS',
			'BURICHANG',
			'LALMAI'
		]
	},
	{
		zilla: 'COXS-BAZAR',
		thana: [
			'COXSBAZAR-SADAR',
			'CHAKARIA',
			'KUTUBDIA',
			'UKHIYA',
			'MOHESHKHALI',
			'PEKUA',
			'RAMU',
			'TEKNAF'
		]
	},
	{
		zilla: 'FENI',
		thana: [
			'FENI-SADAR',
			'CHHAGALNAIYA',
			'SONAGAZI',
			'FULGAZI',
			'PARSHURAM',
			'DAGANBHUIYAN'
		]
	},
	{
		zilla: 'KHAGRACHHARI',
		thana: [
			'KHAGRACHHARI-SADAR',
			'DIGHINALA',
			'PANCHARI',
			'LAXMICHHARI',
			'MOHALCHARI',
			'MANIKCHARI',
			'RAMGARH',
			'MATIRANGA',
			'GUIMARA'
		]
	},
	{
		zilla: 'LAKSHMIPUR',
		thana: ['LAKSHMIPUR-SADAR', 'KAMALNAGAR', 'RAIPUR', 'RAMGATI', 'RAMGANJ']
	},
	{
		zilla: 'NOAKHALI',
		thana: [
			'NOAKHALI-SADAR',
			'COMPANIGANJ',
			'BEGUMGANJ',
			'HATIA',
			'SUBARNACHAR',
			'KABIRHAT',
			'SENBUG',
			'CHATKHIL',
			'SONAIMURI'
		]
	},
	{
		zilla: 'RANGAMATI',
		thana: [
			'RANGAMATI-SADAR',
			'KAPTAI',
			'KAUKHALI',
			'BAGHAICHARI',
			'BARKAL',
			'LANGADU',
			'RAJASTHALI',
			'BELAICHARI',
			'JURAICHARI',
			'NANIARCHAR'
		]
	},
	{
		zilla: 'DHAKA',
		thana: [
			'SAVAR',
			{
				name: 'DHAKA-CITY',
				area: [
					'ADABOR',
					'BADDA',
					'BANANI',
					'BANGSHAL',
					'BIMANBANDAR',
					'BSAHANTEK',
					'CANTONMENT',
					'CHALKBAZAR',
					'DAKKHINKHAN',
					'DARUS-SALAM',
					'DEMRA',
					'DHANMONDI',
					'GANDARIA',
					'GULSHAN',
					'HAJARIBAG',
					'JATRABARI',
					'KAFRUL',
					'KALABAGAN',
					'KAMRANGIRCHAR',
					'KHILGAON',
					'KHILKHET',
					'KADAMTALI',
					'KOTWALI',
					'LALBAGH',
					'MIRPUR',
					'MOHAMMADPUR',
					'MOTIJHEEL',
					'MUGDA',
					'NEW-MARKET',
					'PALLABI',
					'PALTAN',
					'RAMNA',
					'RAMPURA',
					'RUPNAGAR',
					'SABUJBAG',
					'SHAH-ALI',
					'SHAHBAG',
					'SHAHJAHANPUR',
					'SHER-E-BANGLA-NAGAR',
					'SHYAMPUR',
					'SUTRAPUR',
					'TEJGAON',
					'TURAG',
					'UTTAR-KHAN',
					'UTTARA',
					'VATARA',
					'WARI'
				]
			},
			'DHAMRAI',
			'KERANIGANJ',
			'NAWABGANJ',
			'DOHAR'
		]
	},
	{
		zilla: 'FARIDPUR',
		thana: [
			'FARIDPUR-SADAR',
			'ALFADANGA',
			'BOALMARI',
			'SADARPUR',
			'NAGARKANDA',
			'BHANGA',
			'CHARBHADRASAN',
			'MADHUKHALI',
			'SALTHA'
		]
	},
	{
		zilla: 'GAZIPUR',
		thana: [
			'GAZIPUR-SADAR',
			{
				name: 'GAZIPUR-CITY-CORPORATION',
				area: [
					'BASON',
					'KONABARI',
					'KASIMPUR',
					'GASA',
					'PUBAIL',
					'TONGI-EAST',
					'TONGI-WEST'
				]
			},
			'KALIGANJ',
			'KALIAKAIR',
			'KAPASIA',
			'SREEPUR'
		]
	},
	{
		zilla: 'GOPALGANJ',
		thana: [
			'GOPALGANJ-SADAR',
			'KASHIANI',
			'TUNGIPARA',
			'KOTALIPARA',
			'MUKSUDPUR'
		]
	},
	{
		zilla: 'JAMALPUR',
		thana: [
			'JAMALPUR-SADAR',
			'MELANDAH',
			'ISLAMPUR',
			'DEWANGONJ',
			'SARISHABARI',
			'MADARGANJ',
			'BOKSHIGANJ'
		]
	},
	{
		zilla: 'KISHOREGONJ',
		thana: [
			'KISHOREGANJ-SADAR',
			'ITNA',
			'KATIADI',
			'BHAIRAB',
			'TARAIL',
			'HOSSAINPUR',
			'PAKUNDIA',
			'KULIARCHAR',
			'KARIMGONJ',
			'BAJITPUR',
			'AUSTAGRAM',
			'MITHAMOIN',
			'NIKLI'
		]
	},
	{
		zilla: 'MADARIPUR',
		thana: ['MADARIPUR-SADAR', 'SHIBCHAR', 'KALKINI', 'RAJOIR']
	},
	{
		zilla: 'MANIKGANJ',
		thana: [
			'MANIKGANJ-SADAR',
			'DAULATPUR',
			'SINGIAR',
			'SHIBALOY',
			'GIOR',
			'SATURIA',
			'HARIRAMPUR'
		]
	},
	{
		zilla: 'MUNSHIGANJ',
		thana: [
			'MUNSHIGANJ-SADAR',
			'SREENAGAR',
			'SIRAJDIKHAN',
			'LOUHAJANJ',
			'GAJARIA',
			'TONGIBARI'
		]
	},
	{
		zilla: 'MYMENSINGH',
		thana: [
			'MYMENSINGH-SADAR',
			'MYMENSINGH-CITY-CORPORATION',
			'FULBARIA',
			'TRISHAL',
			'BHALUKA',
			'MUKTAGACHA',
			'DHOBAURA',
			'PHULPUR',
			'HALUAGHAT',
			'GOURIPUR',
			'ISWARGANJ',
			'TARAKANDA',
			'GAFARGAON',
			'NANDAIL'
		]
	},
	{
		zilla: 'NARAYANGANJ',
		thana: [
			'NARAYANGANJ-SADAR',
			{
				name: 'NARAYANGANJ-CITY-CORPORATION',
				area: ['FATULLAH', 'SIDDHIRGANJ']
			},
			'ARAIHAZAR',
			'BANDAR',
			'RUPGANJ',
			'SONARGAON'
		]
	},
	{
		zilla: 'NARSINGDI',
		thana: [
			'NARSINGDI-SADAR',
			'MONOHARDI',
			'BELABO',
			'PALASH',
			'RAIPURA',
			'SHIBPUR'
		]
	},
	{
		zilla: 'NETRAKONA',
		thana: [
			'NETROKONA-SADAR',
			'MADAN',
			'DURGAPUR',
			'ATPARA',
			'KENDUA',
			'KHALIAJURI',
			'KALMAKANDA',
			'PURBADHALA',
			'MOHONGONJ',
			'BARHATTA'
		]
	},
	{
		zilla: 'RAJBARI',
		thana: ['RAJBARI-SADAR', 'GOALANDA', 'PANGSA', 'BALIAKANDI', 'KALUKHALI']
	},
	{
		zilla: 'SHARIATPUR',
		thana: [
			'SHARIATPUR-SADAR',
			'NARIA',
			'ZAJIRA',
			'GOSAIRHAT',
			'BHEDARGANJ',
			'DAMUDYA'
		]
	},
	{
		zilla: 'TANGAIL',
		thana: [
			'TANGAIL-SADAR',
			'BASAIL',
			'BHUAPUR',
			'DELDUAR',
			'GHATAIL',
			'GOPALPUR',
			'MIRZAPUR',
			'NAGARPUR',
			'SAKHIPUR',
			'KALIHATI',
			'DHANBARI',
			'MADHUPUR'
		]
	},
	{
		zilla: 'SHERPUR',
		thana: ['SHERPUR-SADAR', 'NOKLA', 'JHENAIGATI', 'SREEBORDI', 'NALITABARI']
	},
	{
		zilla: 'BAGERHAT',
		thana: [
			'BAGERHAT-SADAR',
			'FAKIRHAT',
			'MOLLAHAT',
			'SARANKHOLA',
			'RAMPAL',
			'MORRELGANJ',
			'KOCHUA',
			'MONGLA',
			'CHITALMARI'
		]
	},
	{
		zilla: 'CHUADANGA',
		thana: ['CHUADANGA-SADAR', 'ALAMDANGA', 'DAMURHUDA', 'JIBANNAGAR']
	},
	{
		zilla: 'JESSORE',
		thana: [
			'JESSORE-SADAR',
			'MANIRAMPUR',
			'ABHAYNAGAR',
			'BAGHERPARA',
			'CHOUGACHHA',
			'JHIKARGACHA',
			'KESHABPUR',
			'SHARSHA'
		]
	},
	{
		zilla: 'JHENAIDAH',
		thana: [
			'JHENAIDAH-SADAR',
			'SHAILKUPA',
			'HARINAKUNDU',
			'KALIGONJ',
			'KOTCHANDPUR',
			'MOHESHPUR'
		]
	},
	{
		zilla: 'KHULNA',
		thana: [
			'PAIKGASA',
			{
				name: 'KHULNA-CITY-CORPORATION',
				area: [
					'KOTWALI',
					'SONADANGA',
					'DAULATPUR',
					'KHALISHPUR',
					'KHAN-JAHAN-ALI',
					'LABONCHORA',
					'HORINTANA',
					'ARONGGHATA'
				]
			},
			'FULTOLA',
			'DIGHOLIA',
			'RUPSHA',
			'TEROKHADA',
			'DUMURIA',
			'BOTIAGHATA',
			'DAKOP',
			'KOYRA'
		]
	},
	{
		zilla: 'KUSHTIA',
		thana: [
			'KUSHTIA-SADAR',
			'KUMARKHALI',
			'KHOKSA',
			'MIRPUR',
			'DOULATPUR',
			'BHERAMARA'
		]
	},
	{
		zilla: 'MAGURA',
		thana: ['MAGURA-SADAR', 'SHALIKHA', 'SREEPOOR', 'MOHAMMADPUR']
	},
	{
		zilla: 'MEHERPUR',
		thana: ['MEHERPUR-SADAR', 'MUJIBNAGAR', 'GANGNI']
	},
	{
		zilla: 'NARAIL',
		thana: ['NARIAL-SADAR', 'LOHAGORA', 'KALIA']
	},
	{
		zilla: 'SATKHIRA',
		thana: [
			'SATKHIRA-SADAR',
			'SHYAMNAGAR',
			'ASSASUNI',
			'DEBHATA',
			'KALAROA',
			'TALA',
			'KAALIGANJ'
		]
	},
	{
		zilla: 'BOGRA',
		thana: [
			'BOGRA-SADAR',
			'KAHALOO',
			'SHARIAKANDI',
			'SHAJAHANPUR',
			'DUPCHANCHIA',
			'ADAMDIGHI',
			'NONDIGRAM',
			'SONATALA',
			'DHUNOT',
			'GABTALI',
			'SHERPUR',
			'SHIBGANJ'
		]
	},
	{
		zilla: 'JOYPURHAT',
		thana: ['JOYPURHAT-SADAR', 'AKKELPUR', 'KHETLAL', 'PANCHBIBI', 'KALAI']
	},
	{
		zilla: 'NAOGAON',
		thana: [
			'NAOGAON-SADAR',
			'RANINAGAR',
			'ATRAI',
			'MANDA',
			'NIAMATPUR',
			'DHAMOIRHAT',
			'PATNITALA',
			'BADALGACHI',
			'MOHADEVPUR',
			'SAPAHAR',
			'PORSHA'
		]
	},
	{
		zilla: 'NATORE',
		thana: [
			'NATORE-SADAR',
			'SINGRA',
			'BARAIGRAM',
			'BAGATIPARA',
			'LALPUR',
			'GURUDASPUR',
			'NALDANGA'
		]
	},
	{
		zilla: 'CHAPAI-NABABGANJ',
		thana: [
			'CHAPAINAWABGANJ-SADAR',
			'SHIBGONJ',
			'GOMOSTAPUR',
			'NACHOL',
			'BHOLAHAT'
		]
	},
	{
		zilla: 'PABNA',
		thana: [
			'PABNA-SADAR',
			'SUJANAGAR',
			'ISHURDI',
			'BHANGURA',
			'BERA',
			'ATGHORIA',
			'CHATMOHAR',
			'SANTHIA',
			'FARIDPUR'
		]
	},
	{
		zilla: 'RAJSHAHI',
		thana: [
			'PABA',
			{
				name: 'RAJSHAHI-CITY-CORPORATION',
				area: [
					'BOALIA',
					'RAJPARA',
					'MATIHAR',
					'SHAH-MAKHDUM',
					'CHANDRIMA',
					'KASHIA-DANGA',
					'KATAKHALI',
					'BELPUKUR',
					'AIRPORT',
					'KARMOHAR',
					'DAMKURA'
				]
			},
			'DURGAPOOR',
			'MOHONPUR',
			'CHARGHAT',
			'PUTHIA',
			'BAGHA',
			'TANORE',
			'BAGMARA',
			'GODAGARI'
		]
	},
	{
		zilla: 'SIRAJGANJ',
		thana: [
			'SIRAJGANJ-SADAR',
			'BELKUCHI',
			'CHAUHALI',
			'KAMARKHAND',
			'KAZIPUR',
			'RAIGONJ',
			'SHAHJADPUR',
			'TARASH',
			'ULLAPARA'
		]
	},
	{
		zilla: 'DINAJPUR',
		thana: [
			'DINAJPUR-SADAR',
			'NAWABGONJ',
			'BIRGANJ',
			'GHORAGHAT',
			'BIRAMPUR',
			'PARBATIPUR',
			'BOCHAGANJ',
			'KAHAROL',
			'FULBARI',
			'HAKIMPUR',
			'KHANSAMA',
			'BIROL',
			'CHIRIRBANDAR'
		]
	},
	{
		zilla: 'GAIBANDHA',
		thana: [
			'GAIBANDHA-SADAR',
			'SADULLAPUR',
			'PALASHBARI',
			'SAGHATA',
			'GOBINDAGANJ',
			'SUNDARGANJ',
			'PHULCHARI'
		]
	},
	{
		zilla: 'KURIGRAM',
		thana: [
			'KURIGRAM-SADAR',
			'NAGESHWARI',
			'BHURUNGAMARI',
			'PHULBARI',
			'RAJARHAT',
			'ULIPUR',
			'CHILMARI',
			'ROWMARI',
			'CHAR-RAJIBPUR'
		]
	},
	{
		zilla: 'LALMONIRHAT',
		thana: [
			'LALMONIRHAT-SADAR',
			'KAALIGONJ',
			'HATIBANDHA',
			'PATGRAM',
			'AADITMARI'
		]
	},
	{
		zilla: 'NILPHAMARI',
		thana: [
			'NILPHAMARI-SADAR',
			'JALDHAKA',
			'KISHORGANJ',
			'DOMAR',
			'DIMLA',
			'SYEDPUR'
		]
	},
	{
		zilla: 'PANCHAGARH',
		thana: ['PANCHAGARH-SADAR', 'BODA', 'DEBIGANJ', 'ATWARI', 'TETULIA']
	},
	{
		zilla: 'RANGPUR',
		thana: [
			'RANGPUR-SADAR',
			'PIRGACHA',
			{
				name: 'RANGPUR-CITY-CORPORATION',
				area: [
					'KOTWALI',
					'PARSHURAM',
					'TAJHAT',
					'MAHIGONJ',
					'HARAGACH',
					'HAZIRHAT'
				]
			},
			'KAUNIA',
			'PIRGONJ',
			'MITHAPUKUR',
			'BADARGONJ',
			'TARAGONJ',
			'GANGACHARA'
		]
	},
	{
		zilla: 'THAKURGAON',
		thana: [
			'THAKURGAON-SADAR',
			'PIRGANJ',
			'RANISANKAIL',
			'HARIPUR',
			'BALIADANGI'
		]
	},
	{
		zilla: 'HABIGANJ',
		thana: [
			'HABIGANJ-SADAR',
			'NABIGANJ',
			'BAHUBAL',
			'AJMIRIGANJ',
			'BANIACHANG',
			'LAKHAI',
			'CHUNARUGHAT',
			'MADHABPUR',
			'SHAYESTAGANJ'
		]
	},
	{
		zilla: 'MAULVIBAZAR',
		thana: [
			'MOULVIBAZAR-SADAR',
			'BARALEKHA',
			'KAMALGANJ',
			'KULAURA',
			'RAJNAGAR',
			'SRIMANGAL',
			'JURI'
		]
	},
	{
		zilla: 'SUNAMGANJ',
		thana: [
			'SUNAMGANJ-SADAR',
			'BISHWAMBHARPUR',
			'CHHATAK',
			'JAGANNATHPUR',
			'DOARABAZAR',
			'TAHIRPUR',
			'DHARMAPASHA',
			'JAMALGANJ',
			'SHALLA',
			'DIRAI',
			'SOUTH-SUNAMGANJ'
		]
	},
	{
		zilla: 'SYLHET',
		thana: [
			'SYLHET-SADAR',
			'JAINTAPUR',
			'ZAKIGANJ',
			{
				name: 'SYLHET-CITY-CORPORATION',
				area: [
					'KOTWALI',
					'JALALABAD',
					'AIRPORT',
					'MOGLABAZAR',
					'SHAH-PARAN',
					'DAKSHIN-SURMA'
				]
			},
			'GOWAINGHAT',
			'GOLAPGANJ',
			'FENCHUGANJ',
			'KANAIGHAT',
			'SOUTH-SURMA',
			'OSMANINAGAR',
			'BISHWANATH',
			'COMPANYGANJ',
			'BEANIBAZAR',
			'BALAGANJ'
		]
	}
]
zillaUpazilla.sort((a, b) => {
	if (a.zilla < b.zilla) return -1
	if (a.zilla > b.zilla) return 1
	return 0
})
var zillaList = []
zillaUpazilla.forEach(zilla => {
	zilla.thana.sort()
	zillaList.push(zilla)
})

const Registration = ({ onSuccess, intl }) => {
	const [step, setStep] = React.useState(0)
	const [values, setValues] = React.useState([
		{},
		[],
		[],
		[],
		{},
		[],
		[],
		[],
		{}
	])
	const [visible, setVisible] = useState(false)
	const [isFormSubmitted, setIsFormSubmitted] = useState(false)
	const loadingRef = useRef(null)
	const stepsRef = useRef(null)
	const [loading, setLoading] = useState(false)

	const showModal = () => {
		setVisible(true)
	}
	const handleOk = () => {
		setVisible(false)
		if (!loading) {
			setLoading(true)
			loadingRef.current.continuousStart()
			console.log(values)

			if (values[0]) {
				const nameL = {
					text: values[0].name,
					languageCode: 'en'
				}
				var personalInfo = {
					nameL: nameL,
					dob: new Date(values[0].dob).getTime(),
					mobileNo:
						values[0].phone.length === 14
							? values[0].phone
							: '+88' + values[0].phone,
					secondaryMobileNo:
						values[0].sosPhone.length === 14
							? values[0].sosPhone
							: '+88' + values[0].sosPhone,
					email: values[0].email,
					nid: values[0].NID
				}
				var photo = values.photo
				//values[0].image?.file?.response?.photo
			}

			if (values[1]) {
				var edu = []
				values[1].forEach(field => {
					var eduExperienceTemps = {
						examinationName: field.examName,
						subject: field.subjectOrGroup,
						passingYear: field.passingYear,
						instituteName: field.institute
					}
					edu.push(eduExperienceTemps)
				})
			}

			if (values[2]) {
				var experiencesTemp = []
				values[2].forEach(field => {
					var experienceTemps = {
						organisationName: field.organisationName_,
						designation: field.designation_,
						from: new Date(field.from_).getTime(),
						to: new Date(field.to_).getTime(),
						responsibilities: field.responsibilities_
					}
					experiencesTemp.push(experienceTemps)
				})
			}

			if (values[3]) {
				const titleL = {
					text: values[3]?.occupation[0],
					languageCode: 'en'
				}
				const organizationNameL = {
					text: values[3]?.institute,
					languageCode: 'en'
				}
				const summaryL = {
					text: values[3]?.jobDescription,
					languageCode: 'en'
				}
				var occupationInfo = {
					titleL: titleL,
					organizationNameL: organizationNameL,
					summaryL: summaryL,
					monthlyIncome: values[3]?.monthlyIncome,
					netLandProperty: values[3]?.netLand
				}
			}

			if (values[4]) {
				const permanentLocationL = {
					text: values[4].permanentAddress,
					languageCode: 'en'
				}
				var tempAddress = {
					permanent: {
						district: values[4]?.permanentDistrict[0],
						upazilla: values[4]?.permanentDistrict[1],
						area: values[4]?.permanentDistrict[2],
						locationL: permanentLocationL
					}
				}
				if (values[4].presentAddress && values[4].presentDistrict) {
					const presentLocationL = {
						text: values[4].presentAddress,
						languageCode: 'en'
					}
					tempAddress['present'] = {
						district: values[4]?.presentDistrict[0],
						upazilla: values[4]?.presentDistrict[1],
						area: values[4]?.presentDistrict[2],
						locationL: presentLocationL
					}
				}
			}

			if (values[5]) {
				var familyInfo = []
				var familyTypeTemp
				values[5].forEach(field => {
					if (field.type) {
						familyTypeTemp = field.type
					}
					var familyType = field.type ? field.type : familyTypeTemp
					var familyInfoTemps = {
						familyType: familyType[0],
						relationship: field.relationship[0],
						name: field.name,
						presence: field.deadOrAlive[0],
						dob: new Date(field.dateOfBirth).getTime(),
						occupation: field.occupation[0],
						monthlyIncome: field.monthlyIncome
					}
					familyInfo.push(familyInfoTemps)
				})
			}

			if (values[6]) {
				var traningInfo = []
				values[6].forEach(field => {
					var trainingTemps = {
						organisationName: field.organisationName_,
						topic: field.topic_,
						startDate: new Date(field.from_).getTime(),
						endDate: new Date(field.to_).getTime(),
						description: field.discription_
					}
					traningInfo.push(trainingTemps)
				})
			}

			if (values[7]) {
				var referencesInfo = []
				values[7].forEach(field => {
					const nameL = {
						text: field.name,
						languageCode: 'en'
					}
					const occupationL = {
						text: field.occupation[0],
						languageCode: 'en'
					}
					const addressL = {
						text: field.address,
						languageCode: 'en'
					}

					var referenceTemps = {
						name: field.name,
						nameL: nameL,
						occupation: field.occupation[0],
						occupationL: occupationL,
						phoneNo:
							field.phone.length === 14 ? field.phone : '+88' + field.phone,
						address: field.address,
						addressL: addressL
					}
					referencesInfo.push(referenceTemps)
				})
			}

			if (values[8]) {
				var purposeLTemp = {
					text: values[8].purpose,
					languageCode: 'en'
				}
				//var purposeTemp = values[8].purpose
			}

			BeneficiaryCreateService.create(
				personalInfo || {},
				edu || [],
				experiencesTemp || [],
				occupationInfo || {},
				tempAddress || {},
				familyInfo || [],
				traningInfo || [],
				referencesInfo || [],
				photo || {},
				purposeLTemp || []
			).then(
				res => {
					if (res.state === 'success') {
						loadingRef.current.complete()
						setLoading(false)
						setIsFormSubmitted(true)
						message.success(intl.formatMessage({ id: res.message }))
					} else {
						loadingRef.current.complete()
						setLoading(false)
						message.warning(intl.formatMessage({ id: res.message }))
					}
				},
				err => {
					console.error(err)
					message.error(intl.formatMessage({ id: 'backend.network.error' }))
					loadingRef.current.complete()
					setLoading(false)
				}
			)
		}
	}
	const handleCancel = () => {
		setVisible(false)
	}

	const { Step } = Steps

	const onChange = (index, value = []) => {
		//console.log(value, index)
		stepsRef.current.scrollIntoView()
		if (index === 9) {
			if (value) {
				var tempValues = values
				if (tempValues.length >= index) {
					tempValues[index - 1] = value
				} else {
					tempValues.push(value)
				}
				setValues(tempValues)
			}
			showModal()
		}
		console.log(values)

		if (index < 9) {
			setStep(index)

			var tempValues2 = []
			if (value) {
				tempValues2 = values
				if (tempValues2.length >= index) {
					tempValues2[index - 1] = value
				} else {
					tempValues2.push(value)
				}
				setValues(tempValues2)
			}
		} else if (value) {
			// var tempValues2 = values
			if (tempValues2.length >= index) {
				tempValues2[index - 1] = value
			} else {
				tempValues2.push(value)
			}
			setValues(tempValues2)
		}
	}

	const steps = [
		{
			title: <IntlMessages id='nav.volunteer.formTitle' />,
			content: <PersonalInfo onChange={onChange} previousValue={values[0]} />
		},
		{
			title: <IntlMessages id='nav.volunteer.education.formTitle' />,
			content: <EducationInfo onChange={onChange} previousValue={values[1]} />
		},
		{
			title: <IntlMessages id='nav.work.experience.formTitle' />,
			content: <WorkExperience onChange={onChange} previousValue={values[2]} />
		},
		{
			title: <IntlMessages id='nav.volunteer.form.occupation' />,
			content: <OccupationInfo onChange={onChange} previousValue={values[3]} />
		},
		{
			title: <IntlMessages id='nav.volunteer.formAddress' />,
			content: <AddressInfo onChange={onChange} previousValue={values[4]} />
		},
		{
			title: <IntlMessages id='nav.family.info.title' />,
			content: <FamilyInfo onChange={onChange} previousValue={values[5]} />
		},
		{
			title: <IntlMessages id='nav.volunteer.experience.formTitle' />,
			content: <Training onChange={onChange} previousValue={values[6]} />
		},
		{
			title: <IntlMessages id='nav.volunteer.reference.formTitle' />,
			content: <Reference onChange={onChange} previousValue={values[7]} />
		},
		{
			title: <IntlMessages id='nav.volunteer.purpose.formTitle' />,
			content: <Purpose onChange={onChange} previousValue={values[8]} />
		}
	]

	return (
		<React.Fragment>
			<Bannercrumb bannerId='register-banner' />
			<div className='container-fluid mt-5'>
				<div className='row'>
					<div className='col-md-10 offset-md-1 py-1'>
						<Modal
							title='Add a new Beneficiary'
							visible={visible}
							onOk={handleOk}
							onCancel={handleCancel}
							width='50vw'
							// centered={true}
						>
							If you are sure about all the informations you can submit the
							application. You can edit all the informations going back.
						</Modal>
						<LoadingBar height={3} color='#7dd078' ref={loadingRef} />
						<div>
							{/* <div className='gx-app-admin-form-main-content'> */}
							{/* <div
						className='gx-app-admin-form-content'
						style={{ textAlign: 'center', width: '100%' }}
					> */}
							{isFormSubmitted ? (
								<Card bordered={false}>
									<h2>Your Beneficiary application has been received</h2>
									<p>
										Thanks for applying in NO MORE POVERTY. Your application
										will be scrutinized to verify your eligibility of getting
										fund. You will be notified shortly.
									</p>
									<div style={{ paddingBottom: 10 }}></div>
									<Button
										type='primary'
										onClick={() =>
											(window.location.href =
												'https://nmp-web-fe.dev.quanticdynamics.cloud/')
										}
										style={{ float: 'middle' }}
									>
										Home
									</Button>
								</Card>
							) : (
								<div className='pt-1' ref={stepsRef}>
									{/* <div className='d-none d-lg-block py-3' style={{ backfaceVisibility: 'visible' }}> */}
									<Steps
										responsive
										current={step}
										size='small'
										style={{ paddingBottom: '0.6rem' }}
									>
										{/* progressDot */}
										{steps.map(item => (
											<Step key={item.title} title={item.title} />
										))}
									</Steps>
									{/* </div> */}

									{/* <div className='d-block d-lg-none'>
										<Steps progressDot current={step} size='small'>
											{steps.map(item => (
												<Step key={item.title} title={item.title} />
											))}
										</Steps>
									</div> */}
									<div>{steps[step].content}</div>
									{/* <div className='steps-action'></div> */}
								</div>
							)}
							{/* </div> */}
							{/* </div> */}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default injectIntl(Registration)
