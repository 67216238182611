import Buttons from '../button/Buttons'
import Counter from '../counter/Counter'
import '../qarz/Qarz.css'
import RoundBtn from '../roundBtn/RoundBtn'
// import { FaCheck } from 'react-icons/fa';
import { injectIntl } from 'react-intl'
import { QarzData } from '../../../data/QarzData'
import Imgprofile from '../imgprofile/Imgprofile'

const Qarz=({ intl })=> {

	const qarzImg = {
		backgroundImage: 'url(images/left-circle.svg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right top -200px',
		backgroundSize: 'auto 800px',
	}

	return (
		<div className='qarz' style={qarzImg}>
			<div className='container'>
				<div className='row gy-lg-0 gy-5'>

					<div className='col-lg-7 col-sm-12'>
						<div className='img-wrap'>
							<div className='big-img-wrap'>
								<div className='row'>
									<div className='col-4'>
										<div className='img-box'>
											<img src='images/poultry.jpg'></img>
										</div>
									</div>
								</div>

								<div className='row'>
									<div className='col-10'>
										<div className='big-img'>
											<img src='images/paddy.jpg'></img>
										</div>
									</div>
								</div>

								<div className='row'>
									<div className='col-4'>
										<div className='img-box-bottom'>
											<img src='images/tailor.jpg'></img>
										</div>
									</div>
								</div>
							</div>

							<Imgprofile margin={{ borderRadius: '15px 15px 15px 60px', marginLeft: 'auto', marginTop: '-200px' }}></Imgprofile>

						</div>
					</div>

					<div className='col-lg-5 col-sm-12'>
						<div className='info-wrap'>
							{/* <h1 dangerouslySetInnerHTML={{ __html: QarzData[0].title }}></h1> */}

							<h1>{intl.formatMessage({id:'qarz.heading'})}</h1>

							<Counter district={QarzData[0].district} thana={QarzData[0].thana} child={QarzData[0].children}></Counter>

							{/* <p className="short-desc" dangerouslySetInnerHTML={{ __html: QarzData[0].shortDesc }}></p> */}
							<p className='short-desc'>{intl.formatMessage({ id: 'qarz.description' })}</p>
							<div className='donantion-btn-group'>
								<Buttons></Buttons>
								<RoundBtn></RoundBtn>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	)
}

export default injectIntl(Qarz)

