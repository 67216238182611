import {
	Button, Cascader, Col,
	DatePicker, Form,
	Input, message,
	Modal,
	Row, Table
} from 'antd'
import { useState } from 'react'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

import { AiOutlineUser } from 'react-icons/ai'
import { RiMoneyDollarBoxLine } from 'react-icons/ri'

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }

const FamilyInfo = ({ onChange, previousValue, intl }) => {
	const [familyInformation] = Form.useForm()
	const [familyFields, setFamilyFields] = useState(
		previousValue ? previousValue : []
	)
	const [isUpdateFamilyFields, setIsUpdateFamilyFields] = useState(undefined)
	//const [isFamilyFieldsOpen, setIsFamilyFieldsOpen] = useState(false)
	const [index, setIndex] = useState(undefined)
	const [visible, setVisible] = useState(false)
	const monthlyIncomeRegex = /^([0-9]{1,5}|100000)$/

	const today = new Date()
	var day = today.getDate()
	day = (day < 10 ? '0' : '') + day
	var month = today.getMonth() + 1
	month = (month < 10 ? '0' : '') + month
	var year = today.getFullYear() + ''
	const todayString = `${year}-${month}-${day}`

	if (previousValue) {
		//familyInformation.setFieldsValue(previousValue)
		console.log(previousValue)
	}

	const familyTypeList = ['Nuclear', 'Joint']
	const relationshipList = [
		'Father',
		'Mother',
		'Brother',
		'Sister',
		'Uncle',
		'Aunt',
		'Grandfather',
		'Grandmother',
		'Brother-in-law',
		'Sister-in-law',
		'Mother-in-law',
		'Father-in-law',
		'Cousin'
	]
	const deadOrAliveList = ['Late', 'Alive']

	const onSubmitFamilyInfo = values => {
		if (!isUpdateFamilyFields) {
			var temp2 = familyFields
			temp2.push(values)
			setFamilyFields(temp2)
		} else if (isUpdateFamilyFields) {
			familyFields.splice(index, 1, values)
			setFamilyFields(familyFields)
			setIsUpdateFamilyFields(false)
		}
		setVisible(false)
	}

	const handleCancel = () => {
		setVisible(false)
	}

	const onSubmit = () => {
		if (
			!familyFields.find(item => item.relationship[0] === 'Father') ||
			!familyFields.find(item => item.relationship[0] === 'Mother')
		) {
			console.log(familyFields.find(item => item.relationship[0] === 'Father'))
			message.error('Father\'s and Mother\'s information is required')
		} else {
			onChange(6, familyFields)
		}
	}

	const onPrevious = () => {
		onChange(4)
	}

	const occupationList = [
		'IMAAM-KHATIB',
		'MUAZZIN',
		'DOCTOR',
		'TEACHER',
		'WRITER',
		'FARMER',
		'ENGINEER',
		'LAWYER',
		'BUSINESS-PERSON',
		'COMPUTER-PROGRAMMER',
		'CRAFTER-HANDICRAFTER',
		'JOURNALIST',
		'SOCIAL-WORKER',
		'GRAPHICS-DESIGNER',
		'BANKER',
		'STUDENT',
		'HOUSE-WIFE',
		'GOVT-EMPLOYEE',
		'NON-GOVT-EMPLOYEE',
		'JOB-LESS',
		'OTHERS'
	]

	var columns = [
		// {
		// 	title: <IntlMessages id='nav.family.info.member.type' />,
		// 	// width: 150,
		// 	dataIndex: 'type',
		// 	key: 'names'
		// },
		{
			title: <IntlMessages id='nav.family.info.relationship' />,
			width: 150,
			dataIndex: 'relationship',
			key: 'email'
		},
		{
			title: 'Action',
			// width: 100,
			dataIndex: '_id',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							setIsUpdateFamilyFields(true)
							setVisible(true)
							familyInformation.setFieldsValue(familyFields[index])
							setIndex(index)
						}}
					>
						<IntlMessages id='nav.volunteer.button.edit' />
					</span>
				)
			}
		},
		{
			dataIndex: 'active',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							var temp1 = [...familyFields]
							temp1.splice(index, 1)
							setFamilyFields(temp1)
							if (familyFields.length === 1) {
								familyInformation.resetFields()
							}
						}}
					>
						<IntlMessages id='nav.volunteer.button.delete' />
					</span>
				)
			}
		}
	]

	const familyStyle = {
		backgroundImage: 'url(images-reg/6.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	}

	return (
		<>
			<div style={familyStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='d-none d-lg-block col-lg-6 col-xl-7 display-2 text-end text-white pt-5'>
							Be Courageous
						</div>
						<div className='col-lg-6 col-xl-5 bg-white p-5 rounded-3 my-2'>
							<Modal
								title={intl.formatMessage({ id: 'nav.family.info.title' })}
								visible={visible}
								onOk={familyInformation.submit}
								onCancel={handleCancel}
								width='35vw'
								centered={true}
								// bodyStyle={{
								// 	height: '620px'
								// }}
							>
								<Form
									// {...formItemLayout}
									form={familyInformation}
									name='familyInformation'
									onFinish={onSubmitFamilyInfo}
									style={{ margineTop: 30 }}
								>
									<div>
										{familyFields.length === 0 ? (
											<Form.Item
												hasFeedback
												name='type'
												// label={intl.formatMessage({
												// 	id: 'nav.family.info.member.type'
												// })}
												rules={[
													{
														required: true,
														message: intl.formatMessage({
															id: 'nav.family.info.type.errorMessage'
														})
													}
												]}
											>
												<Cascader
													options={familyTypeList.map(item => {
														return {
															value: item,
															label: intl.formatMessage({
																id: 'nav.family.info.familyTypeList.' + item
															})
														}
													})}
													size={'middle'}
													placeholder={
														intl.formatMessage({
															id: 'nav.family.info.member.type'
														}) + '*'
													}
												/>
											</Form.Item>
										) : null}
										<Form.Item
											hasFeedback
											name='relationship'
											// label={intl.formatMessage({ id: 'nav.family.info.relationship' })}
											rules={[
												{
													required: true,
													message: intl.formatMessage({
														id: 'nav.family.info.relationship.errorMessage'
													})
												}
											]}
										>
											<Cascader
												options={relationshipList.map(item => {
													return {
														value: item,
														label: intl.formatMessage({
															id: 'nav.family.info.relationshipList.' + item
														})
													}
												})}
												size={'middle'}
												placeholder={
													intl.formatMessage({
														id: 'nav.family.info.relationship'
													}) + '*'
												}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='name'
											// label={intl.formatMessage({ id: 'nav.volunteer.formName' })}
											rules={[
												{
													required: true,
													min: 5,
													max: 200,
													message: intl.formatMessage({
														id: 'nav.volunteer.formName.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												size='large'
												style={{ width: '100%' }}
												prefix={<AiOutlineUser />}
												placeholder={`${intl.formatMessage({
													id: 'nav.volunteer.formName'
												})}*`}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='deadOrAlive'
											// label={intl.formatMessage({ id: 'nav.family.info.deadOrAlive' })}
											rules={[
												{
													required: true,
													message: intl.formatMessage({
														id: 'nav.family.info.deadOrAlive.errorMessage'
													})
												}
											]}
										>
											<Cascader
												options={deadOrAliveList.map(item => {
													return {
														value: item,
														label: intl.formatMessage({
															id: 'nav.family.info.deadOrAliveList.' + item
														})
													}
												})}
												size={'middle'}
												placeholder={
													intl.formatMessage({
														id: 'nav.family.info.deadOrAlive'
													}) + '*'
												}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='dateOfBirth'
											// label={intl.formatMessage({ id: 'nav.family.info.dateOfBirth' })}
											rules={[
												{
													required: true,
													message: intl.formatMessage({
														id: 'nav.family.info.age.errorMessage'
													})
												}
											]}
										>
											{/* <Input size='large' type='date' max={todayString} /> */}
											<DatePicker
												disabledDate={d => !d || d.isAfter(todayString)}
												placeholder={
													intl.formatMessage({
														id: 'nav.family.info.dateOfBirth'
													}) + '*'
												}
												style={{
													width: '100%'
												}}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='occupation'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.form.occupation'
											// })}
											rules={[
												{
													required: true,
													message: intl.formatMessage({
														id: 'nav.family.info.occupation.errorMessage'
													})
												}
											]}
										>
											<Cascader
												options={occupationList.map(item => {
													return {
														value: item,
														label: intl.formatMessage({
															id: 'nav.volunteer.form.occupation.' + item
														})
													}
												})}
												// onChange={handleOccupationChange}
												size={'middle'}
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.form.occupation'
													}) + '*'
												}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='monthlyIncome'
											// label={intl.formatMessage({
											// 	id: 'nav.family.info.monthlyIncome'
											// })}
											rules={[
												{
													required: true,
													pattern: monthlyIncomeRegex,
													message: intl.formatMessage({
														id: 'nav.family.info.monthlyIncome.errorMessage'
													})
												}
											]}
										>
											<Input
												size='large'
												prefix={<RiMoneyDollarBoxLine />}
												style={{ width: '100%' }}
												placeholder={
													intl.formatMessage({
														id: 'nav.family.info.monthlyIncome'
													}) + '*'
												}
											/>
										</Form.Item>
									</div>
								</Form>
							</Modal>
							{/* family member info */}
							<Row style={{ marginBottom: '1rem' }}>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<h1
										style={{
											fontWeight: 500,
											color: '#535353',
											fontSize: '2rem',
											lineHeight: '2rem',
											wordWrap: 'breakWord'
										}}
									>
										<IntlMessages id='nav.family.info.title' />
									</h1>
								</Col>
								<Col
									xl={24}
									lg={24}
									md={24}
									sm={24}
									xs={24}
									style={{ textAlign: 'center' }}
								>
									{familyFields.length !== 0 ? (
										<div>
											<Table
												columns={columns}
												dataSource={familyFields.map(item => item)}
												pagination={false}
												// scroll={{ x: 400 }}
											/>
											<Button
												type='primary'
												onClick={() => {
													setVisible(true)
													familyInformation.resetFields()
												}}
												style={{ marginBottom: 0, marginTop: 10 }}
											>
												<IntlMessages id='nav.volunteer.add.more.button' />
											</Button>
										</div>
									) : (
										<Button
											type='primary'
											size='large'
											onClick={() => {
												setVisible(true)
												//setIsEducationFieldsOpen(true)
												familyInformation.resetFields()
											}}
											className='gx-mb-0'
											style={{
												backgroundColor: '#EE0000',
												color: '#ffffff',
												width: '50%'
												// marginBottom: '50px'
											}}
										>
											<IntlMessages id='nav.form.add' />
										</Button>
									)}
								</Col>
							</Row>
							<div className='d-flex justify-content-between'>
								<Button
									// type='primary'
									size='large'
									onClick={() => onPrevious()}
									className='gx-mb-0'
									style={{
										backgroundColor: '#fff',
										color: '#000'
										//width: '100%'
									}}
								>
									<IntlMessages id='nav.form.previous' />
								</Button>
								<Button
									type='primary'
									size='large'
									onClick={() => onSubmit()}
									className='gx-mb-0'
									style={{
										backgroundColor: '#EE0000',
										color: '#ffffff'
										//width: '100%'
									}}
								>
									<IntlMessages id='nav.form.next' />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(FamilyInfo)
