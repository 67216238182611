import React from 'react'

import { AiFillStar } from 'react-icons/ai'

function Rating({ rating }) {
	return (
		<div style={{color:'#FFC300'}}><AiFillStar />{rating}</div>
	)
}

export default Rating
