/* eslint-disable */
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Row,
	Upload,
	Image
} from 'antd'

import ImgCrop from 'antd-img-crop'

import { useState, useEffect } from 'react'
import { AiOutlineUser } from 'react-icons/ai'
import { FiPhone } from 'react-icons/fi'
import { HiOutlineIdentification } from 'react-icons/hi'
import { MdOutlineMail } from 'react-icons/md'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'
import './PersonalInfo.css'

import BeneficiaryCreateService from '../../services/BeneficiaryCreate'

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }
const maxFileSizeInMB = 1
//+8801738822921
const PersonalInfo = ({ onChange, previousValue, intl }) => {
	const [personalInformation] = Form.useForm()
	const [fileList, setFileList] = useState([])
	// const [personalInfo, setPersonalInfo] = useState(undefined)
	// const [isUpdatePersonalInfo, setIsUpdatePersonalInfo] = useState()
	// const phoneRegex = new RegExp(/(^(\+88)?(01){1}[3456789]{1}(\d){8})$/)
	// const emailRegex = new RegExp(/^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/)
	// const nidRegex = /^[0-9]{10,17}$/
	const [photo, setPhoto] = useState(null)
	const phoneRegex = new RegExp(/(^(\+88)?(01){1}[3456789]{1}(\d){8})$/)
	const emailRegex = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/)
	const nidRegex = /^[0-9]{10,17}$/

	const today = new Date()
	var day = today.getDate()
	day = (day < 10 ? '0' : '') + day
	var month = today.getMonth() + 1
	month = (month < 10 ? '0' : '') + month
	var year = today.getFullYear() + ''
	const todayString = `${year}-${month}-${day}`
	//+8801732838282
	// if (previousValue) {
	// 	personalInformation.setFieldsValue(previousValue)
	// 	console.log(previousValue)
	// }
	useEffect(() => {
		//console.log(previousValue);
		if (previousValue) {
			console.log(previousValue)
			personalInformation.setFieldsValue(previousValue)
			if (previousValue.photo && previousValue.photo.url) {
				setPhoto(previousValue.photo)
			}
		}
		return () => {
			console.log('>>>>>> personalInformation.resetFields')
			personalInformation.resetFields()
		}
	}, [previousValue, personalInformation])

	const onSubmitPersonalInfo = async value => {
		try {
			var personalInfo = {
				mobileNo: value.phone.length === 14 ? value.phone : '+88' + value.phone,
				secondaryMobileNo:
					value.sosPhone.length === 14
						? value.sosPhone
						: '+88' + value.sosPhone,
				email: value.email,
				nid: value.NID
			}

			const res = await BeneficiaryCreateService.checkUserExists(personalInfo)
			if (res.state === 'failure') {
				return message.error(intl.formatMessage({ id: res.message }))
			}
			if (photo && photo.url) {
				value.photo = photo
			} else {
				value.photo = null
			}
			console.log(value)
			onChange(1, value)
		} catch (err) {
			console.error(err)
			message.error(intl.formatMessage({ id: 'backend.network.error' }))
		}

		// setPersonalInfo(values)
		// setIsUpdatePersonalInfo(false)
	}

	const handleFormValuesChanged = (changedValues, allValues) => {
		// TODO
	}

	const onPreview = async file => {
		let src = file.url

		if (!src) {
			src = await new Promise(resolve => {
				const reader = new FileReader()
				reader.readAsDataURL(file.originFileObj)

				reader.onload = () => resolve(reader.result)
			})
		}

		const image = new Image()
		image.src = src
		const imgWindow = window.open(src)
		imgWindow?.document.write(image.outerHTML)
	}

	const beforeUpload = file => {
		// console.log(file)
		const isValidType = file.type === 'image/png' || file.type === 'image/jpeg'
		const isAllowedSize = file.size <= 1024 * 1024 * maxFileSizeInMB
		if (!isValidType) {
			message.error(`${file.name} is not a png/jpeg file`)
		} else if (!isAllowedSize) {
			message.error(`${file.name} exceeds ${maxFileSizeInMB} MB file size`)
		}
		return (isValidType && isAllowedSize) || Upload.LIST_IGNORE
	}

	const uploadPhoto = options => {
		const { onSuccess, onError, file } = options

		BeneficiaryCreateService.photoUpload(file).then(
			res => {
				if (res.state === 'success') {
					console.log(res)
					onSuccess(res)
					setPhoto(res.photo)
				} else {
					// console.log(res)
					onError(res)
				}
			},
			err => {
				console.error(err)
				onError(err)
			}
		)
	}
	console.log(photo)

	const onChangeImg = ({ fileList: newFileList }) => {
		const file = newFileList[0]
		if (file) {
			const isValidType =
				file.type === 'image/png' || file.type === 'image/jpeg'
			const isAllowedSize = file.size <= 1024 * 1024 * maxFileSizeInMB
			if (isValidType && isAllowedSize) {
				setFileList(newFileList)
			}
			console.log(newFileList)
		} else {
			setFileList(newFileList)
		}
	}

	const personalStyle = {
		backgroundImage: 'url(images-reg/1.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	}
	return (
		<>
			<div className='p-2' style={personalStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-lg-6 col-xl-5 p-5 bg-white my-2 opacity-100 rounded-3'>
							{/* personal info */}
							<Row>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<h1
										style={{
											// color: '#535353',
											fontWeight: 500,
											// color: 'rgba(0, 0, 0, 0.55)',
											color: '#535353',
											fontSize: '2rem',
											lineHeight: '2rem',
											wordWrap: 'breakWord'
										}}
									>
										<IntlMessages id='nav.volunteer.formTitle' />
									</h1>
								</Col>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<Form
										// {...formItemLayout}
										form={personalInformation}
										name='BeneficiaryForm'
										//onValuesChange={handleFormValuesChanged}
										onFinish={onSubmitPersonalInfo}
										style={{ margineTop: 10 }}
									>
										<Form.Item
											name='name'
											hasFeedback
											// label={intl.formatMessage({ id: 'nav.volunteer.formName' })}
											rules={[
												{
													required: true,
													min: 5,
													max: 200,
													message: intl.formatMessage({
														id: 'nav.volunteer.formName.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												// wrapperCol={{ lg: 24 }}
												placeholder={
													intl.formatMessage({ id: 'nav.volunteer.formName' }) +
													'*'
												}
												prefix={<AiOutlineUser />}
											/>
										</Form.Item>

										{photo && photo.url ? (
											<Form.Item label='Photo'>
												<Image width={100} src={photo.url} />
												<Button
													type='primary'
													onClick={() => {
														setPhoto(null)
													}}
												>
													delete
												</Button>
											</Form.Item>
										) : (
											<Form.Item
												name='image'
												label={
													<IntlMessages id='nav.volunteer.formImageTitle' />
												}
											>
												<ImgCrop
													showGrid
													rotationSlider
													showReset
													aspect={'1.5'}
												>
													<Upload
														customRequest={uploadPhoto}
														listType='picture-card'
														fileList={fileList}
														multiple={false}
														onChange={onChangeImg}
														onPreview={onPreview}
														beforeUpload={beforeUpload}
													>
														{fileList.length < 1 && (
															<IntlMessages id='nav.volunteer.formImage' />
														)}
													</Upload>
												</ImgCrop>
											</Form.Item>
										)}

										<Form.Item
											name='email'
											hasFeedback
											// label={intl.formatMessage({ id: 'nav.volunteer.formEmail' })}
											rules={[
												{
													//required: true,
													pattern: emailRegex,
													message: intl.formatMessage({
														id: 'nav.volunteer.formEmail.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.formEmail'
													}) + ' '
												}
												prefix={<MdOutlineMail />}
											/>
										</Form.Item>

										<Form.Item
											hasFeedback
											name='phone'
											style={{
												display: 'inline-block',
												width: 'calc(50% - 8px)'
											}}
											// label={intl.formatMessage({ id: 'nav.volunteer.formPhone' })}
											rules={[
												{
													required: true,
													pattern: phoneRegex,
													message: intl.formatMessage({
														id: 'nav.volunteer.formPhone.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.formPhone'
													}) + '*'
												}
												prefix={<FiPhone />}
											/>
										</Form.Item>

										<Form.Item
											hasFeedback
											name='dob'
											style={{
												display: 'inline-block',
												width: 'calc(50% - 8px)',
												marginLeft: '16px'
											}}
											// label={intl.formatMessage({ id: 'nav.volunteer.from.dob' })}
											rules={[
												{
													required: true,
													message: intl.formatMessage({
														id: 'nav.volunteer.from.dob.errorMessage'
													})
													// whitespace: true
												}
											]}
										>
											<DatePicker
												disabledDate={d => !d || d.isAfter(todayString)}
												placeholder={
													intl.formatMessage({ id: 'nav.volunteer.from.dob' }) +
													'*'
												}
												style={{
													width: '100%'
												}}
											/>
										</Form.Item>
										{/* <Form.Item
											name='dob' style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
											// label={intl.formatMessage({ id: 'nav.volunteer.from.dob' })}
											rules={[
												{
													required: true,
													message: intl.formatMessage({
														id: 'nav.volunteer.from.dob.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input type='date' max={todayString}
												placeholder={intl.formatMessage({ id: 'nav.volunteer.from.dob' })}
												prefix={<MdOutlineDateRange />}
											/>
										</Form.Item> */}

										<Form.Item
											hasFeedback
											name='sosPhone'
											// label={intl.formatMessage({ id: 'nav.volunteer.sosPhone' })}
											rules={[
												{
													required: true,
													pattern: phoneRegex,
													message: intl.formatMessage({
														id: 'nav.volunteer.sosPhone.errorMessage'
													}),
													whitespace: true
												},
												({ getFieldValue }) => ({
													validator(_, value) {
														if (getFieldValue('phone') === value) {
															return Promise.reject(
																new Error(
																	intl.formatMessage({
																		id: 'nav.volunteer.sosPhone.double.errorMessage'
																	})
																)
															)
														} else {
															return Promise.resolve()
														}
													}
												})
											]}
										>
											<Input
												placeholder={
													intl.formatMessage({ id: 'nav.volunteer.sosPhone' }) +
													'*'
												}
												prefix={<FiPhone />}
											/>
										</Form.Item>

										<Form.Item
											hasFeedback
											name='NID'
											// label={intl.formatMessage({ id: 'nav.volunteer.formNID' })}
											rules={[
												{
													required: false,
													pattern: nidRegex,
													message: intl.formatMessage({
														id: 'nav.volunteer.formNID.errorMessage'
													})
												}
											]}
										>
											<Input
												placeholder={intl.formatMessage({
													id: 'nav.volunteer.formNID'
												})}
												prefix={<HiOutlineIdentification />}
											/>
										</Form.Item>
										<Button
											type='primary'
											htmlType='submit'
											style={{ float: 'right' }}
										>
											<IntlMessages id='nav.form.next' />
										</Button>
									</Form>
								</Col>
							</Row>
						</div>
						<div className='d-none d-lg-block col-lg-6 col-xl-7 display-1 text-white pt-5 ps-5'>
							Decide What You Want
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(PersonalInfo)
