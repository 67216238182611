import { Button, Cascader, Checkbox, Col, Form, Input, Row } from 'antd'
import { useState } from 'react'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }

var zillaUpazilla = [
	{
		zilla: 'BARGUNA',
		thana: [
			'BARGUNA-SADAR',
			'BAMNA',
			'PATHORGHATA',
			'TALTOLI',
			'BETAGI',
			'AMTALI'
		]
	},
	{
		zilla: 'BARISAL',
		thana: [
			'BARISAL-SADAR',
			{
				name: 'BARISAL-CITY-CORPORATION',
				area: [
					'KOTWALI',
					'AIRPORT',
					'KAWNIA',
					'RUPATOLY',
					'BARISAL-UNIVERSITY',
					'CHORMONAI',
					'KASHIPUR'
				]
			},
			'BAKERGANJ',
			'BABUGANJ',
			'WAZIRPUR',
			'BANARIPARA',
			'GOURNADI',
			'AGAILJHARA',
			'MEHENDIGONJ',
			'MULADI',
			'HIZLA'
		]
	},
	{
		zilla: 'BHOLA',
		thana: [
			'BHOLA-SADAR',
			'CHARFESSON',
			'DOULATKHAN',
			'MONPURA',
			'TAZUMUDDIN',
			'LALMOHAN',
			'BORHANUDDIN'
		]
	},
	{
		zilla: 'JHALOKATI',
		thana: ['JHALAKATHI-SADAR', 'KATHALIA', 'NALCHITY', 'RAJAPUR']
	},
	{
		zilla: 'PATUAKHALI',
		thana: [
			'PATUAKHALI-SADAR',
			'BAUPHAL',
			'DUMKI',
			'DASHMINA',
			'KALAPARA',
			'MIRZAGANJ',
			'GALACHIPA',
			'RANGABALI'
		]
	},
	{
		zilla: 'PIROJPUR',
		thana: [
			'PIROJPUR-SADAR',
			'NAZIRPUR',
			'KAWKHALI',
			'BHANDARIA',
			'MATHBARIA',
			'INDURKANI',
			'NESARABAD'
		]
	},
	{
		zilla: 'BANDARBAN',
		thana: [
			'BANDARBAN-SADAR',
			'ALIKADAM',
			'NAIKHONGCHHARI',
			'ROWANGCHHARI',
			'LAMA',
			'RUMA',
			'THANCHI'
		]
	},
	{
		zilla: 'BRAHMANBARIA',
		thana: [
			'BRAHMANBARIA-SADAR',
			'KASBA',
			'NASIRNAGAR',
			'SARAIL',
			'ASHUGANJ',
			'AKHAURA',
			'NABINAGAR',
			'BANCHARAMPUR',
			'BIJOYNAGAR'
		]
	},
	{
		zilla: 'CHANDPUR',
		thana: [
			'CHANDPUR-SADAR',
			'HAIMCHAR',
			'KACHUA',
			'SHAHRASTI',
			'MATLAB',
			'HAJIGANJ',
			'FARIDGONJ'
		]
	},
	{
		zilla: 'CHITTAGONG',
		thana: [
			'RANGUNIA',
			'SITAKUNDA',
			{
				name: 'CHITTAGONG-CITY-CORPORATION',
				area: [
					'CHANDGAON',
					'BONDOR',
					'DOUBLE-MOORING',
					'KOTWALI',
					'PAHARTALI',
					'PANCHLAISH',
					'BAYEJID-BOSTAMI',
					'PATENGA',
					'HALISHAHAR',
					'KHULSHI',
					'BAKALIA',
					'KARNAPHULI',
					'CHAWK-BAZAR',
					'AKBAR-SHAH',
					'SADARGHAT',
					'EPZ'
				]
			},
			'MIRSHARAI',
			'PATIYA',
			'SANDWIP',
			'BANSHKHALI',
			'BOALKHALI',
			'ANWARA',
			'CHANDANAISH',
			'SATKANIA',
			'LOHAGARA',
			'HATHAZARI',
			'FATIKCHHARI',
			'RAOZAN',
			'KARNAFULI'
		]
	},
	{
		zilla: 'COMILLA',
		thana: [
			'COMILLA-SADAR',
			'COMILLA-CITY-CORPORATION',
			'DEBIDWAR',
			'BARURA',
			'BRAHMANPARA',
			'CHANDINA',
			'CHAUDDAGRAM',
			'DAUDKANDI',
			'HOMNA',
			'LAKSAM',
			'MURADNAGAR',
			'NANGALKOT',
			'MEGHNA',
			'MONOHARGONJ',
			'SADAR-SOUTH',
			'TITAS',
			'BURICHANG',
			'LALMAI'
		]
	},
	{
		zilla: 'COXS-BAZAR',
		thana: [
			'COXSBAZAR-SADAR',
			'CHAKARIA',
			'KUTUBDIA',
			'UKHIYA',
			'MOHESHKHALI',
			'PEKUA',
			'RAMU',
			'TEKNAF'
		]
	},
	{
		zilla: 'FENI',
		thana: [
			'FENI-SADAR',
			'CHHAGALNAIYA',
			'SONAGAZI',
			'FULGAZI',
			'PARSHURAM',
			'DAGANBHUIYAN'
		]
	},
	{
		zilla: 'KHAGRACHHARI',
		thana: [
			'KHAGRACHHARI-SADAR',
			'DIGHINALA',
			'PANCHARI',
			'LAXMICHHARI',
			'MOHALCHARI',
			'MANIKCHARI',
			'RAMGARH',
			'MATIRANGA',
			'GUIMARA'
		]
	},
	{
		zilla: 'LAKSHMIPUR',
		thana: ['LAKSHMIPUR-SADAR', 'KAMALNAGAR', 'RAIPUR', 'RAMGATI', 'RAMGANJ']
	},
	{
		zilla: 'NOAKHALI',
		thana: [
			'NOAKHALI-SADAR',
			'COMPANIGANJ',
			'BEGUMGANJ',
			'HATIA',
			'SUBARNACHAR',
			'KABIRHAT',
			'SENBUG',
			'CHATKHIL',
			'SONAIMURI'
		]
	},
	{
		zilla: 'RANGAMATI',
		thana: [
			'RANGAMATI-SADAR',
			'KAPTAI',
			'KAUKHALI',
			'BAGHAICHARI',
			'BARKAL',
			'LANGADU',
			'RAJASTHALI',
			'BELAICHARI',
			'JURAICHARI',
			'NANIARCHAR'
		]
	},
	{
		zilla: 'DHAKA',
		thana: [
			'SAVAR',
			{
				name: 'DHAKA-CITY',
				area: [
					'ADABOR',
					'BADDA',
					'BANANI',
					'BANGSHAL',
					'BIMANBANDAR',
					'BSAHANTEK',
					'CANTONMENT',
					'CHALKBAZAR',
					'DAKKHINKHAN',
					'DARUS-SALAM',
					'DEMRA',
					'DHANMONDI',
					'GANDARIA',
					'GULSHAN',
					'HAJARIBAG',
					'JATRABARI',
					'KAFRUL',
					'KALABAGAN',
					'KAMRANGIRCHAR',
					'KHILGAON',
					'KHILKHET',
					'KADAMTALI',
					'KOTWALI',
					'LALBAGH',
					'MIRPUR',
					'MOHAMMADPUR',
					'MOTIJHEEL',
					'MUGDA',
					'NEW-MARKET',
					'PALLABI',
					'PALTAN',
					'RAMNA',
					'RAMPURA',
					'RUPNAGAR',
					'SABUJBAG',
					'SHAH-ALI',
					'SHAHBAG',
					'SHAHJAHANPUR',
					'SHER-E-BANGLA-NAGAR',
					'SHYAMPUR',
					'SUTRAPUR',
					'TEJGAON',
					'TURAG',
					'UTTAR-KHAN',
					'UTTARA',
					'VATARA',
					'WARI'
				]
			},
			'DHAMRAI',
			'KERANIGANJ',
			'NAWABGANJ',
			'DOHAR'
		]
	},
	{
		zilla: 'FARIDPUR',
		thana: [
			'FARIDPUR-SADAR',
			'ALFADANGA',
			'BOALMARI',
			'SADARPUR',
			'NAGARKANDA',
			'BHANGA',
			'CHARBHADRASAN',
			'MADHUKHALI',
			'SALTHA'
		]
	},
	{
		zilla: 'GAZIPUR',
		thana: [
			'GAZIPUR-SADAR',
			{
				name: 'GAZIPUR-CITY-CORPORATION',
				area: [
					'BASON',
					'KONABARI',
					'KASIMPUR',
					'GASA',
					'PUBAIL',
					'TONGI-EAST',
					'TONGI-WEST'
				]
			},
			'KALIGANJ',
			'KALIAKAIR',
			'KAPASIA',
			'SREEPUR'
		]
	},
	{
		zilla: 'GOPALGANJ',
		thana: [
			'GOPALGANJ-SADAR',
			'KASHIANI',
			'TUNGIPARA',
			'KOTALIPARA',
			'MUKSUDPUR'
		]
	},
	{
		zilla: 'JAMALPUR',
		thana: [
			'JAMALPUR-SADAR',
			'MELANDAH',
			'ISLAMPUR',
			'DEWANGONJ',
			'SARISHABARI',
			'MADARGANJ',
			'BOKSHIGANJ'
		]
	},
	{
		zilla: 'KISHOREGONJ',
		thana: [
			'KISHOREGANJ-SADAR',
			'ITNA',
			'KATIADI',
			'BHAIRAB',
			'TARAIL',
			'HOSSAINPUR',
			'PAKUNDIA',
			'KULIARCHAR',
			'KARIMGONJ',
			'BAJITPUR',
			'AUSTAGRAM',
			'MITHAMOIN',
			'NIKLI'
		]
	},
	{
		zilla: 'MADARIPUR',
		thana: ['MADARIPUR-SADAR', 'SHIBCHAR', 'KALKINI', 'RAJOIR']
	},
	{
		zilla: 'MANIKGANJ',
		thana: [
			'MANIKGANJ-SADAR',
			'DAULATPUR',
			'SINGIAR',
			'SHIBALOY',
			'GIOR',
			'SATURIA',
			'HARIRAMPUR'
		]
	},
	{
		zilla: 'MUNSHIGANJ',
		thana: [
			'MUNSHIGANJ-SADAR',
			'SREENAGAR',
			'SIRAJDIKHAN',
			'LOUHAJANJ',
			'GAJARIA',
			'TONGIBARI'
		]
	},
	{
		zilla: 'MYMENSINGH',
		thana: [
			'MYMENSINGH-SADAR',
			'MYMENSINGH-CITY-CORPORATION',
			'FULBARIA',
			'TRISHAL',
			'BHALUKA',
			'MUKTAGACHA',
			'DHOBAURA',
			'PHULPUR',
			'HALUAGHAT',
			'GOURIPUR',
			'ISWARGANJ',
			'TARAKANDA',
			'GAFARGAON',
			'NANDAIL'
		]
	},
	{
		zilla: 'NARAYANGANJ',
		thana: [
			'NARAYANGANJ-SADAR',
			{
				name: 'NARAYANGANJ-CITY-CORPORATION',
				area: ['FATULLAH', 'SIDDHIRGANJ']
			},
			'ARAIHAZAR',
			'BANDAR',
			'RUPGANJ',
			'SONARGAON'
		]
	},
	{
		zilla: 'NARSINGDI',
		thana: [
			'NARSINGDI-SADAR',
			'MONOHARDI',
			'BELABO',
			'PALASH',
			'RAIPURA',
			'SHIBPUR'
		]
	},
	{
		zilla: 'NETRAKONA',
		thana: [
			'NETROKONA-SADAR',
			'MADAN',
			'DURGAPUR',
			'ATPARA',
			'KENDUA',
			'KHALIAJURI',
			'KALMAKANDA',
			'PURBADHALA',
			'MOHONGONJ',
			'BARHATTA'
		]
	},
	{
		zilla: 'RAJBARI',
		thana: ['RAJBARI-SADAR', 'GOALANDA', 'PANGSA', 'BALIAKANDI', 'KALUKHALI']
	},
	{
		zilla: 'SHARIATPUR',
		thana: [
			'SHARIATPUR-SADAR',
			'NARIA',
			'ZAJIRA',
			'GOSAIRHAT',
			'BHEDARGANJ',
			'DAMUDYA'
		]
	},
	{
		zilla: 'TANGAIL',
		thana: [
			'TANGAIL-SADAR',
			'BASAIL',
			'BHUAPUR',
			'DELDUAR',
			'GHATAIL',
			'GOPALPUR',
			'MIRZAPUR',
			'NAGARPUR',
			'SAKHIPUR',
			'KALIHATI',
			'DHANBARI',
			'MADHUPUR'
		]
	},
	{
		zilla: 'SHERPUR',
		thana: ['SHERPUR-SADAR', 'NOKLA', 'JHENAIGATI', 'SREEBORDI', 'NALITABARI']
	},
	{
		zilla: 'BAGERHAT',
		thana: [
			'BAGERHAT-SADAR',
			'FAKIRHAT',
			'MOLLAHAT',
			'SARANKHOLA',
			'RAMPAL',
			'MORRELGANJ',
			'KOCHUA',
			'MONGLA',
			'CHITALMARI'
		]
	},
	{
		zilla: 'CHUADANGA',
		thana: ['CHUADANGA-SADAR', 'ALAMDANGA', 'DAMURHUDA', 'JIBANNAGAR']
	},
	{
		zilla: 'JESSORE',
		thana: [
			'JESSORE-SADAR',
			'MANIRAMPUR',
			'ABHAYNAGAR',
			'BAGHERPARA',
			'CHOUGACHHA',
			'JHIKARGACHA',
			'KESHABPUR',
			'SHARSHA'
		]
	},
	{
		zilla: 'JHENAIDAH',
		thana: [
			'JHENAIDAH-SADAR',
			'SHAILKUPA',
			'HARINAKUNDU',
			'KALIGONJ',
			'KOTCHANDPUR',
			'MOHESHPUR'
		]
	},
	{
		zilla: 'KHULNA',
		thana: [
			'PAIKGASA',
			{
				name: 'KHULNA-CITY-CORPORATION',
				area: [
					'KOTWALI',
					'SONADANGA',
					'DAULATPUR',
					'KHALISHPUR',
					'KHAN-JAHAN-ALI',
					'LABONCHORA',
					'HORINTANA',
					'ARONGGHATA'
				]
			},
			'FULTOLA',
			'DIGHOLIA',
			'RUPSHA',
			'TEROKHADA',
			'DUMURIA',
			'BOTIAGHATA',
			'DAKOP',
			'KOYRA'
		]
	},
	{
		zilla: 'KUSHTIA',
		thana: [
			'KUSHTIA-SADAR',
			'KUMARKHALI',
			'KHOKSA',
			'MIRPUR',
			'DOULATPUR',
			'BHERAMARA'
		]
	},
	{
		zilla: 'MAGURA',
		thana: ['MAGURA-SADAR', 'SHALIKHA', 'SREEPOOR', 'MOHAMMADPUR']
	},
	{
		zilla: 'MEHERPUR',
		thana: ['MEHERPUR-SADAR', 'MUJIBNAGAR', 'GANGNI']
	},
	{
		zilla: 'NARAIL',
		thana: ['NARIAL-SADAR', 'LOHAGORA', 'KALIA']
	},
	{
		zilla: 'SATKHIRA',
		thana: [
			'SATKHIRA-SADAR',
			'SHYAMNAGAR',
			'ASSASUNI',
			'DEBHATA',
			'KALAROA',
			'TALA',
			'KAALIGANJ'
		]
	},
	{
		zilla: 'BOGRA',
		thana: [
			'BOGRA-SADAR',
			'KAHALOO',
			'SHARIAKANDI',
			'SHAJAHANPUR',
			'DUPCHANCHIA',
			'ADAMDIGHI',
			'NONDIGRAM',
			'SONATALA',
			'DHUNOT',
			'GABTALI',
			'SHERPUR',
			'SHIBGANJ'
		]
	},
	{
		zilla: 'JOYPURHAT',
		thana: ['JOYPURHAT-SADAR', 'AKKELPUR', 'KHETLAL', 'PANCHBIBI', 'KALAI']
	},
	{
		zilla: 'NAOGAON',
		thana: [
			'NAOGAON-SADAR',
			'RANINAGAR',
			'ATRAI',
			'MANDA',
			'NIAMATPUR',
			'DHAMOIRHAT',
			'PATNITALA',
			'BADALGACHI',
			'MOHADEVPUR',
			'SAPAHAR',
			'PORSHA'
		]
	},
	{
		zilla: 'NATORE',
		thana: [
			'NATORE-SADAR',
			'SINGRA',
			'BARAIGRAM',
			'BAGATIPARA',
			'LALPUR',
			'GURUDASPUR',
			'NALDANGA'
		]
	},
	{
		zilla: 'CHAPAI-NABABGANJ',
		thana: [
			'CHAPAINAWABGANJ-SADAR',
			'SHIBGONJ',
			'GOMOSTAPUR',
			'NACHOL',
			'BHOLAHAT'
		]
	},
	{
		zilla: 'PABNA',
		thana: [
			'PABNA-SADAR',
			'SUJANAGAR',
			'ISHURDI',
			'BHANGURA',
			'BERA',
			'ATGHORIA',
			'CHATMOHAR',
			'SANTHIA',
			'FARIDPUR'
		]
	},
	{
		zilla: 'RAJSHAHI',
		thana: [
			'PABA',
			{
				name: 'RAJSHAHI-CITY-CORPORATION',
				area: [
					'BOALIA',
					'RAJPARA',
					'MATIHAR',
					'SHAH-MAKHDUM',
					'CHANDRIMA',
					'KASHIA-DANGA',
					'KATAKHALI',
					'BELPUKUR',
					'AIRPORT',
					'KARMOHAR',
					'DAMKURA'
				]
			},
			'DURGAPOOR',
			'MOHONPUR',
			'CHARGHAT',
			'PUTHIA',
			'BAGHA',
			'TANORE',
			'BAGMARA',
			'GODAGARI'
		]
	},
	{
		zilla: 'SIRAJGANJ',
		thana: [
			'SIRAJGANJ-SADAR',
			'BELKUCHI',
			'CHAUHALI',
			'KAMARKHAND',
			'KAZIPUR',
			'RAIGONJ',
			'SHAHJADPUR',
			'TARASH',
			'ULLAPARA'
		]
	},
	{
		zilla: 'DINAJPUR',
		thana: [
			'DINAJPUR-SADAR',
			'NAWABGONJ',
			'BIRGANJ',
			'GHORAGHAT',
			'BIRAMPUR',
			'PARBATIPUR',
			'BOCHAGANJ',
			'KAHAROL',
			'FULBARI',
			'HAKIMPUR',
			'KHANSAMA',
			'BIROL',
			'CHIRIRBANDAR'
		]
	},
	{
		zilla: 'GAIBANDHA',
		thana: [
			'GAIBANDHA-SADAR',
			'SADULLAPUR',
			'PALASHBARI',
			'SAGHATA',
			'GOBINDAGANJ',
			'SUNDARGANJ',
			'PHULCHARI'
		]
	},
	{
		zilla: 'KURIGRAM',
		thana: [
			'KURIGRAM-SADAR',
			'NAGESHWARI',
			'BHURUNGAMARI',
			'PHULBARI',
			'RAJARHAT',
			'ULIPUR',
			'CHILMARI',
			'ROWMARI',
			'CHAR-RAJIBPUR'
		]
	},
	{
		zilla: 'LALMONIRHAT',
		thana: [
			'LALMONIRHAT-SADAR',
			'KAALIGONJ',
			'HATIBANDHA',
			'PATGRAM',
			'AADITMARI'
		]
	},
	{
		zilla: 'NILPHAMARI',
		thana: [
			'NILPHAMARI-SADAR',
			'JALDHAKA',
			'KISHORGANJ',
			'DOMAR',
			'DIMLA',
			'SYEDPUR'
		]
	},
	{
		zilla: 'PANCHAGARH',
		thana: ['PANCHAGARH-SADAR', 'BODA', 'DEBIGANJ', 'ATWARI', 'TETULIA']
	},
	{
		zilla: 'RANGPUR',
		thana: [
			'RANGPUR-SADAR',
			'PIRGACHA',
			{
				name: 'RANGPUR-CITY-CORPORATION',
				area: [
					'KOTWALI',
					'PARSHURAM',
					'TAJHAT',
					'MAHIGONJ',
					'HARAGACH',
					'HAZIRHAT'
				]
			},
			'KAUNIA',
			'PIRGONJ',
			'MITHAPUKUR',
			'BADARGONJ',
			'TARAGONJ',
			'GANGACHARA'
		]
	},
	{
		zilla: 'THAKURGAON',
		thana: [
			'THAKURGAON-SADAR',
			'PIRGANJ',
			'RANISANKAIL',
			'HARIPUR',
			'BALIADANGI'
		]
	},
	{
		zilla: 'HABIGANJ',
		thana: [
			'HABIGANJ-SADAR',
			'NABIGANJ',
			'BAHUBAL',
			'AJMIRIGANJ',
			'BANIACHANG',
			'LAKHAI',
			'CHUNARUGHAT',
			'MADHABPUR',
			'SHAYESTAGANJ'
		]
	},
	{
		zilla: 'MAULVIBAZAR',
		thana: [
			'MOULVIBAZAR-SADAR',
			'BARALEKHA',
			'KAMALGANJ',
			'KULAURA',
			'RAJNAGAR',
			'SRIMANGAL',
			'JURI'
		]
	},
	{
		zilla: 'SUNAMGANJ',
		thana: [
			'SUNAMGANJ-SADAR',
			'BISHWAMBHARPUR',
			'CHHATAK',
			'JAGANNATHPUR',
			'DOARABAZAR',
			'TAHIRPUR',
			'DHARMAPASHA',
			'JAMALGANJ',
			'SHALLA',
			'DIRAI',
			'SOUTH-SUNAMGANJ'
		]
	},
	{
		zilla: 'SYLHET',
		thana: [
			'SYLHET-SADAR',
			'JAINTAPUR',
			'ZAKIGANJ',
			{
				name: 'SYLHET-CITY-CORPORATION',
				area: [
					'KOTWALI',
					'JALALABAD',
					'AIRPORT',
					'MOGLABAZAR',
					'SHAH-PARAN',
					'DAKSHIN-SURMA'
				]
			},
			'GOWAINGHAT',
			'GOLAPGANJ',
			'FENCHUGANJ',
			'KANAIGHAT',
			'SOUTH-SURMA',
			'OSMANINAGAR',
			'BISHWANATH',
			'COMPANYGANJ',
			'BEANIBAZAR',
			'BALAGANJ'
		]
	}
]

zillaUpazilla.sort((a, b) => {
	if (a.zilla < b.zilla) return -1
	if (a.zilla > b.zilla) return 1
	return 0
})
var zillaList = []
zillaUpazilla.forEach(zilla => {
	zilla.thana.sort()
	zillaList.push(zilla)
})

const AddressInfo = ({ onChange, previousValue, intl }) => {
	const [addressInformation] = Form.useForm()
	const [sameAddress, setSameAddress] = useState(false)

	const onCheckBoxChange = () => {
		if (sameAddress) {
			setSameAddress(false)
		} else if (!sameAddress) {
			setSameAddress(true)
		}
	}

	if (previousValue) {
		addressInformation.setFieldsValue(previousValue)
		console.log(previousValue)
	}

	// const handleFormValuesChanged = (changedValues, allValues) => {
	// 	// TODO
	// }

	const onSubmitAddressInfo = values => {
		onChange(5, values)
	}

	const onPrevious = () => {
		onChange(3)
	}

	const addressStyle = {
		backgroundImage: 'url(images-reg/5.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	}

	return (
		<>
			<div style={addressStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-lg-6 col-xl-5 bg-white p-5 my-2 rounded-3'>

							<Row>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<h1
										style={{
											// margin: '0 0 30px 0',
											fontWeight: 500,
											color: '#535353',
											fontSize: '2rem',
											lineHeight: '2rem',
											wordWrap: 'breakWord'
										}}
									>
										<IntlMessages id='nav.volunteer.formAddress' />
									</h1>
								</Col>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<Form
										// {...formItemLayout}
										form={addressInformation}
										name='addressInfoForm'
										onFinish={onSubmitAddressInfo}
									>
										{/* work info */}
										<>
											<h4 style={{ marginBottom: '20px', color: 'rgba(0, 0, 0, 0.55)' }}>
												<IntlMessages id='nav.volunteer.subtitle.presentAddress' />
											</h4>
											<Form.Item hasFeedback
												name='permanentDistrict'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.form.distAndThana'
												// })}
												rules={[
													{
														required: true,
														message: intl.formatMessage({
															id: 'nav.volunteer.zilla.errorMessage'
														})
													}
												]}
											>
												<Cascader
													options={zillaList.map(item => {
														return {
															value: item.zilla,
															label: intl.formatMessage({
																id: 'nav.volunteer.dist.name.' + item.zilla
															}),
															children: [
																...item.thana
																	.filter(thanaItem => {
																		return typeof thanaItem === 'object'
																	})
																	.map(cityCorpItem => {
																		return {
																			value: cityCorpItem.name,
																			label: intl.formatMessage({
																				id: 'nav.volunteer.thana.' + cityCorpItem.name
																			}),
																			children: cityCorpItem.area.map(areaItem => {
																				return {
																					value: areaItem,
																					label: intl.formatMessage({
																						id: 'nav.volunteer.area.' + areaItem
																					})
																				}
																			})
																		}
																	}),
																...item.thana
																	.filter(thanaItem => {
																		return typeof thanaItem === 'string'
																	})
																	.map(thanaItem => {
																		return {
																			value: thanaItem,
																			label: intl.formatMessage({
																				id: 'nav.volunteer.thana.' + thanaItem
																			})
																		}
																	})
															]
														}
													})}
													// size={'large'}
													placeholder={intl.formatMessage({
														id: 'nav.volunteer.form.distAndThana'
													}) + '*'}
												/>
											</Form.Item>
											<Form.Item hasFeedback
												name='permanentAddress'
												// label={intl.formatMessage({ id: 'nav.volunteer.formAddress' })}
												rules={[
													{
														required: true,
														min: 10,
														max: 500,
														message: intl.formatMessage({
															id: 'nav.volunteer.formAddress.errorMessage'
														}),
														whitespace: true
													}
												]}
											>
												<Input.TextArea
													rows={2}
													// size='large'
													placeholder={intl.formatMessage({ id: 'nav.volunteer.formAddress' }) + '*'}
												/>
											</Form.Item>
											<h4 style={{ marginBottom: '0.25rem', color: 'rgba(0, 0, 0, 0.55)', }}>
												<IntlMessages id='nav.volunteer.subtitle.permanentAddress' />
											</h4>
											<Form.Item hasFeedback
												style={{ marginBottom: '0.2rem', textAlign: 'left', maxWidth: '70%' }}
												label={intl.formatMessage({
													id: 'address.info.same.title'
												})}
											>
												<div>
													<Checkbox onChange={onCheckBoxChange}></Checkbox>
												</div>
											</Form.Item>
											{!sameAddress ? (
												<>
													<Form.Item hasFeedback
														name='presentDistrict'
														// label={intl.formatMessage({
														// 	id: 'nav.volunteer.form.distAndThana'
														// })}
														rules={[
															{
																required: true,
																message: intl.formatMessage({
																	id: 'nav.volunteer.zilla.errorMessage'
																})
															}
														]}
													>
														<Cascader
															options={zillaList.map(item => {
																return {
																	value: item.zilla,
																	label: intl.formatMessage({
																		id: 'nav.volunteer.dist.name.' + item.zilla
																	}),
																	children: [
																		...item.thana
																			.filter(thanaItem => {
																				return typeof thanaItem === 'object'
																			})
																			.map(cityCorpItem => {
																				return {
																					value: cityCorpItem.name,
																					label: intl.formatMessage({
																						id: 'nav.volunteer.thana.' + cityCorpItem.name
																					}),
																					children: cityCorpItem.area.map(areaItem => {
																						return {
																							value: areaItem,
																							label: intl.formatMessage({
																								id: 'nav.volunteer.area.' + areaItem
																							})
																						}
																					})
																				}
																			}),
																		...item.thana
																			.filter(thanaItem => {
																				return typeof thanaItem === 'string'
																			})
																			.map(thanaItem => {
																				return {
																					value: thanaItem,
																					label: intl.formatMessage({
																						id: 'nav.volunteer.thana.' + thanaItem
																					})
																				}
																			})
																	]
																}
															})}
															// size={'large'}
															placeholder={intl.formatMessage({
																id: 'nav.volunteer.form.distAndThana'
															}) + '*'}
														/>
													</Form.Item>
													<Form.Item hasFeedback
														name='presentAddress'
														// label={intl.formatMessage({
														// 	id: 'nav.volunteer.formAddress'
														// })}
														rules={[
															{
																required: true,
																min: 10,
																max: 500,
																message: intl.formatMessage({
																	id: 'nav.volunteer.formAddress.errorMessage'
																}),
																whitespace: true
															}
														]}
													>
														<Input.TextArea
															rows={2}
															// size='large'
															placeholder={intl.formatMessage({
																id: 'nav.volunteer.formAddress'
															}) + '*'}
														/>
													</Form.Item>
												</>
											) : null}
										</>
										<div className='d-flex justify-content-between'>
											<Button
												// type='primary'
												// size='large'
												onClick={() => onPrevious()}
												// className='gx-mb-0'
												style={{
													backgroundColor: '#fff',
													color: '#000',
												}}
											>
												<IntlMessages id='nav.form.previous' />
											</Button>
											<Button
												type='primary'
												// size='large'
												htmlType='submit'
												// className='gx-mb-0'
												style={{
													backgroundColor: '#EE0000',
													color: '#ffffff',
												}}
											>
												<IntlMessages id='nav.form.next' />
											</Button>

										</div>
									</Form>
								</Col>
							</Row>
						</div>
						<div className='d-none d-lg-block col-lg-6 col-xl-7 display-1 text-white pt-5 ps-5'>
							Dont Blame Luck
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(AddressInfo)
