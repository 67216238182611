import { injectIntl } from 'react-intl'
import BelowSliderSection from '../../components/BelowSliderSection'
import Bannercrumb from '../../util/banner/Bannercrumb'

const Contact = ({ intl }) => {
	return (
		<>
			<Bannercrumb bannerId='contact' />
			<div className='container-fluid'>
				<div
					className='row'
					style={{
						border: '2px solid #7dd078'
						// borderRight: '2px solid lightgrey'
					}}
				>
					<div className='col-md-6 ps-0 pe-0'>
						<div
							className='card card-bene bg-dark text-white backface border-0'
							style={{ border: '1px solid #78d673', borderRadius: '0' }}
						>
							<img
								src='https://cdn.pixabay.com/photo/2014/11/13/06/12/boy-529067_1280.jpg'
								alt='Stony Beach'
							/>
							<div className='card-img-overlay'>
								<h6
									style={{
										position: 'absolute',
										textAlign: 'center',
										bottom: '1.25rem',
										fontSize: '1rem',
										color: 'white'
									}}
								>
									Ayman
								</h6>
								<p
									style={{
										position: 'absolute',
										textAlign: 'center',
										bottom: '0.1rem',
										color: '#7dd078',
										fontSize: '0.75rem'
									}}
								>
									Children
								</p>
							</div>
						</div>
					</div>
					<div className='col-md-6 d-flex justify-content-center align-items-center position-relative'>
						<div className='card position-absolute' style={{ width: '95%' }}>
							<div className='card-body'>
								<div className='row'>
									<h2 className='card-subtitle mb-4 text-muted col-md-8 offset-md-4'>
										{intl.formatMessage({ id: 'nav.contact.formTitle' })}
									</h2>
								</div>
								<form>
									<div className='form-group row mb-3'>
										<label className='col-md-3 col-form-label  text-end'>
											{intl.formatMessage({ id: 'nav.contact.formName' })}
										</label>
										<div className='col-md-8'>
											<input type='text' className='form-control' />
										</div>
									</div>
									<div className='form-group row mb-3'>
										<label className='col-md-3 col-form-label text-end'>
											{intl.formatMessage({ id: 'nav.contact.formEmail' })}
										</label>
										<div className='col-md-8'>
											<input type='text' className='form-control' />
										</div>
									</div>
									<div className='form-group row mb-3'>
										<label className='col-md-3 col-form-label text-end'>
											{intl.formatMessage({ id: 'nav.contact.formSubject' })}
										</label>
										<div className='col-md-8'>
											<input type='text' className='form-control' />
										</div>
									</div>
									<div className='form-group row mb-3'>
										<label className='col-md-3 col-form-label text-end'>
											{intl.formatMessage({ id: 'nav.contact.formMessage' })}
										</label>
										<div className='col-md-8'>
											<textarea
												className='form-control'
												id='exampleFormControlTextarea1'
												rows='5'
											></textarea>
										</div>
									</div>
									<div className='row'>
										<div className='col-md-3 offset-md-8 position-relative'>
											<button type='submit' className='btn btn-success w-100'>
												{intl.formatMessage({
													id: 'nav.contact.formSendButton'
												})}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<BelowSliderSection />
		</>
	)
}

export default injectIntl(Contact)
