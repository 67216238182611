import React, { Component } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import '../slider/PartnerSlider.css'

export default class SimpleSlider extends Component {
	constructor(props) {
		super(props)
		this.play = this.play.bind(this)
		this.pause = this.pause.bind(this)
	}
	play() {
		this.slider.slickPlay()
	}
	pause() {
		this.slider.slickPause()
	}
	render() {
		const settings = {
			dots: false,
			infinite: true,
			slidesToShow: 6,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 5,
					}
				},
				{
					breakpoint: 624,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
					}
				}
			]
		};
		return (
			<div className="slider">
				<Slider ref={slider => (this.slider = slider)} {...settings}>
					<div>
						<img src="images/1.png"></img>
					</div>
					<div>
						<img src="images/2.png"></img>
					</div>
					<div>
						<img src="images/6.png"></img>
					</div>
					<div>
						<img src="images/4.png"></img>
					</div>
					<div>
						<img src="images/5.png"></img>
					</div>
					<div>
						<img src="images/6.png"></img>
					</div>
					<div>
						<img src="images/1.png"></img>
					</div>
					<div>
						<img src="images/2.png"></img>
					</div>
					<div>
						<img src="images/6.png"></img>
					</div>
					<div>
						<img src="images/4.png"></img>
					</div>
					<div>
						<img src="images/5.png"></img>
					</div>
					<div>
						<img src="images/6.png"></img>
					</div>
				</Slider>
			</div>
		)
	}
}