// import { GiftOutlined } from '@ant-design/icons/lib/icons'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { BiDonateHeart, BiLogInCircle } from 'react-icons/bi'
import { FaRegAddressBook } from 'react-icons/fa'
import '../App.css'
import Topbar from './Topbar'
// import { Link } from 'react-router-dom' register-banner login-banner
import { injectIntl } from 'react-intl'

const Header = ({ intl }) => {
	return (
		<Navbar expand='lg'>
			<Container fluid className='py-3'>
				<LinkContainer to='/'>
					<Navbar.Brand>
						{/* <div className='d-flex py-1'>
							<img src={logo} className='App-logo' alt='logo' />
						</div> */}

						<img
							className='App-logo'
							src='/images/nmp-logo.svg'
							width='auto'
							alt='No More Poverty'
						/>
					</Navbar.Brand>
				</LinkContainer>
				<LinkContainer to='/register'>
					<Navbar.Brand className='header-register'>
						<span className='lead'>
							<FaRegAddressBook />
						</span>{' '}
						{intl.formatMessage({ id: 'register-banner' })}
					</Navbar.Brand>
				</LinkContainer>
				<LinkContainer to='/beneficiaryrepayment'>
					<Navbar.Brand className='header-register'>
						<span className='lead'>
							<BiLogInCircle />
						</span>{' '}
						{intl.formatMessage({ id: 'beneficiaryrepayment' })}
					</Navbar.Brand>
				</LinkContainer>
				<LinkContainer to='/donation'>
					<Navbar.Brand className='header-register'>
						<span className='lead'>
							<BiDonateHeart />
						</span>{' '}
						{intl.formatMessage({ id: 'donation-banner' })}
					</Navbar.Brand>
				</LinkContainer>

				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='ms-auto header'>
						<LinkContainer to='/donation/general'>
							<Nav.Link>
								{intl.formatMessage({ id: 'general' })}
							</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/donation/zakat'>
							<Nav.Link>{intl.formatMessage({ id: 'zakat' })}</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/donation/qarzehasana'>
							<Nav.Link>{intl.formatMessage({ id: 'qarzehasana' })}</Nav.Link>
						</LinkContainer>

						{/* <LinkContainer to='/corporate'>
							<Nav.Link>Corporate</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/disasters'>
							<Nav.Link>Disasters</Nav.Link>
						</LinkContainer>
						<LinkContainer to='/giftcards'>
							<Nav.Link>
								<span className='gift'>
									<i className='bi bi-gift'></i> Gift Cards
								</span>
							</Nav.Link>
						</LinkContainer> */}
					</Nav>
				</Navbar.Collapse>
				{/* <LinkContainer to='/'>
					<Navbar.Brand className='cart'>
						<i className='bi bi-cart4'></i>
					</Navbar.Brand>
				</LinkContainer> */}
				<Topbar />
			</Container>
		</Navbar>
	)
}
export default injectIntl(Header)
