import {
	Button, Col,
	DatePicker, Form,
	Input, message, Modal, Row, Table
} from 'antd'
import { useState } from 'react'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

import { BsBuilding } from 'react-icons/bs'

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }

const Training = ({ onChange, previousValue, intl }) => {
	const [trainingInformation] = Form.useForm()
	const [experienceFields, setExperienceFields] = useState(
		previousValue ? previousValue : []
	)
	const [isUpdateExperienceFields, setIsUpdateExperienceFields] = useState(
		undefined
	)
	//const [isExperienceFieldsOpen, setIsExperienceFieldsOpen] = useState(false)

	const [index, setIndex] = useState(undefined)
	// const [minToValue, setMinToValue] = useState(undefined)
	// const [minWorkToValue, setMinWorkToValue] = useState(undefined)
	const [visible, setVisible] = useState(false)

	const today = new Date()
	var day = today.getDate()
	day = (day < 10 ? '0' : '') + day
	var month = today.getMonth() + 1
	month = (month < 10 ? '0' : '') + month
	var year = today.getFullYear() + ''
	const todayString = `${year}-${month}-${day}`

	if (previousValue) {
		console.log(previousValue)
	}

	const onSubmit = values => {
		if (experienceFields.length === 0) {
			message.error('Please add at least one training field')
		} else {
			onChange(7, experienceFields)
		}
	}

	const onPrevious = () => {
		onChange(5)
	}

	const onCancel = () => {
		setVisible(false)
	}

	const onSubmitVolunteerExperience = values => {
		if (!isUpdateExperienceFields) {
			var temp2 = experienceFields
			temp2.push(values)
			setExperienceFields(temp2)
		} else if (isUpdateExperienceFields) {
			experienceFields.splice(index, 1, values)
			setExperienceFields(experienceFields)
			setIsUpdateExperienceFields(false)
		}
		setVisible(false)
	}

	// const onChangeExperienceValues = (changedValues, allValues) => {
	// 	if (changedValues.from_) {
	// 		setMinToValue(changedValues.from_)
	// 	}
	// }

	var columns = [
		{
			title: intl.formatMessage({
				id: 'nav.volunteer.experience.organisation.name'
			}),
			// width: 150,
			dataIndex: 'organisationName_',
			key: 'names'
		},
		// {
		// 	title: intl.formatMessage({ id: 'nav.volunteer.experience.from.date' }),
		// 	width: 150,
		// 	dataIndex: 'from_',
		// 	key: 'email'
		// },
		// {
		// 	title: intl.formatMessage({ id: 'nav.volunteer.experience.to.date' }),
		// 	width: 100,
		// 	dataIndex: 'to_',
		// 	key: 'account'
		// },
		// {
		// 	title: intl.formatMessage({ id: 'nav.volunteer.experience.discription' }),
		// 	width: 150,
		// 	dataIndex: 'summary_',
		// 	key: 'dept'
		// },
		{
			title: 'Action',
			// width: 100,
			dataIndex: '_id',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							setIsUpdateExperienceFields(true)
							setVisible(true)
							trainingInformation.setFieldsValue(experienceFields[index])
							setIndex(index)
						}}
					>
						<IntlMessages id='nav.volunteer.button.edit' />
					</span>
				)
			}
		},
		{
			// fixed: 'right',
			// width: 100,
			dataIndex: 'active',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							var temp1 = [...experienceFields]
							temp1.splice(index, 1)
							setExperienceFields(temp1)
							if (experienceFields.length === 1) {
								trainingInformation.resetFields()
							}
						}}
					>
						<IntlMessages id='nav.volunteer.button.delete' />
					</span>
				)
			}
		}
	]

	const trainingStyle = {
		backgroundImage: 'url(images-reg/7.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	}

	return (
		<>
			<div style={trainingStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-lg-6 col-xl-5 bg-white p-5 my-2 rounded-3'>
							<div>
								{/* work info */}
								<Row style={{ marginBottom: '1rem' }}>
									<Col xl={24} lg={24} md={24} sm={24} xs={24}>
										<h1
											style={{
												fontWeight: 500,
												color: '#535353',
												fontSize: '2rem',
												lineHeight: '2rem',
												wordWrap: 'breakWord'
											}}
										>
											<IntlMessages id='nav.volunteer.experience.formTitle' />
										</h1>
									</Col>
									<Col
										xl={24}
										lg={24}
										md={24}
										sm={24}
										xs={24}
										style={{ textAlign: 'center' }}
									>
										{experienceFields.length !== 0 ? (
											<div>
												<Table
													columns={columns}
													dataSource={experienceFields.map(item => item)}
													pagination={false}
													scroll={{ x: 300 }}
												/>
												<Button
													type='primary'
													onClick={() => {
														setVisible(true)
														trainingInformation.resetFields()
													}}
													style={{ marginBottom: 0, marginTop: 10 }}
												>
													<IntlMessages id='nav.volunteer.add.more.button' />
												</Button>
											</div>
										) : (
											<Button
												type='primary'
												size='large'
												onClick={() => {
													setVisible(true)
													//setIsEducationFieldsOpen(true)
													trainingInformation.resetFields()
												}}
												className='gx-mb-0'
												style={{
													backgroundColor: '#EE0000',
													color: '#ffffff',
													width: '50%'
													// marginBottom: '50px'
												}}
											>
												<IntlMessages id='nav.form.add' />
											</Button>
										)}
									</Col>
								</Row>
								<Modal
									title={intl.formatMessage({
										id: 'nav.volunteer.experience.formTitle'
									})}
									visible={visible}
									onOk={trainingInformation.submit}
									onCancel={onCancel}
									width='35vw'
									centered={true}
								// bodyStyle={{
								// 	height: '620px'
								// }}
								>
									<Form
										// {...formItemLayout}
										form={trainingInformation}
										name='volunteerWorkExperienceForm'
										onFinish={onSubmitVolunteerExperience}
										// onValuesChange={onChangeExperienceValues}
										style={{ margineTop: 30 }}
									>
										<div>
											<Form.Item hasFeedback
												name='organisationName_'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.experience.organisation.name'
												// })}
												rules={[
													{
														required: true,
														min: 5,
														max: 500,
														message: intl.formatMessage({
															id:
																'nav.volunteer.experience.organisation.name.errorMessage'
														}),
														whitespace: true
													}
												]}
											>
												<Input size='large' style={{ width: '100%' }}
													prefix={<BsBuilding />}
													placeholder={intl.formatMessage({
														id: 'nav.volunteer.experience.organisation.name'
													}) + '*'} />
											</Form.Item>
											<Form.Item
												name='topic_'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.experience.topic'
												// })}
												rules={[
													{
														required: true,
														min: 5,
														max: 500,
														message: intl.formatMessage({
															id: 'nav.volunteer.experience.topic.errorMessage'
														}),
														whitespace: true
													}
												]}
											>
												<Input size='large' style={{ width: '100%' }}
													placeholder={intl.formatMessage({
														id: 'nav.volunteer.experience.topic'
													}) + '*'} />
											</Form.Item>
											<Form.Item
												name='from_'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.experience.from.date'
												// })}
												rules={[
													{
														required: true,
														message: intl.formatMessage({
															id: 'nav.volunteer.experience.from.date.errorMessage'
														})
													}
												]}
											>
												<DatePicker
													placeholder={intl.formatMessage({
														id: 'nav.volunteer.experience.from.date'
													}) + '*'}
													disabledDate={d => !d || d.isAfter(todayString)}
													style={{
														width: '100%',
													}}
												/>
											</Form.Item>
											<Form.Item hasFeedback
												name='to_'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.experience.to.date'
												// })}
												rules={[
													{
														required: true,
														message: intl.formatMessage({
															id: 'nav.volunteer.experience.to.date.errorMessage'
														})
													}
												]}
											>
												{/* <Input
													size='large'
													type='date'
													// min={minWorkToValue}
													max={todayString}
												/> */}
												<DatePicker
													placeholder={intl.formatMessage({
														id: 'nav.volunteer.experience.to.date'
													}) + '*'}
													disabledDate={d => !d
														|| d.isAfter(todayString)}
													style={{
														width: '100%',
													}}
												/>
											</Form.Item>
											<Form.Item hasFeedback
												name='discription_'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.experience.discription'
												// })}
												rules={[
													{
														required: true,
														min: 10,
														max: 10000,
														message: intl.formatMessage({
															id: 'nav.volunteer.experience.summary.errorMessage'
														}),
														whitespace: true
													}
												]}
											>
												<Input.TextArea rows={4} size='large'
													placeholder={intl.formatMessage({
														id: 'nav.volunteer.experience.discription'
													}) + '*'} />
											</Form.Item>
										</div>
									</Form>
								</Modal>
							</div>
							<div className='d-flex justify-content-between'>
								<Button
									// type='primary'
									size='large'
									onClick={() => onPrevious()}
									className='gx-mb-0'
									style={{
										backgroundColor: '#fff',
										color: '#000',
										//width: '100%'
										float: 'left'
									}}
								>
									<IntlMessages id='nav.form.previous' />
								</Button>
								<Button
									type='primary'
									size='large'
									onClick={() => onSubmit()}
									className='gx-mb-0'
									style={{
										backgroundColor: '#EE0000',
										color: '#ffffff',
										//width: '100%'
										float: 'left'
									}}
								>
									<IntlMessages id='nav.form.next' />
								</Button>

							</div>
						</div>
						<div className='d-none d-lg-block col-lg-6 col-xl-7 display-1 text-white pt-5 mt-3 ps-5'>
							Never Fear
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(Training)
