import React from 'react'
import Buttons from '../button/Buttons'
import Counter from '../counter/Counter'
import RoundBtn from '../roundBtn/RoundBtn'
import '../sadakah/Sadakah.css'
// import { FaCheck } from 'react-icons/fa';
import Imgprofile from '../imgprofile/Imgprofile'
import { SadakahData } from '../../../data/SadakahData'

// import IntlMessages from '../../../util/IntlMessages'
import { injectIntl } from 'react-intl'

const Sadakah = ({ intl }) => {

	const myStyle = {
		backgroundImage: "url(images/bangladesh.svg), url(images/wave.svg), url(images/left-circle.svg)",
		backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
		backgroundPosition: 'left top, left bottom, right bottom -600px',
		backgroundSize: '560px auto, 150px auto, auto 800px',
	}

	// console.log(SadakahData);

	return (
		<div className="sadakah" style={myStyle} >
			<div className="container">
				<div className="row gy-5 gy-lg-0">
					<div className="col-lg-5 col-sm-12">
						<div className="info-wrap">
							{/* <h1 dangerouslySetInnerHTML={{ __html: SadakahData[0].title }}></h1> */}
							<h1>{intl.formatMessage({id:'sadakah.heading'})}</h1>

							<Counter district={SadakahData[0].district} thana={SadakahData[0].thana} child={SadakahData[0].children}></Counter>

							{/* <p className="short-desc" dangerouslySetInnerHTML={{ __html: SadakahData[0].shortDesc }}><IntlMessages id={sadakah.description} /></p> */}
							<p className="short-desc">{intl.formatMessage({ id: 'Sadakah.description' })}</p>
							<div className="donantion-btn-group">
								<Buttons></Buttons>
								<RoundBtn></RoundBtn>
							</div>
						</div>
					</div>
					<div className="col-lg-7 col-sm-12">
						<div className="img-wrap">
							<img className="big-img" src="images/children.svg" />

							<Imgprofile margin={{ marginTop: "-110px" }}></Imgprofile>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default injectIntl(Sadakah)

