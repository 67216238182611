import React from 'react'
import '../counter/Counter.css'

import { injectIntl } from 'react-intl'

// const Sadakah = ({ intl }) => {

const Counter = ({ intl, margin, district, thana, child }) => {

	// const m = props.margin;


	return (
		<div className="counter" style={margin}>
			<div>
				<h3>{district}</h3>
				<span>{intl.formatMessage({ id: 'district' })}</span>
			</div>
			<div>
				<h3>{thana}</h3>
				<span>{intl.formatMessage({ id: 'thana' })}</span>
			</div>
			<div>
				<h3>{child}</h3>
				<span>{intl.formatMessage({ id: 'child' })}</span>
			</div>
		</div>
	)
}

export default injectIntl(Counter)
