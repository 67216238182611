import React from 'react'

function Personal() {
	return (
		<div className="row row-cols-1 row-cols-md-3 g-4">
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Personal Information</h5>
						<p className="card-text lh-1">Name: Abdul Majid</p>
						<p className="card-text lh-1">Email: ab@gmail.com</p>
						<p className="card-text lh-1">Phone: +8801789123456</p>
						<p className="card-text lh-1">Date of Birth: Jan 01, 1971</p>
						<p className="card-text lh-1">Emergency Phone: +8801789123457</p>
						<p className="card-text lh-1">NID: 1234567890</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Education</h5>
						<p className="card-text lh-1">Degree: HSC</p>
						<p className="card-text lh-1">Group: Science</p>
						<p className="card-text lh-1">Passing Year: 2000</p>
						<p className="card-text lh-1">Institution: Dhaka College</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Job Experience</h5>
						<p className="card-text lh-1">Organization: College</p>
						<p className="card-text lh-1">Designation: Teacher</p>
						<p className="card-text lh-1">From: Jan 01, 1971</p>
						<p className="card-text lh-1">To: Jan 01, 1972</p>
						<p className="card-text lh-1">Responsibilities: Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, fugit?</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Occupation</h5>
						<p className="card-text lh-1">Occupation: Teacher</p>
						<p className="card-text lh-1">Organization: College</p>
						<p className="card-text lh-1">Summary: Teacher</p>
						<p className="card-text lh-1">Monthly Income: Jan 01, 1971</p>
						<p className="card-text lh-1">Net Land Property: 1 acre</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Address</h5>
						<h6 className="card-subtitle mb-2 text-muted">Present Address</h6>
						<p className="card-text lh-1">District: Dhaka</p>
						<p className="card-text lh-1">Thana: New Market</p>
						<p className="card-text lh-1">Address: Road:10, House:2</p>
						<h6 className="card-subtitle mb-2 text-muted">Permanent Address</h6>
						<p className="card-text lh-1">District: Bhola</p>
						<p className="card-text lh-1">Thana: Lalmohan</p>
						<p className="card-text lh-1">Address: Road:10, House:20</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Family</h5>
						<p className="card-text lh-1">Family Type: Nuclear</p>
						<p className="card-text lh-1">Relationship: Mother</p>
						<p className="card-text lh-1">Name: Rahima</p>
						<p className="card-text lh-1">Presence: Alive</p>
						<p className="card-text lh-1">Date of Birth: Jan 01, 1971</p>
						<p className="card-text lh-1">Occupation: Teacher</p>
						<p className="card-text lh-1">Monthly Income: Jan 01, 1971</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Training</h5>
						<p className="card-text lh-1">Organization: College</p>
						<p className="card-text lh-1">Topic: Education</p>
						<p className="card-text lh-1">From: Jan 01, 1971</p>
						<p className="card-text lh-1">To: Jan 01, 1972</p>
						<p className="card-text lh-1">Description: Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque, fugit?</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Reference</h5>
						<p className="card-text lh-1">Name: Abdul Majid</p>
						<p className="card-text lh-1">Occupation: Teacher</p>
						<p className="card-text lh-1">Phone: +8801789123457</p>
						<p className="card-text lh-1">Address: Road:10, House:2, Thana:New Market, District: Dhaka</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card h-100">
					<div className="card-body">
						<h5 className="card-title">Purpose</h5>
						<p className="card-text lh-1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id quis earum eveniet, distinctio fuga placeat quibusdam nisi sit error tempora.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Personal