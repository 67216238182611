import React from 'react'
import { Button } from 'react-bootstrap'
import './Buttons.css'
import { injectIntl } from 'react-intl'

const Buttons = ({ intl }) => {
	return (
		<Button className="btn-big"
			style={{ backgroundColor: '#2aa457', border: 0, boxShadow: '0 0 20px rgba(0, 64, 25, 0.3)' }}>
			{intl.formatMessage({ id: 'donate.now' })}
		</Button>
	)
}

export default injectIntl(Buttons)
