import { useState } from 'react'
import './bene-detail.css'

import { useSelector } from 'react-redux'
import Donate from './Donate'

import About from './About'
import DonateEntry from './DonateEntry'
import Profile from './Profile'
// import DonationForm from './DonationForm'

import { injectIntl } from 'react-intl'

const BeneficiaryProfileDetail = ({ intl }) => {
	const donate = useSelector(state => state.beneficiaries.donate)
	const profile = useSelector(state => state.beneficiaries.profile)

	const [gallery, setGallery] = useState(true)
	const [analysis, setAnalysis] = useState(false)
	const handleGallery = () => {
		setGallery(true)
		setAnalysis(false)
	}

	const handleAnalysis = () => {
		setGallery(false)
		setAnalysis(true)
	}

	return (
		<div className='h-100 position-relative'>
			{!donate && (
				<div className='pb-3 px-1 pt-1'>
					<button
						onClick={() => handleGallery()}
						className={gallery ? 'btn btn-new' : 'btn btn-gallery'}
					>
						{intl.formatMessage({ id: 'profile' })}
					</button>
					<button
						onClick={() => handleAnalysis()}
						className={analysis ? 'btn btn-new' : 'btn btn-analysis'}
					>
						{intl.formatMessage({ id: 'about' })}
					</button>
				</div>
			)}

			{!donate && (
				<div>
					{gallery && <Profile profile={profile} />}
					{analysis && <About profile={profile} />}
				</div>
			)}
			{donate && !profile.paid && (
				<div className='donate-entry-wrap w-100 h-100'>
					<DonateEntry />
				</div>
			)}

			{/* {donate && <DonateEntry />} */}

			{!donate && !profile.paid && (
				<div
					className='conatiner-fluid w-100 position-rel'
					style={{ bottom: '5px' }}
				>
					<div className='row g-0'>
						<div className='col'>
							<Donate />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default injectIntl(BeneficiaryProfileDetail)
