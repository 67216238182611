export default function Beneficiary({ beneficiary }) {
	return (
		<div
			className='card card-bene bg-dark text-white backface'
			style={{ border: '1px solid #78d673', borderRadius: '0' }}
		>
			<img src={beneficiary.src} alt='Stony Beach' />
			<div className='card-img-overlay'>
				<h6
					style={{
						position: 'absolute',
						textAlign: 'center',
						bottom: '1.25rem',
						fontSize: '1rem',
						color: 'white'
					}}
				>
					{beneficiary.name}
				</h6>
				<p
					style={{
						position: 'absolute',
						textAlign: 'center',
						bottom: '0.1rem',
						color: '#7dd078',
						fontSize: '0.75rem'
					}}
				>
					{/* {beneficiary.occupationLang} */}
				</p>
			</div>
		</div>
	)
}

// Beneficiary.propTypes = {
// 	name: PropTypes.string,
// 	occupation: PropTypes.string,
// 	src: PropTypes.string
// }
