import React from 'react'
import '../activities/Activities.css'
import { injectIntl } from 'react-intl'

const Activities = ({ intl }) => {

	const myStyle = {
		backgroundImage: 'url(images/right-circle.svg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'left bottom -100px',
		backgroundSize: 'auto 700px',
	}

	return (
		<div className="activities" style={myStyle}>
			<div className='container-lg container-fluid'>
				<div className='row gy-lg-0 gy-5'>
					<div className='col-lg-6 col-sm-12 mt-auto mb-auto'>
						<div className="service-wrap">
							<div className="row gy-5">
								<div className="col-6 d-flex">
									<span><img className="icon" src="images/poor.svg"></img></span>
									<div className="info">
										<h1>9,852 k</h1>
										<span>{intl.formatMessage({ id: 'poor.people' })}</span>
									</div>
								</div>
								<div className="col-6 d-flex">
									<span><img className="icon" src="images/donation.svg"></img></span>
									<div className="info">
										<h1>9,852 k</h1>
										<span>{intl.formatMessage({ id: 'fund.raised' })}</span>
									</div>
								</div>
								<div className="col-6 d-flex">
									<span><img className="icon" src="images/doner.svg"></img></span>
									<div className="info">
										<h1>9,852 k</h1>
										<span>{intl.formatMessage({ id: 'active.donors' })}</span>
									</div>
								</div>
								<div className="col-6 d-flex">
									<span><img className="icon" src="images/volunteer.svg"></img></span>
									<div className="info">
										<h1>9,852 k</h1>
										<span>{intl.formatMessage({ id: 'active.volunteers' })}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-lg-6 col-sm-12'>
						<img className="worldwide-doner" src="images/world-map.svg"></img>
					</div>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(Activities)
