import {
	Button, Cascader, Col, Form,
	Input, message,
	Modal,
	Row, Table
} from 'antd'
import { useState } from 'react'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

import { AiOutlineUser } from 'react-icons/ai'

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }

const phoneRegex = new RegExp(/(^(\+88)?(01){1}[3456789]{1}(\d){8})$/)

const Reference = ({ onChange, previousValue, intl }) => {
	const [referenceInformation] = Form.useForm()
	const [index, setIndex] = useState(undefined)
	const [referenceFields, setReferenceFields] = useState(
		previousValue ? previousValue : []
	)
	const [isUpdateReferenceFields, setIsUpdateReferenceFields] = useState(
		undefined
	)
	//const [isReferenceFieldsOpen, setIsReferenceFieldsOpen] = useState(false)
	const [visible, setVisible] = useState(false)

	const occupationList = [
		'IMAAM-KHATIB',
		'MUAZZIN',
		'DOCTOR',
		'TEACHER',
		'WRITER',
		'FARMER',
		'ENGINEER',
		'LAWYER',
		'BUSINESS-PERSON',
		'COMPUTER-PROGRAMMER',
		'CRAFTER-HANDICRAFTER',
		'JOURNALIST',
		'SOCIAL-WORKER',
		'GRAPHICS-DESIGNER',
		'BANKER',
		'STUDENT',
		'HOUSE-WIFE',
		'GOVT-EMPLOYEE',
		'NON-GOVT-EMPLOYEE',
		'JOB-LESS',
		'OTHERS'
	]

	// const handleOccupationChange = value => {
	// 	if (value[0] === 'JOB-LESS' || value[0] === 'HOUSE-WIFE') {
	// 		setIsJobDescriptionField(false)
	// 	} else {
	// 		setIsJobDescriptionField(true)
	// 	}
	// }

	if (previousValue) {
		//setEducationFields(previousValue)
		//educationInformation.setFieldsValue(previousValue)
		console.log(previousValue)
	}

	const onSubmitReferenceInfo = values => {
		if (!isUpdateReferenceFields) {
			var temp2 = referenceFields
			temp2.push(values)
			setReferenceFields(temp2)
		} else if (isUpdateReferenceFields) {
			referenceFields.splice(index, 1, values)
			setReferenceFields(referenceFields)
			setIsUpdateReferenceFields(false)
		}
		setVisible(false)
	}

	const handleCancel = () => {
		setVisible(false)
	}

	const onSubmit = () => {
		if (
			!referenceFields.find(item => item.occupation[0] === 'IMAAM-KHATIB') ||
			referenceFields.length === 1
		) {
			message.error(
				'Reference of local IMAAM-KHATIB and another reference must required'
			)
		} else {
			onChange(8, referenceFields)
		}
	}

	const onPrevious = () => {
		onChange(6)
	}

	var columns = [
		{
			title: <IntlMessages id='nav.volunteer.formName' />,
			// width: 150,
			dataIndex: 'name',
			key: 'names'
		},
		{
			title: 'Action',
			// width: 100,
			dataIndex: '_id',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							setIsUpdateReferenceFields(true)
							setVisible(true)
							referenceInformation.setFieldsValue(referenceFields[index])
							setIndex(index)
						}}
					>
						<IntlMessages id='nav.volunteer.button.edit' />
					</span>
				)
			}
		},
		{
			dataIndex: 'active',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							var temp1 = [...referenceFields]
							temp1.splice(index, 1)
							setReferenceFields(temp1)
							if (referenceFields.length === 1) {
								referenceInformation.resetFields()
							}
						}}
					>
						<IntlMessages id='nav.volunteer.button.delete' />
					</span>
				)
			}
		}
	]

	const referenceStyle = {
		backgroundImage: 'url(images-reg/8.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	}

	return (
		<>
			<div style={referenceStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='d-none d-lg-block col-lg-6 col-xl-7 display-2 text-end text-white pt-5'>
							Hard Work &amp; Hard Work
						</div>
						<div className='col-lg-6 col-xl-5 bg-white p-5 rounded-3 my-2'>
							<Row style={{ marginBottom: '1rem' }}>
								{/* education info */}
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<h1
										style={{
											color: '#535353',
											fontWeight: 500,
											fontSize: '2rem',
											lineHeight: '2rem',
											wordWrap: 'breakWord'
										}}
									>
										<IntlMessages id='nav.volunteer.reference.formTitle' />
									</h1>
								</Col>
								<Col
									xl={24}
									lg={24}
									md={24}
									sm={24}
									xs={24}
									style={{ textAlign: 'center' }}
								>
									{referenceFields.length !== 0 ? (
										<div>
											<Table
												columns={columns}
												dataSource={referenceFields.map(item => item)}
												pagination={false}
											// scroll={{ x: 500 }}
											/>
											<Button
												type='primary'
												size='large'
												onClick={() => {
													setVisible(true)
													referenceInformation.resetFields()
												}}
												style={{ marginBottom: 0, marginTop: 10 }}
											>
												<IntlMessages id='nav.volunteer.add.more.button' />
											</Button>
										</div>
									) : (
										<Button
											type='primary'
											size='large'
											onClick={() => {
												setVisible(true)
												//setIsEducationFieldsOpen(true)
												referenceInformation.resetFields()
											}}
											className='gx-mb-0'
											style={{
												backgroundColor: '#EE0000',
												color: '#ffffff',
												// width: '50%'
												// marginBottom: '50px'
											}}
										>
											<IntlMessages id='nav.form.add.IMAAM-KHATIB' />
										</Button>
									)}
								</Col>
							</Row>
							<Modal
								title={intl.formatMessage({
									id: 'nav.volunteer.reference.formTitle'
								})}
								visible={visible}
								onOk={referenceInformation.submit}
								onCancel={handleCancel}
								width='35vw'
								centered={true}
							// bodyStyle={{
							// 	height: '620px'
							// }}
							>
								<Form
									// {...formItemLayout}
									form={referenceInformation}
									name='referenceInformation'
									onFinish={onSubmitReferenceInfo}
									style={{ margineTop: 30 }}
								>
									<div>
										<Form.Item hasFeedback
											name='name'
											// label={intl.formatMessage({ id: 'nav.volunteer.formName' })}
											rules={[
												{
													required: true,
													min: 5,
													max: 200,
													message: intl.formatMessage({
														id: 'nav.volunteer.formName.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												size='large'
												prefix={<AiOutlineUser />}
												style={{ width: '100%' }}
												placeholder={intl.formatMessage({ id: 'nav.volunteer.formName' }) + '*'}
											/>
										</Form.Item>
										<Form.Item hasFeedback
											name='occupation'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.form.occupation'
											// })}
											rules={[
												{
													required: true,
													message: intl.formatMessage({
														id: 'nav.volunteer.occupation.errorMessage'
													})
												}
											]}
										>
											<Cascader
												options={occupationList.map(item => {
													return {
														value: item,
														label: intl.formatMessage({
															id: 'nav.volunteer.form.occupation.' + item
														})
													}
												})}
												// onChange={handleOccupationChange}
												size={'middle'}
												placeholder={intl.formatMessage({
													id: 'nav.volunteer.form.occupation'
												}) + '*'}
											/>
										</Form.Item>
										<Form.Item hasFeedback
											name='phone'
											// label={intl.formatMessage({ id: 'nav.volunteer.formPhone' })}
											rules={[
												{
													required: true,
													pattern: phoneRegex,
													message: intl.formatMessage({
														id: 'nav.volunteer.formPhone.errorMessage'
													})
												}
											]}
										>
											<Input size='large'
												placeholder={intl.formatMessage({ id: 'nav.volunteer.formPhone' }) + '*'} />
										</Form.Item>
										<Form.Item hasFeedback
											name='address'
											// label={intl.formatMessage({ id: 'nav.volunteer.formAddress' })}
											rules={[
												{
													required: true,
													min: 10,
													max: 1000,
													message: intl.formatMessage({
														id: 'nav.volunteer.formAddress.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input.TextArea
												rows={3}
												size='large'
												placeholder={intl.formatMessage({ id: 'nav.volunteer.formAddress' }) + '*'}
											/>
										</Form.Item>
									</div>
								</Form>
							</Modal>
							<div className='d-flex justify-content-between'>

								<Button
									// type='primary'
									size='large'
									onClick={() => onPrevious()}
									className='gx-mb-0'
									style={{
										backgroundColor: '#fff',
										color: '#000',
										//width: '100%'
										// float: 'left'
									}}
								>
									<IntlMessages id='nav.form.previous' />
								</Button>
								<Button
									type='primary'
									size='large'
									onClick={() => onSubmit()}
									className='gx-mb-0'
									style={{
										backgroundColor: '#EE0000',
										color: '#ffffff',
										//width: '100%'
										// float: 'left'
									}}
								>
									<IntlMessages id='nav.form.next' />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(Reference)
