import { Col, Container, Row } from 'react-bootstrap'
import './profile-analysis.css'

function About({ profile }) {
	console.log(profile)
	return (
		<div className='analysis h-50 position-relative w-100'>
			<Container className='gx-3'>
				<Row className='g-0'>
					<Col>
						<p>{profile.purpose}</p>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default About
