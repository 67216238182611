import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { FaHeart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import './donation.css'
// import Arrow from './../../assets/images/arrow'

import { injectIntl } from 'react-intl'
import Bannercrumb from '../../util/banner/Bannercrumb'
import LoadingBar from 'react-top-loading-bar'
import ProductService from './../../services/Public/Product'
// import { donationData } from './donationData'

const DonationsAll = ({ intl }) => {
	const loadingRef = useRef(null)
	const [products, setProducts] = useState([])
	const language = useSelector(state => state.language.locale)

	useEffect(() => {
		loadingRef.current.continuousStart()
		ProductService.getAll().then(
			res => {
				// console.log(res)
				loadingRef.current.complete()
				if (res.state === 'success') {
					localStorage.setItem('products', JSON.stringify(res.products))
					setProducts(res.products)
				}
			},
			err => {
				loadingRef.current.complete()
				console.error(err)
			}
		)
	}, [])

	const myStyle = {
		backgroundImage:
			'url(images/left-circle.svg), url(images/right-circle.svg)',
		backgroundRepeat: 'no-repeat, no-repeat',
		backgroundPosition: 'right top, left top -600px',
		backgroundSize: '350px auto, auto 700px'
	}

	return (
		<>
			<LoadingBar height={3} color='#0C8F4D' ref={loadingRef} />
			<Bannercrumb bannerId='donation-banner' />
			<div className='news' style={myStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-md-10 offset-md-1 g-0'>
							<div className='row'>
								{products.map((data, id) => {
									// console.log('route', data.routeExtension)
									const name = data.name.find(
										item => item.languageCode === language.locale
									)
									const disc = data.description.find(
										item => item.languageCode === language.locale
									)
									return (
										<div className='col-md-4 col-sm-12' key={id}>
											<Link to={`/donation/${data.routeExtension}`}>
												<div className='card rounded-0 border-0'>
													<img
														src={data.primaryBanner}
														className='card-img-top rounded-0'
														alt='...'
													/>
													<div className='card-body'>
														<p
															style={{
																color: '#111',
																fontSize: '1rem',
																height: '6rem',
																overflow: 'hidden'
															}}
														>
															{disc.text}
														</p>
													</div>
													<div className='card-footer d-flex justify-content-between'>
														<div>
															<img
																className='arrow'
																src='images/arrow.png'
															></img>
															<span className='mx-1'>
																{intl.formatMessage({ id: 'donate' })}
															</span>
														</div>
														<div>
															<Button>
																<FaHeart />
																<span>{data.likeCount}</span>
															</Button>
														</div>
													</div>
													<div className='donation'>
														<span>{name.text}</span>
													</div>
												</div>
											</Link>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(DonationsAll)
