import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
// import { Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import './beneficiarylist.css'

import classNames from 'classnames'
import UiSlice from '../store/UiSlice'
import Beneficiary from './Beneficiary'
import BeneficiaryProfile from './BeneficiaryProfile'
import BeneficiaryProfileDetail from './BeneficiaryProfileDetail'
import NextArrow from './NextArrow'
import PreviousArrow from './PreviousArrow'

import { getAllHerosArch } from '../services/Public/PublicBeneficiaryRequest'
import {
	getAge,
	setLangDonatationItem,
	setLangFields
} from '../util/setFieldValueForBeneficiary'

function BeneficiaryList() {
	// const [getAllBeneficiaries, setGetAllBeneficiaries] = useState([])

	const [beneficiaries, setBeneficiaries] = useState(
		[]
		//useSelector(state => state.beneficiaries.profiles)
	)
	const locale = useSelector(state => state.language.locale)
	//console.log(locale)
	//const beneficiaries = useSelector(state => state.beneficiaries.profiles)
	const profile = useSelector(state => state.beneficiaries.profile)
	const [selectedBeneficiary, setSelectedBeneficiary] = useState(null)
	const animate = useSelector(state => state.ui.animate)
	const dispatch = useDispatch()
	const { setAnimate } = UiSlice.actions
	//console.log(beneficiaries)

	const handleClick = beneficiary => {
		dispatch(setAnimate(!animate))
		setSelectedBeneficiary(beneficiary)
	}
	const settings = {
		className: 'center',
		focusOnSelect: true,
		centerMode: false,
		adaptiveHeight: false,
		infinite: false,
		centerPadding: '0px',
		slidesToShow: 1,
		speed: 500,
		// rows: 2,
		rows: isMobile && !selectedBeneficiary ? 4 : 2,
		// slidesPerRow: 4,
		slidesPerRow: isMobile || selectedBeneficiary ? 2 : 4,
		nextArrow: <NextArrow />,
		prevArrow: <PreviousArrow />
	}
	useEffect(() => {
		fetchHeros()

		async function fetchHeros() {
			const res = await getAllHerosArch()
			if (
				res &&
				res.beneficiaryRequests &&
				res.beneficiaryRequests.length > 0
			) {
				const heros = res.beneficiaryRequests.map((b, i) => {
					b.src = b.photo.url
					b.id = i + 1
					b.age = getAge(b.personal.dob)
					const nb = setLangFields(b, locale.locale)
					if (nb.donationItem) {
						nb.donationItem = setLangDonatationItem(
							nb.donationItem,
							locale.locale
						)
					}

					return nb
				})
				setBeneficiaries(heros)
			}
		}
	}, [])
	useEffect(() => {
		const heros = beneficiaries.map(b => {
			const nb = setLangFields(b, locale.locale)
			if (nb.donationItem) {
				nb.donationItem = setLangDonatationItem(nb.donationItem, locale.locale)
			}
			return nb
		})

		setBeneficiaries(heros)
		if (selectedBeneficiary) {
			const selectedBeneficiaryN = heros.find(
				b => b._id === selectedBeneficiary._id
			)

			if (selectedBeneficiaryN) {
				if (selectedBeneficiaryN.donationItem) {
					selectedBeneficiaryN.donationItem = setLangDonatationItem(
						selectedBeneficiaryN.donationItem,
						locale.locale
					)
				}
				setSelectedBeneficiary(selectedBeneficiaryN)
			}
		}
	}, [locale])
	//console.log(selectedBeneficiary)

	return (
		<div className='container-fluid xyz-in' xyz='fade up big duration-10'>
			<div className='row' style={{ background: '#7dd078' }}>
				{/* <div className='row'> */}
				{selectedBeneficiary && (
					<div className={classNames('col-md-6 gx-0')}>
						<BeneficiaryProfile
							beneficiary={selectedBeneficiary}
							onClose={setSelectedBeneficiary}
						/>
					</div>
				)}

				<div
					className={
						selectedBeneficiary ? 'col-md-6 gx-0 xyz-in' : 'col-md-12 gx-0'
					}
					xyz='fade up big duration-10'
				>
					<div className='h-100'>
						{profile ? (
							<BeneficiaryProfileDetail />
						) : (
							<Slider {...settings}>
								{beneficiaries.map(beneficiary => (
									<div
										className='image-hover'
										onClick={() => handleClick(beneficiary)}
										key={beneficiary.id}
									>
										<Beneficiary beneficiary={beneficiary} />
									</div>
								))}
							</Slider>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default BeneficiaryList
