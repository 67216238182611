import { Button, Col, Form, Input, Row } from 'antd'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }

const Purpose = ({ onChange, previousValue, intl }) => {
	const [purposeInformation] = Form.useForm()

	if (previousValue) {
		purposeInformation.setFieldsValue(previousValue)
		console.log(previousValue)
	}

	const onSubmit = values => {
		onChange(9, values)
	}

	const onPrevious = () => {
		onChange(7)
	}

	const purposeStyle = {
		backgroundImage: 'url(images-reg/9.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	}

	return (
		<>
			<div style={purposeStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-lg-4 bg-white p-5 my-2 rounded-3'>

							<Row>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<h1
										style={{
											color: '#535353',
											fontWeight: 500,
											fontSize: '2rem',
											lineHeight: '2rem',
											wordWrap: 'breakWord'
										}}
									>
										<IntlMessages id='nav.volunteer.purpose.formTitle' />
									</h1>
								</Col>
								<Col
									xl={24}
									lg={24}
									md={24}
									sm={24}
									xs={24}
									style={{ textAlign: 'center' }}
								>
									<Form
										// {...formItemLayout}
										form={purposeInformation}
										name='purposeInformation'
										onFinish={onSubmit}
										style={{ margineTop: 30 }}
									>
										{/* personal info */}
										<div>
											<Form.Item
												name='purpose'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.purpose.formTitle'
												// })}
												rules={[
													{
														required: true,
														min: 10,
														max: 10000,
														message: intl.formatMessage({
															id: 'nav.volunteer.purpose.errorMessage'
														}),
														whitespace: true
													}
												]}
											>
												<Input.TextArea size='large' rows={5} style={{ width: '100%' }}
													placeholder={intl.formatMessage({
														id: 'nav.volunteer.purpose.formTitle'
													}) + '*'} />
											</Form.Item>
											<div style={{ paddingBottom: 10 }}></div>
										</div>
										<div className='d-flex justify-content-between'>

											<Button
												// type='primary'
												size='large'
												onClick={() => onPrevious()}
												className='gx-mb-0'
												style={{
													backgroundColor: '#fff',
													color: '#000',
													//width: '100%'
													// float: 'left'
												}}
											>
												<IntlMessages id='nav.form.previous' />
											</Button>
											<Button
												type='primary'
												size='large'
												htmlType='submit'
												className='gx-mb-0'
												style={{
													backgroundColor: '#EE0000',
													color: '#ffffff',
													//width: '100%'
													// float: 'left'
												}}
											>
												<IntlMessages id='nav.form.done' />
											</Button>
										</div>
									</Form>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(Purpose)
