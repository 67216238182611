import React from 'react'

function Donated({ description, date }) {
	return (
		<div className='d-flex'>
			<div className="p-2 bd-highlight">
				<span className='text-muted'>{description}</span>
				<span style={{color:'#C0C0C0'}}>{date}</span>
			</div>
			{/* <div className="p-2 bd-highlight text-muted">{date}</div> */}
		</div>
	)
}

export default Donated
