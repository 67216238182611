import beneficiaries from '../beneficiaries'

const { createSlice } = require('@reduxjs/toolkit')

const BeneficiarySlice = createSlice({
	name: 'beneficiaries',
	initialState: {
		profiles: beneficiaries,
		profile: null,
		donate: false,
		donationAmount: 0
	},
	reducers: {
		setProfile: (state, action) => {
			state.profile = action.payload.portfolio
		},
		setDonate: (state, action) => {
			state.donate = action.payload
		},
		donationByAmount: (state, action) => {
			state.donationAmount += action.payload.amount
		},
		addBeneficiary: (state, action) => {}
	}
})
export default BeneficiarySlice
