import React from 'react'
import '../roundBtn/RoundBtn.css'
import { Button } from 'react-bootstrap'
import { BrowserRouter as Router } from 'react-router-dom'
import { FaPlay } from 'react-icons/fa'

import { injectIntl } from 'react-intl'

// const Sadakah = ({ intl }) => {

const RoundBtn = ({ intl }) => {
	return (
		<>
			<Button className="btn btn-round" style={{ backgroundColor: '#2aa457', border: 0, boxShadow: '0 0 20px rgba(0, 64, 25, 0.3)' }}>
				<FaPlay /></Button>
			<span className="btn-dsc">
				{intl.formatMessage({ id: 'howitworks' })}
			</span>
		</>
	)
}

export default injectIntl(RoundBtn)
