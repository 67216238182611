/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react'
import './failure.css'
import { useNavigate, useParams } from 'react-router-dom'
import { message } from 'antd'
import { injectIntl } from 'react-intl'
import LoadingBar from 'react-top-loading-bar'

// import IntlMessages from './../../util/IntlMessages'
import UserProductService from '../../services/Public/Product'

const Abort = ({ intl }) => {
	const loadingRef = useRef(null)
	const [loading, setLoading] = useState(false)
	const history = useNavigate()
	const { id } = useParams()

	// useEffect(() => {
	// 	if (id.length !== 24) {
	// 		history.push('/error404')
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	const retry = () => {
		if (!loading) {
			setLoading(true)
			loadingRef.current.continuousStart()
			UserProductService.retryPayment(id).then(
				res => {
					setLoading(false)
					if (res.state === 'success') {
						window.location.href = res.paymentGatewayUrl
					} else {
						message.warning(intl.formatMessage({ id: res.message }), 10)
					}
				},
				err => {
					setLoading(false)
					loadingRef.current.complete()
					console.error(err)
					message.error(intl.formatMessage({ id: err.message }), 10)
				}
			)
		}
	}
	return (
		<div className='container py-5 mt-2'>
			<LoadingBar height={3} color='#7dd078' ref={loadingRef} />
			<div className='row'>
				<div className='col-md-8 offset-md-2 text-center failure-heading'>
					<h1>Transaction Failed</h1>
					<p>
						Sorry, your donation transaction could not be processed. Please try
						again.
					</p>
					<button className='btn btn-success' onClick={retry}>
						Try Again
					</button>
				</div>
			</div>
		</div>
	)
}

export default injectIntl(Abort)
