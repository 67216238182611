import { useState } from 'react'

import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Modal,
	Row,
	Table
} from 'antd'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

import { BsBuilding, BsPencil } from 'react-icons/bs'
import { FcPositiveDynamic } from 'react-icons/fc'

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }

const WorkExperience = ({ onChange, previousValue, intl }) => {
	console.log(previousValue)
	const [workExperienceInformation] = Form.useForm()
	const [workExperienceFields, setWorkExperienceFields] = useState(
		previousValue ? previousValue : []
	)
	const [isUpdateWorkExperienceFields, setIsUpdateWorkExperienceFields] =
		useState(undefined)
	//const [isWorkExperienceFieldsOpen, setIsWorkExperienceFieldsOpen] = useState(false)
	const [index, setIndex] = useState(undefined)
	const [minWorkToValue, setMinWorkToValue] = useState(undefined)
	const [visible, setVisible] = useState(false)
	const [working, setWorking] = useState(false)

	const today = new Date()
	var day = today.getDate()
	day = (day < 10 ? '0' : '') + day
	var month = today.getMonth() + 1
	month = (month < 10 ? '0' : '') + month
	var year = today.getFullYear() + ''
	const todayString = `${year}-${month}-${day}`

	if (previousValue) {
		workExperienceInformation.setFieldsValue(previousValue)
		console.log(previousValue)
	}

	const onCheckBoxChange = () => {
		if (working) {
			setWorking(false)
		} else if (!working) {
			setWorking(true)
		}
	}
	const onSubmit = () => {
		if (workExperienceFields.length === 0) {
			message.error('Please add at least one Job experience field')
		} else {
			onChange(3, workExperienceFields)
		}
	}

	const onPrevious = () => {
		onChange(1)
	}

	const onSubmitVolunteerWorkExperience = values => {
		if (!isUpdateWorkExperienceFields) {
			var temp2 = workExperienceFields
			temp2.push(values)
			setWorkExperienceFields(temp2)
		} else if (isUpdateWorkExperienceFields) {
			workExperienceFields.splice(index, 1, values)
			setWorkExperienceFields(workExperienceFields)
			setIsUpdateWorkExperienceFields(false)
		}
		setVisible(false)
	}

	const handleCancel = () => {
		setVisible(false)
	}

	const onChangeWorkExperienceValues = (changedValues, allValues) => {
		if (changedValues.from_) {
			setMinWorkToValue(changedValues.from_)
		}
	}

	var columns = [
		{
			title: intl.formatMessage({
				id: 'nav.volunteer.experience.organisation.name'
			}),
			// width: 150,
			dataIndex: 'organisationName_',
			key: 'names'
		},
		{
			title: 'Action',
			// colSpan: 2,
			// width: 100,
			dataIndex: '_id',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							setIsUpdateWorkExperienceFields(true)
							workExperienceInformation.setFieldsValue(
								workExperienceFields[index]
							)
							setIndex(index)
							setVisible(true)
						}}
					>
						<IntlMessages id='nav.volunteer.button.edit' />
					</span>
				)
			}
		},
		{
			fixed: 'right',
			// width: 100,
			dataIndex: 'active',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							var temp1 = [...workExperienceFields]
							temp1.splice(index, 1)
							setWorkExperienceFields(temp1)
							if (workExperienceFields.length === 1) {
								workExperienceInformation.resetFields()
							}
						}}
					>
						<IntlMessages id='nav.volunteer.button.delete' />
					</span>
				)
			}
		}
	]

	const workStyle = {
		backgroundImage: 'url(images-reg/3.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	}

	return (
		<>
			<div style={workStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-lg-6 col-xl-5 bg-white p-5 my-2 rounded-3'>
							<Modal
								title={intl.formatMessage({
									id: 'nav.work.experience.formTitle'
								})}
								visible={visible}
								onOk={workExperienceInformation.submit}
								onCancel={handleCancel}
								width='35vw'
								centered={true}
								bodyStyle={{
									maxHeight: '80vh'
								}}
							>
								<Form
									// {...formItemLayout}
									form={workExperienceInformation}
									name='volunteerWorkExperienceForm'
									onFinish={onSubmitVolunteerWorkExperience}
									onValuesChange={onChangeWorkExperienceValues}
									style={{ margineTop: 30 }}
								>
									<div>
										<Form.Item
											hasFeedback
											name='organisationName_'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.experience.organisation.name'
											// })}
											rules={[
												{
													required: true,
													min: 5,
													max: 500,
													message: intl.formatMessage({
														id: 'nav.volunteer.experience.organisation.name.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												size='large'
												style={{ width: '100%' }}
												prefix={<BsBuilding />}
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.experience.organisation.name'
													}) + '*'
												}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='designation_'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.experience.designation'
											// })}
											rules={[
												{
													required: true,
													min: 2,
													max: 200,
													message: intl.formatMessage({
														id: 'nav.volunteer.experience.designation.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												size='large'
												style={{ width: '100%' }}
												prefix={<FcPositiveDynamic />}
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.experience.designation'
													}) + '*'
												}
											/>
										</Form.Item>

										<Form.Item
											hasFeedback
											name='from_'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.experience.from.date'
											// })}
											rules={[
												{
													required: true,
													message: intl.formatMessage({
														id: 'nav.volunteer.experience.from.date.errorMessage'
													})
												}
											]}
										>
											{/* <Input size='large' type='date' max={todayString}
												placeholder={intl.formatMessage({
													id: 'nav.volunteer.experience.from.date'
												})} /> */}
											<DatePicker
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.experience.from.date'
													}) + '*'
												}
												disabledDate={d => !d || d.isAfter(todayString)}
												style={{
													width: '100%'
												}}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											label={intl.formatMessage({
												id: 'jobexperience.info.working'
											})}
										>
											<Checkbox
												onChange={onCheckBoxChange}
												style={{ marginLeft: '10px' }}
											></Checkbox>
										</Form.Item>
										{!working ? (
											<Form.Item
												hasFeedback
												name='to_'
												// label={intl.formatMessage({
												// 	id: 'nav.volunteer.experience.to.date'
												// })}
												rules={[
													{
														required: true,
														message: intl.formatMessage({
															id: 'nav.volunteer.experience.to.date.errorMessage'
														})
													}
												]}
											>
												<DatePicker
													placeholder={
														intl.formatMessage({
															id: 'nav.volunteer.experience.to.date'
														}) + '*'
													}
													disabledDate={d =>
														!d ||
														d.isBefore(minWorkToValue) ||
														d.isAfter(todayString)
													}
													style={{
														width: '100%'
													}}
												/>
											</Form.Item>
										) : null}
										<Form.Item
											hasFeedback
											name='responsibilities_'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.experience.responsibilities'
											// })}
											rules={[
												{
													required: true,
													min: 10,
													max: 10000,
													message: intl.formatMessage({
														id: 'nav.volunteer.experience.summary.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input.TextArea
												rows={3}
												size='large'
												prefix={<BsPencil />}
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.experience.responsibilities'
													}) + '*'
												}
											/>
										</Form.Item>
									</div>
								</Form>
							</Modal>
							{/* work info */}
							<Row style={{ marginBottom: '1rem' }}>
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<h1
										style={{
											// margin: '0 0 30px 0',
											color: '#535353',
											fontWeight: 500,
											fontSize: '2rem',
											lineHeight: '2rem',
											wordWrap: 'breakWord'

											// textAlign: 'center'
										}}
									>
										<IntlMessages id='nav.work.experience.formTitle' />
									</h1>
								</Col>
								<Col
									xl={24}
									lg={24}
									md={24}
									sm={24}
									xs={24}
									style={{ textAlign: 'center' }}
								>
									{workExperienceFields && workExperienceFields.length !== 0 ? (
										<div>
											<Table
												columns={columns}
												dataSource={workExperienceFields.map(item => item)}
												pagination={false}
												// scroll={{ x: 300 }}
											/>
											<Button
												type='primary'
												size='large'
												onClick={() => {
													setVisible(true)
													workExperienceInformation.resetFields()
												}}
												style={{
													float: 'center',
													marginBottom: 0,
													marginTop: 10
												}}
											>
												<IntlMessages id='nav.volunteer.add.more.button' />
											</Button>
										</div>
									) : (
										<Button
											type='primary'
											size='large'
											onClick={() => {
												setVisible(true)
												//setIsEducationFieldsOpen(true)
												workExperienceInformation.resetFields()
											}}
											className='gx-mb-0'
											style={{
												backgroundColor: '#EE0000',
												color: '#ffffff',
												width: '50%'
											}}
										>
											<IntlMessages id='nav.form.add' />
										</Button>
									)}
								</Col>
							</Row>
							<div className='d-flex justify-content-between'>
								<Button
									// type='primary'
									size='large'
									onClick={() => onPrevious()}
									// className='gx-mb-0'
									style={{
										backgroundColor: '#fff',
										color: '#000'
									}}
								>
									<IntlMessages id='nav.form.previous' />
								</Button>
								<Button
									type='primary'
									size='large'
									onClick={() => onSubmit()}
									// className='gx-mb-0'
									style={{
										backgroundColor: '#EE0000',
										color: '#ffffff'
									}}
								>
									<IntlMessages id='nav.form.next' />
								</Button>
							</div>
						</div>
						<div className='d-none d-lg-block col-lg-6 col-xl-7 display-1 text-white pt-3 ps-5'>
							Start Now
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default injectIntl(WorkExperience)
