import classNames from 'classnames'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BeneficiarySlice from '../store/BeneficiarySlice'
import {
	setLangDonatationItem,
	setLangFields
} from '../util/setFieldValueForBeneficiary'

export default function BeneficiaryProfile({ beneficiary, onClose }) {
	const dispatch = useDispatch()
	const locale = useSelector(state => state.language.locale)
	const { setProfile, setDonate } = BeneficiarySlice.actions

	const handleClose = () => {
		dispatch(setProfile({ portfolio: null }))
		onClose(null)
	}

	const handleVisitProfile = () => {
		dispatch(setProfile({ portfolio: beneficiary }))
		dispatch(setDonate(false))
	}
	useEffect(() => {
		if (beneficiary && beneficiary.currentLang !== locale.locale) {
			const beneficiaryTransformed = setLangFields(beneficiary, locale.locale)
			if (beneficiaryTransformed.donationItem) {
				beneficiaryTransformed.donationItem = setLangDonatationItem(
					beneficiaryTransformed.donationItem,
					locale.locale
				)
			}
			dispatch(setProfile({ portfolio: beneficiaryTransformed }))
		}
	}, [locale])

	const animate = useSelector(state => state.ui.animate)
	//onClose(null)
	return (
		<div className='card text-white'>
			<div className='full-image-close' onClick={() => handleClose()}>
				<i className='bi bi-x-lg' aria-hidden='true'></i>
			</div>
			<img
				src={beneficiary.src}
				className={classNames('card-img card-img-full', animate && 'xyz-in')}
				xyz='fade up small duration-10'
				alt='...'
			/>
			<div className='card-img-overlay'>
				<h5
					style={{ paddingTop: '1.25rem', fontSize: '1.7rem', color: 'white' }}
				>
					{beneficiary.name}
				</h5>

				<p style={{ fontSize: '0.75rem', color: '#7dd078' }}>
					{/* {beneficiary.occupationLang} */}
				</p>

				<button
					onClick={() => handleVisitProfile()}
					type='button'
					className='btn btn-success card-btn'
				>
					Visit Profile
				</button>
			</div>
		</div>
	)
}
