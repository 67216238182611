import { useState } from 'react'
import { injectIntl } from 'react-intl'
import BelowSliderSection from '../../components/BelowSliderSection'
import Bannercrumb from '../../util/banner/Bannercrumb'

const About = ({ intl }) => {
	const [tab1, setTab1] = useState(true)
	const [tab2, setTab2] = useState(false)

	const handleTab1 = () => {
		setTab1(true)
		setTab2(false)
	}
	const handleTab2 = () => {
		setTab2(true)
		setTab1(false)
	}
	return (
		<>
			<Bannercrumb bannerId='about' />
			<div className='container-fluid'>
				<div
					className='row'
					style={{
						border: '2px solid #7dd078'
						// borderRight: '2px solid lightgrey'
					}}
				>
					<div className='col-md-6 ps-0 pe-0'>
						<div
							className='card card-bene bg-dark text-white backface border-0'
							style={{ border: '1px solid #78d673', borderRadius: '0' }}
						>
							<img
								src='https://cdn.pixabay.com/photo/2014/11/13/06/12/boy-529067_1280.jpg'
								alt='Stony Beach'
							/>
							<div className='card-img-overlay'>
								<h6
									style={{
										position: 'absolute',
										textAlign: 'center',
										bottom: '1.25rem',
										fontSize: '1rem',
										color: 'white'
									}}
								>
									Ayman
								</h6>
								<p
									style={{
										position: 'absolute',
										textAlign: 'center',
										bottom: '0.1rem',
										color: '#7dd078',
										fontSize: '0.75rem'
									}}
								>
									Children
								</p>
							</div>
						</div>
					</div>
					<div className='col-md-6'>
						<div className='pb-3 px-1 pt-1'>
							<button
								onClick={() => handleTab1()}
								className={tab1 ? 'btn btn-new' : 'btn btn-gallery'}
							>
								Tab1
							</button>
							<button
								onClick={() => handleTab2()}
								className={tab2 ? 'btn btn-new' : 'btn btn-analysis'}
							>
								Tab2
							</button>
						</div>

						<div>
							{tab1 && (
								<p>
									Lorem ipsum dolor, sit amet consectetur adipisicing elit.
									Incidunt, velit suscipit! Impedit hic dolores cumque explicabo
									nihil sint, veniam atque tenetur. Nostrum, doloremque magnam
									facilis aut fugit culpa. Iusto consequatur eaque porro ad
									repellendus laboriosam! Tempore eum sit atque, natus, et
									deserunt sequi, reiciendis aperiam accusamus reprehenderit
									ducimus! Sequi totam illo voluptate accusantium nostrum
									inventore adipisci nulla doloremque! Consequuntur eligendi
									facilis laudantium et, ipsa aliquid eius maxime reiciendis
									accusamus explicabo saepe doloremque rem voluptatibus a at,
									dolorum perspiciatis cum dolore voluptatum. Facilis fuga error
									quae et ea quos, cumque quod unde numquam rerum est facere,
									obcaecati, illo sed quaerat aspernatur.
								</p>
							)}
							{tab2 && (
								<p>
									Lorem ipsum 2 dolor, sit amet consectetur adipisicing elit.
									Incidunt, velit suscipit! Impedit hic dolores cumque explicabo
									nihil sint, veniam atque tenetur. Nostrum, doloremque magnam
									facilis aut fugit culpa. Iusto consequatur eaque porro ad
									repellendus laboriosam! Tempore eum sit atque, natus, et
									deserunt sequi, reiciendis aperiam accusamus reprehenderit
									ducimus! Sequi totam illo voluptate accusantium nostrum
									inventore adipisci nulla doloremque! Consequuntur eligendi
									facilis laudantium et, ipsa aliquid eius maxime reiciendis
									accusamus explicabo saepe doloremque rem voluptatibus a at,
									dolorum perspiciatis cum dolore voluptatum. Facilis fuga error
									quae et ea quos, cumque quod unde numquam rerum est facere,
									obcaecati, illo sed quaerat aspernatur.
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
			<BelowSliderSection />
		</>
	)
}

export default injectIntl(About)
