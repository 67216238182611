import React from 'react'

function Benefited({ donor, amount, when }) {
	return (
		<div className='d-flex'>
			<div className="p-2 bd-highlight">
				<span className='text-muted'>{donor} donated </span>
				<span className='fw-bolder'> &#2547;{amount} </span>
				<span style={{ color: '#C0C0C0' }}>{when}</span>
			</div>
			{/* <div className="p-2 bd-highlight text-muted">{date}</div> */}
		</div>
	)
}

export default Benefited