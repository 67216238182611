import { configureStore } from '@reduxjs/toolkit'
import BeneficiarySlice from './BeneficiarySlice'
import FormSlice from './FormSlice'
import LanguageSlice from './LanguageSlice'

import UiSlice from './UiSlice'

const store = configureStore({
	reducer: {
		beneficiaries: BeneficiarySlice.reducer,
		ui: UiSlice.reducer,
		formMulti: FormSlice.reducer,
		language: LanguageSlice.reducer
	}
})
export default store

