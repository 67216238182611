import { Button, Col, Form, Input, message, Modal, Row, Table } from 'antd'
import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

import { AiOutlineGroup } from 'react-icons/ai'
import { GiGraduateCap } from 'react-icons/gi'
import { MdOutlineDateRange } from 'react-icons/md'
import { RiBuilding2Line } from 'react-icons/ri'

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 8 }
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 16 }
// 	}
// }

const EducationInfo = ({ onChange, previousValue, intl }) => {
	const [educationInformation] = Form.useForm()
	const [index, setIndex] = useState(undefined)
	const [educationFields, setEducationFields] = useState(
		previousValue ? previousValue : []
	)
	const [isUpdateEducationFields, setIsUpdateEducationFields] =
		useState(undefined)
	//const [isEducationFieldsOpen, setIsEducationFieldsOpen] = useState(false)
	const [visible, setVisible] = useState(false)
	const yearPattern = /^[1-9]{1}[0-9]{3}$/

	if (previousValue) {
		//setEducationFields(previousValue)
		//educationInformation.setFieldsValue(previousValue)
		console.log(previousValue)
	}

	const onSubmitEducationInfo = values => {
		if (!isUpdateEducationFields) {
			var temp2 = educationFields
			temp2.push(values)
			setEducationFields(temp2.map((f, i) => ({ ...f, id: i })))
		} else if (isUpdateEducationFields) {
			educationFields.splice(index, 1, values)
			setEducationFields(educationFields)
			setIsUpdateEducationFields(false)
		}
		setVisible(false)
	}

	const handleCancel = () => {
		setVisible(false)
	}

	const onSubmit = () => {
		if (educationFields.length === 0) {
			message.error('Please add at least one education field')
		} else {
			onChange(2, educationFields)
		}
	}

	const onPrevious = () => {
		onChange(0)
	}

	var columns = [
		{
			title: 'Examination',
			// width: 150,
			dataIndex: 'examName',
			key: 'names'
		},

		{
			title: 'Action',
			// colSpan: 2,
			// width: 100,
			dataIndex: '_id',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							setIsUpdateEducationFields(true)
							setVisible(true)
							//setIsEducationFieldsOpen(true)
							educationInformation.setFieldsValue(educationFields[index])
							setIndex(index)
						}}
					>
						<IntlMessages id='nav.volunteer.button.edit' />
					</span>
				)
			}
		},
		{
			dataIndex: 'active',
			render: (text, record, index) => {
				return (
					<span
						className='gx-link'
						onClick={() => {
							var temp1 = [...educationFields]
							temp1.splice(index, 1)
							setEducationFields(temp1)
							if (educationFields.length === 1) {
								educationInformation.resetFields()
							}
						}}
					>
						<IntlMessages id='nav.volunteer.button.delete' />
					</span>
				)
			}
		}
	]

	const educationStyle = {
		backgroundImage: 'url(images-reg/2.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top',
		backgroundSize: 'cover'
	}

	return (
		<React.Fragment>
			<div style={educationStyle}>
				<div className='container-fluid'>
					<div className='row'>
						<div className='d-none d-lg-block col-lg-4 col-xl-4  offset-lg-2 offset-xl-3 display-2 text-end text-white pt-5'>
							Believe in Yourself
						</div>
						{/* offset-lg-6 offset-xl-7 */}

						<div className='col-lg-6 col-xl-5 bg-white p-5 rounded-3 my-2'>
							<Modal
								title={intl.formatMessage({
									id: 'nav.volunteer.education.formTitle'
								})}
								visible={visible}
								onOk={educationInformation.submit}
								onCancel={handleCancel}
								centered={true}
								width='30vw'
								// bodyStyle={{
								// 	height: '50vh'
								// }}
							>
								<Form
									// {...formItemLayout}
									form={educationInformation}
									name='educationInformation'
									onFinish={onSubmitEducationInfo}
									style={{ margineTop: 0 }}
								>
									<div>
										<Form.Item
											hasFeedback
											name='examName'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.education.exam.name'
											// })}
											rules={[
												{
													required: true,
													min: 2,
													max: 200,
													message: intl.formatMessage({
														id: 'nav.volunteer.education.exam.name.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												size='large'
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.education.exam.name'
													}) + '*'
												}
												prefix={<GiGraduateCap />}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='subjectOrGroup'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.education.exam.subject.or.group'
											// })}
											rules={[
												{
													required: true,
													min: 2,
													max: 200,
													message: intl.formatMessage({
														id: 'nav.volunteer.education.exam.subject.or.group.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												size='large'
												style={{ width: '100%' }}
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.education.exam.subject.or.group'
													}) + '*'
												}
												prefix={<AiOutlineGroup />}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='passingYear'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.education.exam.passing.year'
											// })}
											rules={[
												{
													required: true,
													pattern: yearPattern,
													message: intl.formatMessage({
														id: 'nav.volunteer.education.exam.passing.year.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												type='number'
												size='large'
												style={{ width: '100%' }}
												prefix={<MdOutlineDateRange />}
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.education.exam.passing.year'
													}) + '*'
												}
											/>
										</Form.Item>
										<Form.Item
											hasFeedback
											name='institute'
											// label={intl.formatMessage({
											// 	id: 'nav.volunteer.education.exam.institute'
											// })}
											rules={[
												{
													required: true,
													min: 5,
													max: 500,
													message: intl.formatMessage({
														id: 'nav.volunteer.education.exam.institute.errorMessage'
													}),
													whitespace: true
												}
											]}
										>
											<Input
												size='large'
												style={{ width: '100%' }}
												prefix={<RiBuilding2Line />}
												placeholder={
													intl.formatMessage({
														id: 'nav.volunteer.education.exam.institute'
													}) + '*'
												}
											/>
										</Form.Item>
									</div>
									<div style={{ paddingBottom: 10 }}></div>
									{/* <Button
						type='primary'
						htmlType='submit'
						style={{ float: 'right' }}
					>
						<IntlMessages id='nav.volunteer.formSendButton' />
					</Button> */}
								</Form>
							</Modal>
							<Row style={{ marginBottom: '1rem' }}>
								{/* education info */}
								<Col xl={24} lg={24} md={24} sm={24} xs={24}>
									<h1
										style={{
											// marginBottom: 10,
											// color: 'rgba(0, 0, 0, 0.55)',
											color: '#535353',
											fontWeight: 500,
											fontSize: '2rem',
											lineHeight: '2rem',
											wordWrap: 'breakWord'
											// paddingBottom: '0.25rem',
											// textAlign: 'center'
										}}
									>
										<IntlMessages id='nav.volunteer.education.formTitle' />
									</h1>
								</Col>
								<Col
									xl={24}
									lg={24}
									md={24}
									sm={24}
									xs={24}
									style={{ textAlign: 'center' }}
								>
									{educationFields.length !== 0 ? (
										<div>
											<Table
												columns={columns}
												dataSource={educationFields.map(item => item)}
												pagination={false}
												// scroll={{ x: 200 }}
											/>
											<Button
												type='primary'
												size='large'
												onClick={() => {
													setVisible(true)
													//setIsEducationFieldsOpen(true)
													educationInformation.resetFields()
												}}
												style={{
													float: 'center',
													marginBottom: 0,
													marginTop: 10
												}}
											>
												<IntlMessages id='nav.volunteer.add.more.button' />
											</Button>
										</div>
									) : (
										<div style={{ marginBottom: '1rem', marginTop: '2rem' }}>
											<Button
												type='primary'
												size='large'
												onClick={() => {
													setVisible(true)
													//setIsEducationFieldsOpen(true)
													educationInformation.resetFields()
												}}
												className='gx-mb-0'
												style={{
													color: '#ffffff',
													width: '50%'
													// marginBottom: '50px'
												}}
											>
												<IntlMessages id='nav.form.add' />
											</Button>
										</div>
									)}
								</Col>
							</Row>

							<div className='d-flex justify-content-between'>
								<Button
									// type='primary'
									size='large'
									onClick={() => onPrevious()}
									// className='gx-mb-0'
									style={{
										backgroundColor: '#fff',
										color: '#000'
										//width: '100%'
									}}
								>
									<IntlMessages id='nav.form.previous' />
								</Button>
								<Button
									type='primary'
									size='large'
									onClick={() => onSubmit()}
									// className='gx-mb-0'
									style={{
										color: '#ffffff'
										// width: '100%',
									}}
								>
									<IntlMessages id='nav.form.next' />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default injectIntl(EducationInfo)
