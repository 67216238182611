import { useState } from 'react'
import { injectIntl } from 'react-intl'
import BelowSliderSection from '../../components/BelowSliderSection'
import Bannercrumb from '../../util/banner/Bannercrumb'
import PhotoGallery from './PhotoGallery'

const Gallery = ({ intl }) => {
	const [tab1, setTab1] = useState(true)
	const [tab2, setTab2] = useState(false)

	const handleTab1 = () => {
		setTab1(true)
		setTab2(false)
	}
	const handleTab2 = () => {
		setTab2(true)
		setTab1(false)
	}
	return (
		<>
			<Bannercrumb bannerId='gallery' />
			<div className='container-fluid'>
				<div
					className='row'
					style={{
						border: '2px solid #7dd078'
						// borderRight: '2px solid lightgrey'
					}}
				>
					<div className='col-md-6 ps-0 pe-0'>
						<div
							className='card card-bene bg-dark text-white backface border-0'
							style={{ border: '1px solid #78d673', borderRadius: '0' }}
						>
							<img
								src='https://cdn.pixabay.com/photo/2014/11/13/06/12/boy-529067_1280.jpg'
								alt='Stony Beach'
							/>
							<div className='card-img-overlay'>
								<h6
									style={{
										position: 'absolute',
										textAlign: 'center',
										bottom: '1.25rem',
										fontSize: '1rem',
										color: 'white'
									}}
								>
									Ayman
								</h6>
								<p
									style={{
										position: 'absolute',
										textAlign: 'center',
										bottom: '0.1rem',
										color: '#7dd078',
										fontSize: '0.75rem'
									}}
								>
									Children
								</p>
							</div>
						</div>
					</div>
					<div className='col-md-6'>
						<div className='pb-3 px-1 pt-1'>
							<button
								onClick={() => handleTab1()}
								className={tab1 ? 'btn btn-new' : 'btn btn-gallery'}
							>
								Tab1
							</button>
							<button
								onClick={() => handleTab2()}
								className={tab2 ? 'btn btn-new' : 'btn btn-analysis'}
							>
								Tab2
							</button>
						</div>

						<div>
							{tab1 && <PhotoGallery />}
							{tab2 && <PhotoGallery />}
						</div>
					</div>
				</div>
			</div>
			<BelowSliderSection />
		</>
	)
}

export default injectIntl(Gallery)
