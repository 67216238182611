import React from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
//import './bene-detail.css'
//import './profileGallery.css'
function Gallery() {
	const images = useSelector(state => state.beneficiaries.profiles)
	let image2 = images.slice(0, 1)
	return (
		<div>
			<Row className='g-0'>
				{image2.map(image => (
					<Col key={image.id} md={3} sm={3} xs={4}>
						{/* <Card>
              <Card.Img variant="top" className="img-fluid" src={image.src} />
            </Card> */}
						<Card>
							<img
								src={image.src}
								className='img-fluid xyz-in'
								xyz='fade up small duration-10'
							/>
						</Card>
					</Col>
				))}
			</Row>
		</div>
	)
}

export default Gallery
