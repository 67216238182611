import React from 'react'
import { injectIntl } from 'react-intl'
import '../copyrights/Copyright.css'

const Copyright = ({ intl }) => {
	return (
		<React.Fragment>
			<section className='copy-rights'>
				<span>
					{intl.formatMessage({ id: 'copyright' })} © 2022{' '}
					{intl.formatMessage({ id: 'no.more.poverty' })}{' '}
					{intl.formatMessage({ id: 'copyright.reserved' })} |{' '}
					{intl.formatMessage({ id: 'tech.support' })}{' '}
					<a href='https://www.quanticdynamics.com/'>
						{intl.formatMessage({ id: 'quantic.dynamics' })}
					</a>
				</span>
			</section>
		</React.Fragment>
	)
}
export default injectIntl(Copyright)
