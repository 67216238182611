import React from 'react'
import './Donors.css'
import PartnerSlider from '../slider/PartnerSlider'
import { PartnerData } from '../../../data/PartnerData'
import { injectIntl } from 'react-intl'
const Donors = ({ intl }) => {
	return (
		<div className="donor">
			<div className="container-lg container-fluid">
				{/* <h1 className="section-title">Our Honorable Partners</h1> */}
				<h1 className="section-title">{intl.formatMessage({id:'donors.heading'})}</h1>
				{/* <p className="section-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p> */}
				<p className="section-desc">{intl.formatMessage({ id: 'donors.description' })}</p>
				<PartnerSlider data={{ PartnerData }}></PartnerSlider>

			</div>
		</div>
	)
}

export default injectIntl(Donors)
